import React, { useState, useEffect, useRef } from "react";
import "../scss/pages/newquotes.scss";
import Select from "react-dropdown-select";
// import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { SignUpSchema } from "./validation";
import Layout from "../components/common/layout";
import PopupMessage from "../components/common/popup-message";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { Axios } from "./config";
const transPortModeOptions = [
  {
    value: "",
    label: "Please select",
  },
  {
    value: "ROA",
    label: "Road",
  },
  {
    value: "AIR",
    label: "Air",
  },
  {
    value: "SEA",
    label: "Sea",
  },
];
const packageTypeOptions = [
  { value: "Bag", label: "Bag" },
  { value: "Bulk Bag", label: "Bulk Bag" },
  { value: "Break Bulk", label: "Break Bulk" },
  { value: "Bale, Compressed", label: "Bale, Compressed" },
  { value: "Bale, Uncompressed", label: "Bale, Uncompressed" },
  { value: "Bundle", label: "Bundle" },
  { value: "Bottle", label: "Bottle" },
  { value: "Box", label: "Box" },
  { value: "Basket", label: "Basket" },
  { value: "Case", label: "Case" },
  { value: "Coil", label: "Coil" },
  { value: "Cradle", label: "Cradle" },
  { value: "Crate", label: "Crate" },
  { value: "Carton", label: "Carton" },
  { value: "Cylinder", label: "Cylinder" },
  { value: "Dozen", label: "Dozen" },
  { value: "Drum", label: "Drum" },
  { value: "Envelope", label: "Envelope" },
  { value: "Gross", label: "Gross" },
  { value: "Keg", label: "Keg" },
  { value: "Mix", label: "Mix" },
  { value: "Pail", label: "Pail" },
  { value: "Piece", label: "Piece" },
  { value: "Package", label: "Package" },
  { value: "Pallet", label: "Pallet" },
  { value: "Reel", label: "Reel" },
  { value: "Roll", label: "Roll" },
  { value: "Sheet", label: "Sheet" },
  { value: "Skid", label: "Skid" },
  { value: "Spool", label: "Spool" },
  { value: "Tote", label: "Tote" },
  { value: "Tube", label: "Tube" },
  { value: "Unit", label: "Unit" },
];

const customsClearanceRequirementsOptions = [
  { value: "Export", label: "Export" },
  { value: "Import", label: "Import" },
  { value: "Export and Import", label: "Export and Import" },
];

const incotermsOptions = [
  {
    value: "FAS (Free Alongside Ship)",
    label: "FAS (Free Alongside Ship)",
  },
  {
    value: "EXW (Ex Works)",
    label: "EXW (Ex Works)",
  },
  {
    value: "FCA (Seller)",
    label: "FCA (Seller)",
  },
  { value: "FCA (Named Place)", label: "FCA (Named Place)" },
  { value: "FOB (Free on Board)", label: "FOB (Free on Board)" },
  { value: "CFR (Cost and Freight)", label: "CFR (Cost and Freight)" },
  { value: "CPT (Carriage Paid To)", label: "CPT (Carriage Paid To)" },
  {
    value: "CIF (Cost, Insurance and Freight)",
    label: "CIF (Cost, Insurance and Freight)",
  },
  {
    value: "CIP (Carrier and Insurance Paid)",
    label: "CIP (Carrier and Insurance Paid)",
  },
  {
    value: "DDP (Delivered Duty Paid)",
    label: "DDP (Delivered Duty Paid)",
  },
  {
    value: "DAP (Delivered at Place)",
    label: "DAP (Delivered at Place)",
  },
  {
    value: "DPU (Delivered at Place Unloaded)",
    label: "DPU (Delivered at Place Unloaded)",
  },
];

const initialValues = {
  firstname: "",
  surname: "",
  email: "",
  companyName: "",
  contactNumber: "",
  collectionAddress: "",
  collectionPostcode: "",
  deliveryAddress: "",
  deliveryPostcode: "",
  portOfLoading: "",
  portOfDelivery: "",
  // multiPort: "",
  transportMode: "",
  collectDate: "",
  deliveryDate: "",
  volume: "",
  weight: "",
  width: "",
  length: "",
  height: "",
  dimensions: "",
  packageType: "",
  QTY: "",
  Incoterms: "",
  customsclearance: "",
  incotermsSelect1: "",
  hazerdousGoods: "",
  gauge: "",
  comments: "",
  attachment: null,
  airportOfArrival: "",
  airportOfDeparture: "",
};


export default function NewQuotes() {
  const [SubmitMessage, setSubmitMessage] = useState("");
  const inputRef = useRef(null);
  const [validationScheme, setValidationScheme] = useState(SignUpSchema);
  const [options, setOptions] = useState({
    portOfLoading: [],
    portOfArrival: [],
    airports: [],
    transPortMode: transPortModeOptions,
    packageType: packageTypeOptions,
    customsClearanceRequirements: customsClearanceRequirementsOptions,
    incoterms: incotermsOptions,
  });
  const [isLoading, setIsLoading] = useState(false);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationScheme,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      let data = new FormData();
      data.append("pinned", "0");
      data.append("quote_ref", "");
      data.append("origin", "aa");
      data.append("destination", "abc destination");
      data.append("issue_date", "2023-07-07");
      data.append("issued_by", "12");
      data.append("valid_from", "2023-07-18");
      data.append("valid_to", "2024-07-20");
      data.append("first_name", values.firstname);
      data.append("last_name", values.surname);
      data.append("company_name", values.companyName);
      data.append("email_address", values.email);
      data.append("telephone_number", values.contactNumber);
      data.append("type_of_packages", values.packageType);
      data.append("qty", values.QTY);
      data.append("weight", values.weight);
      data.append("volume", values.volume);
      data.append("width", values.width);
      data.append("height", values.height);
      data.append("length", values.length);
      data.append("dimensions", values.dimensions);
      data.append("custom_clearance_requirement", values.customsclearance);
      data.append("incoterms", values.Incoterms);
      data.append("hazardous", values.hazerdousGoods);
      data.append("out_of_gauge", values.gauge);
      data.append("comments", values.comments);
      data.append("attachment", values.attachment);
      data.append("collection_address", values.collectionAddress);
      data.append("collection_postcode", values.collectionPostcode);
      data.append("delivery_address", values.deliveryAddress);
      data.append("delivery_postcode", values.deliveryPostcode);
      data.append("port_of_loading", values.portOfLoading);
      data.append("port_of_delivery", values.portOfDelivery);
      data.append("transport_mode", values.transportMode);
      // data.append("transport_type", "8 wheeler");
      data.append("ready_to_collect_date", values.collectDate);
      data.append("target_delivery_date", values.deliveryDate);
      data.append("airport_of_arrival", values.airportOfArrival);
      data.append("airport_of_departure", values.airportOfDeparture);
      try {
        await Axios.post(
          `/quotes`,
           data
        );
        setSubmitMessage("Your data has been submitted successfully");
        resetForm();
        setFieldValue("");
        handleChange("");
      } catch (error) {
        console.error(error);
        setSubmitMessage("Something went wrong");
      }
    },
  });

  const fetchOptions = async () => {
    try {
      let portOfArrival = [],
        portOfLoading = [],
        airports = [];
      setIsLoading(true);
      const portOfLoadingRes =await Axios.get("/quote/options/port-of-loading")
        portOfLoading = await portOfLoadingRes;
     
      const portOfArrivalRes = await Axios.get(
        `/quote/options/port-of-arrival`
      );
        portOfArrival = await portOfArrivalRes;
      

      const airportResponse = await Axios.get(
        `/quote/options/airports`
      );
     
        airports = await airportResponse;
      
      const userDetails = await Axios.get(`/user`);
    
        const userData = await userDetails
        setFieldValue("firstname",userData?.name?.split(" ")?.[0] || "");
        setFieldValue("surname", userData?.name?.split(" ")?.[1] || "")
        setFieldValue("email", userData?.email ||  "")
        setFieldValue("companyName",userData?.organisation?.name || "")
        setFieldValue("contactNumber",userData?.phone_number||"")
      setOptions((prevState) => ({
        ...prevState,
        airports: airports.map((item) => {
          return { label: item, value: item };
        }),
        portOfArrival: portOfArrival.map((item) => {
          return { label: item, value: item };
        }),
        portOfLoading: portOfLoading.map((item) => {
          return { label: item, value: item };
        }),
      }));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilePreview = (file) => {
    const fileType = file?.type;

    if (fileType?.startsWith("image/")) {
      return { preview: URL.createObjectURL(file), name: file?.name };
    } else if (fileType === "application/pdf") {
      return { preview: "/assets/image/pdf1.png", name: file?.name };
    } else if (fileType === "text/csv") {
      return { preview: "/assets/image/csv2.png", name: file?.name };
    }
    return "/assets/icons/upload-cloud-2.svg";
  };
  // const [deliveryDetails, setdeliveryDetails] = useState(true);

  let dimensionValue = values.width * values.length * values.height;
  values.dimensions = dimensionValue;
  const onCloseMessage = () => {
    setSubmitMessage("");
  };
  useEffect(() => {
    if (values?.transportMode === "ROA") {
      const roadSchema = Yup.object().shape({
        collectionAddress: Yup.string().required(
          "Collection address is required"
        ),
        collectionPostcode: Yup.string().required(
          "Collection postcode is required"
        ),
        deliveryAddress: Yup.string().required("Delivery address is required"),
        deliveryPostcode: Yup.string().required(
          "Delivery postcode is required"
        ),
      });
      setValidationScheme(SignUpSchema.concat(roadSchema));
    } else if (values?.transportMode === "AIR") {
      const airportSchema = Yup.object().shape({
        airportOfArrival: Yup.string().required(
          "A airport of arrival option is required"
        ),
        airportOfDeparture: Yup.string().required(
          "A airport of departure option is required"
        ),
      });
      setValidationScheme(SignUpSchema.concat(airportSchema));
    } else if (values?.transportMode === "SEA") {
      const airportSchema = Yup.object().shape({
        portOfLoading: Yup.string().required("Port of loading is required"),
        portOfDelivery: Yup.string().required("portOfDelivery is required"),
      });
      setValidationScheme(SignUpSchema.concat(airportSchema));
    }
  }, [values?.transportMode]);
  return (
    <>
      <Layout />
      {isLoading ? (
        <div className="text-center loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper newquotes md:pt-[26px] md:pr-9 md:pb-[29px] md:pl-[26px]">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-[27px]">
              <div>
                <section>
                  <div className="newquotes-card pt-[20px] pr-[24px] pb-[22px] pl-[24px]">
                    <div className="newquotes-card-heading pb-3">
                      <h4>Customer Details</h4>
                      <p>Required Fields *</p>
                    </div>
                    <div className="newquotes-card-body pt-6">
                      <div className="grid  grid-cols-2 gap-[14px] gap-y-6 ">
                        <div className="col-span-2 sm:col-span-1 card-items relative ">
                          <label htmlFor="name">First Name*</label>
                          <input
                            type="text"
                            className={
                              touched.firstname && errors.firstname
                                ? "error"
                                : ""
                            }
                            name="firstname"
                            id="name"
                            placeholder="John"
                            value={values.firstname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <p className="form-error">
                            {touched.firstname && errors.firstname}
                          </p>
                        </div>
                        <div className="col-span-2 sm:col-span-1 card-items relative">
                          <label htmlFor="surname">Surname*</label>
                          <input
                            type="text"
                            className={
                              touched.surname && errors.surname ? "error" : ""
                            }
                            name="surname"
                            id="Surname"
                            placeholder="Surname"
                            value={values.surname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {
                            <p className="form-error">
                              {touched.surname && errors.surname}
                            </p>
                          }
                        </div>
                        <div className="col-span-2 card-items relative">
                          <label htmlFor="Companyname">Company name*</label>
                          <input
                            type="text"
                            className={
                              touched.companyName && errors.companyName
                                ? "error"
                                : ""
                            }
                            name="companyName"
                            id="Companyname"
                            placeholder="Company Name"
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {
                            <p className="form-error">
                              {touched.companyName && errors.companyName}
                            </p>
                          }
                        </div>
                        <div className="col-span-2 sm:col-span-1 card-items relative">
                          <label htmlFor="email">Email address*</label>
                          <input
                            type="text"
                            className={
                              touched.email && errors.email ? "error" : ""
                            }
                            name="email"
                            id="email"
                            placeholder="hello@phunk.co.uk"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {
                            <p className="form-error">
                              {touched.email && errors.email}
                            </p>
                          }
                        </div>
                        <div className="col-span-2 sm:col-span-1 card-items relative">
                          <label htmlFor="number">Contact number*</label>
                          <input
                            type="text"
                            className={
                              touched.contactNumber && errors.contactNumber
                                ? "error"
                                : ""
                            }
                            name="contactNumber"
                            id="Contactnumber"
                            placeholder="01234 586 932"
                            value={values.contactNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPress={(event) => {
                              const numericRegex = /^[0-9\b]+$/;
                              if (!numericRegex.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <p className="form-error">
                            {touched.contactNumber && errors.contactNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="newquotes-card mt-6 pt-[20px] pr-[24px] pb-[30px] pl-[24px]">
                    <div className="newquotes-card-heading pb-3">
                      <h4>Delivery Details</h4>
                      <p>Required Fields *</p>
                    </div>
                    <div className="newquotes-card-body pt-6">
                      <div className="container-xl">
                        <div className="grid grid-cols-6 gap-[14px] gap-y-[18px] ">
                          <div className="card-items col-span-6 sm:col-span-3 md:col-span-4 card-dropdown relative">
                            <label htmlFor="transportMode">
                              Transport mode*
                            </label>
                            <Select
                              className={`dropdown-btn ${
                                touched.transportMode && errors.transportMode
                                  ? "error"
                                  : ""
                              }`}
                              id="transportMode"
                              name="transportMode"
                              placeholder={"Select from dropdown"}
                              options={options.transPortMode}
                              searchable={false}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "transportMode",
                                  selectedOption[0]?.value
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <p className="form-error">
                              {touched.transportMode && errors.transportMode}
                            </p>
                          </div>

                          {values.transportMode === "ROA" && (
                            <>
                              <div className="card-items col-span-6 sm:col-span-3 md:col-span-4 relative">
                                <label htmlFor="collection-address">
                                  Collection address*
                                </label>
                                <input
                                  type="text"
                                  name="collectionAddress"
                                  className={
                                    touched.collectionAddress &&
                                    errors.collectionAddress
                                      ? "error"
                                      : ""
                                  }
                                  id="collection-address"
                                  placeholder="John"
                                  value={values.collectionAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="form-error">
                                  {touched.collectionAddress &&
                                    errors.collectionAddress}
                                </p>
                              </div>
                              <div className="card-items col-span-6 sm:col-span-3 md:col-span-2 relative">
                                <label htmlFor="collection-postcode">
                                  Collection postcode*
                                </label>
                                <input
                                  type="text"
                                  name="collectionPostcode"
                                  className={
                                    touched.collectionPostcode &&
                                    errors.collectionPostcode
                                      ? "error"
                                      : ""
                                  }
                                  id="collection-postcode"
                                  placeholder="John"
                                  value={values.collectionPostcode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="form-error">
                                  {touched.collectionPostcode &&
                                    errors.collectionPostcode}
                                </p>
                              </div>
                              <div className="col-span-6 sm:col-span-3 md:col-span-4 card-items relative">
                                <label htmlFor="delivery-address">
                                  Delivery address*
                                </label>
                                <input
                                  type="text"
                                  name="deliveryAddress"
                                  className={
                                    touched.deliveryAddress &&
                                    errors.deliveryAddress
                                      ? "error"
                                      : ""
                                  }
                                  id="delivery-address"
                                  placeholder="John"
                                  value={values.deliveryAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="form-error">
                                  {touched.deliveryAddress &&
                                    errors.deliveryAddress}
                                </p>
                              </div>
                              <div className="card-items col-span-6 sm:col-span-3 md:col-span-2 relative">
                                <label htmlFor="delivery-postcode">
                                  Delivery postcode*
                                </label>
                                <input
                                  type="text"
                                  name="deliveryPostcode"
                                  className={
                                    touched.deliveryPostcode &&
                                    errors.deliveryPostcode
                                      ? "error"
                                      : ""
                                  }
                                  id="delivery-postcode"
                                  placeholder="John"
                                  value={values.deliveryPostcode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="form-error">
                                  {touched.deliveryPostcode &&
                                    errors.deliveryPostcode}
                                </p>
                              </div>
                            </>
                          )}
                          {values.transportMode === "SEA" && (
                            <>
                              <div className="card-items col-span-6 sm:col-span-3 card-dropdown relative">
                                <label htmlFor="port-loading">
                                  Port of loading*
                                </label>
                                <Select
                                  className={`dropdown-btn ${
                                    touched.portOfLoading &&
                                    errors.portOfLoading
                                      ? "error"
                                      : ""
                                  }`}
                                  id="portOfLoading"
                                  name="portOfLoading"
                                  placeholder={"Select from dropdown"}
                                  options={options.portOfLoading}
                                  searchable={true}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "portOfLoading",
                                      selectedOption[0]?.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                />

                                <p className="form-error">
                                  {touched.portOfLoading &&
                                    errors.portOfLoading}
                                </p>
                              </div>
                              <div className="card-items col-span-6 sm:col-span-3 card-dropdown relative">
                                <label htmlFor="port-delivery">
                                  Port of delivery*
                                </label>
                                <Select
                                  className={`dropdown-btn ${
                                    touched.portOfDelivery &&
                                    errors.portOfDelivery
                                      ? "error"
                                      : ""
                                  }`}
                                  id="portOfDelivery"
                                  name="portOfDelivery"
                                  placeholder={"Select from dropdown"}
                                  options={options.portOfArrival}
                                  searchable={true}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "portOfDelivery",
                                      selectedOption?.[0]?.label
                                    )
                                  }
                                  onBlur={handleBlur}
                                />
                                <p className="form-error">
                                  {touched.portOfDelivery &&
                                    errors.portOfDelivery}
                                </p>
                              </div>
                            </>
                          )}
                          {values?.transportMode === "AIR" && (
                            <>
                              <div className="card-items col-span-6 sm:col-span-3 card-dropdown relative">
                                <label htmlFor="airportOfDeparture">
                                  Airport of Departure*
                                </label>
                                <Select
                                  className={`dropdown-btn ${
                                    touched.airportOfDeparture &&
                                    errors.airportOfDeparture
                                      ? "error"
                                      : ""
                                  }`}
                                  id="airportOfDeparture"
                                  name="airportOfDeparture"
                                  placeholder={"Select from dropdown"}
                                  options={options.airports}
                                  searchable={true}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "airportOfDeparture",
                                      selectedOption[0]?.label
                                    )
                                  }
                                  onBlur={handleBlur}
                                />
                                <p className="form-error">
                                  {touched.airportOfDeparture &&
                                    errors.airportOfDeparture}
                                </p>
                              </div>
                              <div className="card-items col-span-6 sm:col-span-3 card-dropdown relative">
                                <label htmlFor="airportOfArrival">
                                  Airport of Arrival*
                                </label>
                                <Select
                                  className={`dropdown-btn ${
                                    touched.airportOfArrival &&
                                    errors.airportOfArrival
                                      ? "error"
                                      : ""
                                  }`}
                                  id="airportOfArrival"
                                  name="airportOfArrival"
                                  placeholder={"Select from dropdown"}
                                  options={options.airports}
                                  searchable={true}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "airportOfArrival",
                                      selectedOption[0]?.label
                                    )
                                  }
                                  onBlur={handleBlur}
                                />
                                <p className="form-error">
                                  {touched.airportOfArrival &&
                                    errors.airportOfArrival}
                                </p>
                              </div>
                            </>
                          )}
                          <div className="card-items col-span-6 sm:col-span-3 relative">
                            <label htmlFor="collectDate">
                              Ready to collect date
                            </label>
                            <input
                              type="date"
                              name="collectDate"
                              id="collectDate"
                              className={
                                touched.collectDate && errors.collectDate
                                  ? "error"
                                  : ""
                              }
                              value={values.collectDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="form-error">
                              {touched.collectDate && errors.collectDate}
                            </p>
                          </div>
                          <div className="card-items col-span-6 sm:col-span-3 relative">
                            <label htmlFor="deliveryDate">
                              Target delivery date
                            </label>
                            <input
                              type="date"
                              name="deliveryDate"
                              id="deliveryDate"
                              value={values.deliveryDate}
                              className={
                                touched.deliveryDate && errors.deliveryDate
                                  ? "error"
                                  : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="form-error">
                              {touched.deliveryDate && errors.deliveryDate}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*start section for the quotes request page  */}
                  {/* <div className={`details-card pt-[19px] pl-[25px] pr-[15px] pb-[30px] mt-8 ${userId === "delveryDetails=true" ? 'block' : 'hidden'}`}>
                                    <div className="card-heading">
                                        <button className="flex items-center justify-between w-full text-left" onClick={toggleAccordionDelivery}>
                                            <h4 className="font-semibold">Delivery Details</h4>
                                            <img className={`transition-transform transform ${deliveryDetails ? 'rotate-[270deg]' : 'rotate-90'}`} src="/assets/icons/right-arrow.svg" alt="right-arrow-icon" />
                                        </button>
                                    </div>
                                    <div className={`overflow-hidden transition-max-height ease-in-out duration-300  ${deliveryDetails ? 'max-h-96' : 'max-h-0'}`}>
                                        <div className="details-card-body">
                                            <div className="container-xl">
                                                <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-[33px]">
                                                    <div className='item'>
                                                        <h6 className='item-heading'>Origin</h6>
                                                        <div className='item-content'>Ningbo</div>
                                                    </div>
                                                    <div className='item'>
                                                        <h6 className='item-heading'>Destination</h6>
                                                        <div className='item-content'>Felixstowe</div>
                                                    </div>
                                                    <div className='item'>
                                                        <h6 className='item-heading'>ETD</h6>
                                                        <div className='item-content'>27/05/23</div>
                                                    </div>
                                                    <div className='item'>
                                                        <h6 className='item-heading'>ETA</h6>
                                                        <div className='item-content'>23/05/23</div>
                                                    </div>
                                                    <div className='item'>
                                                        <h6 className='item-heading'>Estimated Delivery</h6>
                                                        <div className='item-content'>Felixstowe</div>
                                                    </div>
                                                    <div className='item'>
                                                        <h6 className='item-heading'>Date Of Arrival</h6>
                                                        <div className='item-content'>27/06/23</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                  {/*end  section for the quotes request page  */}
                </section>
              </div>

              <div>
                <div className="newquotes-card pt-[20px] pr-[24px] pb-[23px] pl-[24px]">
                  <div className="newquotes-card-heading pb-3">
                    <h4>Goods Details</h4>
                    <p>Required Fields *</p>
                  </div>
                  <div className="newquotes-card-body pt-6">
                    <div className="container-xl">
                      <div className="grid grid-cols-12 gap-[14px] gap-y-[18px] ">
                        <div className="card-items col-span-12 sm:col-span-6 relative">
                          <label htmlFor="volume">Volume</label>
                          <input
                            type="number"
                            name="volume"
                            id="volume"
                            placeholder="123"
                            className={
                              touched.volume && errors.volume ? "error" : ""
                            }
                            value={values.volume}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <p className="form-error">
                            {touched.volume && errors.volume}
                          </p>
                        </div>
                        <div className="card-items col-span-12 sm:col-span-6 relative">
                          <label htmlFor="surname">Weight</label>
                          <div className="relative w-full">
                            <div className="input-weight">
                              <input
                                type="number"
                                name="weight"
                                id="weight"
                                placeholder="124"
                                className={
                                  touched.weight && errors.weight ? "error" : ""
                                }
                                value={values.weight}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="form-error">
                                {touched.weight && errors.weight}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3 card-items relative">
                          <label htmlFor="width">Width*</label>
                          <div className="relative w-full">
                            <div className="input-width">
                              <input
                                type="number"
                                name="width"
                                id="width"
                                placeholder="12"
                                className={
                                  touched.width && errors.width ? "error" : ""
                                }
                                value={values.width}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="form-error">
                                {touched.width && errors.width}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card-items col-span-6 sm:col-span-3 relative">
                          <label htmlFor="length">Length*</label>
                          <div className="relative w-full">
                            <div className="input-length">
                              <input
                                className={
                                  touched.length && errors.length ? "error" : ""
                                }
                                type="number"
                                name="length"
                                id="length"
                                placeholder="12"
                                value={values.length}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="form-error">
                                {touched.length && errors.length}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card-items col-span-6 sm:col-span-3">
                          <label htmlFor="height">Height*</label>
                          <div className="relative w-full">
                            <div className="input-height">
                              <input
                                className={
                                  touched.height && errors.height ? "error" : ""
                                }
                                type="number"
                                name="height"
                                id="height"
                                placeholder="12"
                                value={values.height}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="form-error">
                                {touched.height && errors.height}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card-items col-span-6 sm:col-span-3">
                          <label htmlFor="dimensions">Dimensions</label>
                          <div className="relative w-full">
                            <div className="input-dimensions">
                              <input
                                className={
                                  touched.dimensions && errors.dimensions
                                    ? "error"
                                    : ""
                                }
                                type="number"
                                name="dimensions"
                                id="dimensions"
                                placeholder="12"
                                value={values.dimensions}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="form-error">
                                {touched.dimensions && errors.dimensions}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card-items col-span-12 sm:col-span-4 card-dropdown relative">
                          <label htmlFor="packageType">Package Type</label>
                          <Select
                            className={`dropdown-btn ${
                              touched.packageType && errors.packageType
                                ? "error"
                                : ""
                            }`}
                            id="packageType"
                            name="packageType"
                            placeholder={"Select from dropdown"}
                            options={options.packageType}
                            searchable={false}
                            onChange={(selectedOption) =>
                              setFieldValue(
                                "packageType",
                                selectedOption[0]?.label
                              )
                            }
                            onBlur={handleBlur}
                          />
                          <p className="form-error">
                            {touched.packageType && errors.packageType}
                          </p>
                        </div>
                        <div className="card-items col-span-12 sm:col-span-4 card-dropdown relative">
                          <label htmlFor="QTY">QTY</label>

                          <div className="relative w-full">
                            <div className="input-QTY">
                              <input
                                className={
                                  touched.QTY && errors.QTY ? "error" : ""
                                }
                                type="number"
                                name="QTY"
                                id="QTY"
                                placeholder="12"
                                value={
                                  Math.abs(values.QTY) === 0
                                    ? ""
                                    : Math.abs(values.QTY)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="form-error">
                                {touched.QTY && errors.QTY}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card-items col-span-12 sm:col-span-6 card-dropdown relative">
                          <label htmlFor="customsclearance">
                            Customs clearance requirements
                          </label>
                          <Select
                            className={`dropdown-btn ${
                              touched.customsclearance &&
                              errors.customsclearance
                                ? "error"
                                : ""
                            }`}
                            id="customsclearance"
                            name="customsclearance"
                            placeholder={"Select from dropdown"}
                            options={options.customsClearanceRequirements}
                            searchable={false}
                            onChange={(selectedOption) =>
                              setFieldValue(
                                "customsclearance",
                                selectedOption[0]?.label
                              )
                            }
                            onBlur={handleBlur}
                          />
                          <p className="form-error">
                            {touched.customsclearance &&
                              errors.customsclearance}
                          </p>
                        </div>
                        <div className="card-items col-span-12 sm:col-span-6 card-dropdown relative">
                          <label htmlFor="Incoterms">Incoterms</label>
                          <Select
                            className={`dropdown-btn ${
                              touched.Incoterms && errors.Incoterms
                                ? "error"
                                : ""
                            }`}
                            id=""
                            name="Incoterms"
                            placeholder={"Select from dropdown"}
                            options={options.incoterms}
                            searchable={false}
                            onChange={(selectedOption) =>
                              setFieldValue(
                                "Incoterms",
                                selectedOption[0]?.label
                              )
                            }
                            onBlur={handleBlur}
                          />
                          <p className="form-error">
                            {touched.Incoterms && errors.Incoterms}
                          </p>
                        </div>

                        <div className="gap-6 flex items-start justify-start flex-col col-span-12 sm:col-span-6 card-item-radio mt-[3px] relative">
                          <label htmlFor="goods">
                            Does this shipment contain hazerdous goods?
                          </label>
                          <div className="flex items-center justify-start gap radio-btn gap-[37px]">
                            <span className="flex items-cente justify-center gap-[12px]">
                              <input
                                type="radio"
                                id="html"
                                name="hazerdousGoods"
                                value="1"
                                className=""
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="html">Yes</label>
                            </span>
                            <span className="flex items-cente justify-center gap-[12px]">
                              <input
                                type="radio"
                                id="css"
                                name="hazerdousGoods"
                                value="0"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="css">No</label>
                            </span>
                            {
                              <p className="form-error">
                                {touched.hazerdousGoods &&
                                  errors.hazerdousGoods}
                              </p>
                            }
                          </div>
                        </div>

                        <div className="gap-6 flex items-start justify-start flex-col col-span-12 sm:col-span-6 card-item-radio mt-[3px] relative">
                          <label htmlFor="gauge">Out of gauge?</label>
                          <div className="flex items-center justify-start gap radio-btn gap-[37px] ">
                            <span className="flex items-cente justify-center gap-[12px]">
                              <input
                                type="radio"
                                id="html"
                                name="gauge"
                                value="1"
                                className=""
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="html">Yes</label>
                            </span>
                            <span className="flex items-cente justify-center gap-[12px]">
                              <input
                                type="radio"
                                id="css"
                                name="gauge"
                                value="0"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="css">No</label>
                            </span>
                            {
                              <p className="form-error">
                                {touched.gauge && errors.gauge}
                              </p>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section>
                  <div className="mt-[30px] bg-white other-details px-6 py-5">
                    <div className="other-details-heading pb-4 ">
                      <h4>Other Details</h4>
                    </div>
                    <div className="pt-[21px] other-details-body">
                      <div className="grid grid-cols-7 gap-3">
                        <div className="col-span-7 md:col-span-5 other-details-item flex items-start justify-start flex-col">
                          <div className="heading mb-[7px]">
                            Additional comments or special instructions
                          </div>
                          <textarea
                            name="comments"
                            id=""
                            className="h-[193px] w-full other-details-textfield"
                            placeholder=""
                            value={values.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></textarea>
                        </div>
                        <div className="col-span-7 md:col-span-2 other-details-item flex items-start justify-start flex-col">
                          <div className="heading mb-[7px]">
                            Upload supporting files
                          </div>
                          <div className="other-details-textfield h-[193px] relative flex items-center justify-center w-full">
                            <input
                              ref={inputRef}
                              className="w-full h-full opacity-0 z-10"
                              type="file"
                              name="attachment"
                              id=""
                              onChange={(event) => {
                                const file = event?.currentTarget?.files[0];
                                setFieldValue("attachment", file);
                              }}
                              multiple={true}
                              onBlur={handleBlur}
                              accept=".pdf,.csv,.doc,.docx,image/jpeg,image/png,image/gif"
                            />
                            {values?.attachment &&
                            getFilePreview(values?.attachment) &&
                            values?.attachment?.type === "application/pdf" ? (
                              <div
                                onClick={() => inputRef.current.click()}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className="custom_pdf"
                                  src={
                                    getFilePreview(values?.attachment).preview
                                  }
                                  alt="PDF Icon"
                                />
                                <p>{getFilePreview(values?.attachment).name}</p>
                              </div>
                            ) : (
                              <img
                                className="absolute top-0 left-0 right-0 bottom-0 m-auto"
                                src={
                                  getFilePreview(values?.attachment)?.preview ??
                                  "/assets/icons/upload-cloud-2.svg"
                                }
                                alt="uploads-icon"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="other-details-btn mt-3">
                        Submit Quote
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </form>
        </div>
      )}

      <PopupMessage
        visible={SubmitMessage}
        onClose={onCloseMessage}
        heading={"Submit Data"}
      />
    </>
  );
}
