import React from "react";
import "../../../src/scss/popup.scss";
import { Link } from "react-router-dom";
export default function PopupUploads({
  visible,
  onClose,
  heading,
  link = "",
  linkText = "",
}) {
  // if (!visible) return null;
  const handleClose = (e) => {
    if (e.target.id === "Upload-modal-wrapper") {
      onClose();
    }
  };

  return (
    <div
      id="Upload-modal-wrapper"
      onClick={handleClose}
      className={`modal-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center transition-all duration-300 ease-in-out ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div className="modal popup-message max-w-[700px] w-full bg-white rounded-[15px] pt-[26px] pr-[16px] pb-[18px] pl-[19px] relative ">
        <img
          src="/assets/icons/close.svg"
          alt="close"
          className="absolute top-4 right-[18px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-header pb-[23px]">
          <h4 className="heading">{heading}</h4>
          {/* <div className='description'>Use this function to upload a file to the ICL cloud. <span>(.pdf .csv .word supported)</span></div> */}
        </div>
        <div className="modal-body pt-4 popup-message  min-h-[100px] flex items-center justify-center">
          <p className="text-message">{visible}</p>
         
        </div>
        {link && (
            <div className="text-center pt-4  ">
              <Link
                to={link}
                className="login-button py-2 px-2"
              >{linkText}</Link>
            </div>
          )}
      </div>
    </div>
  );
}
