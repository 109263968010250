import React from "react";
import "../../../src/scss/popup.scss";
import CircularProgress from "@mui/material/CircularProgress";
// import { splitExtensionAndCreateDocType } from "../../common/utils";
export default function PopupDownload({
  visible,
  onClose,
  handleDownloadClick,
  loading,
  documents,
  handleDownloadAll,
}) {
  const handleClose = (e) => {
    if (e.target.id === "Download-modal-wrapper") {
      // onClose();
    }
  };
  return (
    <div
      id="Download-modal-wrapper"
      onClick={handleClose}
      className={`modal-wrapper transition-all ease-in-out duration-300 fixed top-0 left-0 w-full h-full flex items-center justify-center ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div
        className={`modal w-[629px] bg-white rounded-[15px] pt-[26px] pr-[17px] pb-[19px] pl-[19px] relative transition-all ease-in-out duration-300 ${
          visible ? "visible" : "invisible"
        }`}
      >
        <img
          src="/assets/icons/close.svg"
          alt="close icon"
          className={`absolute top-4 right-[18px] ${"cursor-pointer"} `}
          onClick={onClose}
        />
        <div className="modal-header pb-[23px]">
          <h4 className="heading">Download files</h4>
          <div className="description">
            Download available files related to this shipment.
          </div>
        </div>
        <div className="modal-body pt-[16px]">
          <div className="grid grid-cols-1 gap-y-[10px] download-popup-modal-body" >
            {loading ? (
              <div className="text-center loader">
                <CircularProgress />
              </div>
            ) : Array.isArray(documents) && documents?.length > 0 ? (
              documents?.map((doc) => {
                return (
                  <>
                    <div
                      className="p-[13px] flex items-center justify-between file-item"
                      key={doc?.FileName}
                    >
                      <div className="flex items-center justify-start gap-[13px]" style={{maxWidth:"90%"}}>
                        <img src="/assets/icons/file.svg" alt="file" />
                        <a href={""} onClick={(e)=>{e.preventDefault();   handleDownloadClick(doc?.ImageData, doc?.FileName) }} >
                          <div className="file-name"> {doc?.Type?.Description}({doc?.Type?.Code})</div>
                          {/* <div className="file-description">{splitExtensionAndCreateDocType(doc?.FileName).docType} {splitExtensionAndCreateDocType(doc?.FileName).name} </div> */}
                        </a>
                      </div>
                      <div>
                      <button
                        onClick={() =>
                          handleDownloadClick(doc?.ImageData, doc?.FileName)
                        }
                      >
                        <img
                          src="/assets/icons/downloads.svg"
                          alt="downloads"
                          style={{ cursor: "pointer" }}
                        />
                      </button>
                      </div>     
                    </div>
                  </>
                );
              })
            ) : (
              "No File Found "
            )}
          </div>

          <div className="btn-modal mt-4 pt-[14px] pb-[18px] w-full flex items-end justify-center">
            <button onClick={() => handleDownloadAll()}>
              <span>Download All</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
