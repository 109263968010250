import React, { useState } from 'react'
import '../scss/pages/tariffs.scss'
import Layout from '../components/common/layout';

export default function Tariffs() {
    const [showList, setShowList] = useState(false)
    const handleShowList=()=>{
        setShowList(!showList);
    }
    return (
        <>
        <Layout/>
            <div className='mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper tariffs md:pt-[26px] md:pr-9 md:pb-[27px] md:pl-[26px] '>
                <div className='tariffs-card pt-[14px] md:pt-[23px] pl-4 md:pl-6 pb-5 md:pb-6 pr-4 md:pr-6 bg-white rounded-[15px]'>
                    <div className='card-heading pb-[11px] md:pb-[18px]'>
                        <h4>Your Tarriffs</h4>
                    </div>
                    <div className='card-body pt-[14px] md:pt-[18px]'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 text-center gap-[9px] md:gap-7">
                            <div className='item active  pt-3 md:pt-[14px] pb-[15px] md:pb-4'><h5>SEA Tariff</h5> </div>
                            <div className='item pt-3 md:pt-[14px] pb-[15px] md:pb-4'> <h5>FCL Tariff</h5></div>
                            <div className='item pt-3 md:pt-[14px] pb-[15px] md:pb-4'> <h5>LCL Tariff</h5></div>
                            <div className='item pt-3 md:pt-[14px] pb-[15px] md:pb-4'><h5>AIR Tariff</h5></div>
                            <div className='item  pt-3 md:pt-[14px] pb-[15px] md:pb-4 hidden md:inline-block'><h5>ROAD Tariff</h5></div>
                        </div>
                    </div>
                </div>
                <div className='tariffs-card pt-[14px] md:pt-[23px] pl-4 md:pl-6 pb-6 pr-4 md:pr-6 bg-white rounded-[15px] mt-[13px] md:mt-[27px]'>
                    <div className='card-heading pb-[10px] md:pb-4'>
                        <h4>Details</h4>
                    </div>
                    <div className='card-body pt-6 md:pt-[21px]'>
                        <div className="card-body-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-[13px] md:gap-y-[42px]">
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Attention</h4>
                                <p >Ravi Sood</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Email</h4>
                                <p>ravi.sood@ced-elec.co.uk</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Quote Type</h4>
                                <p >FCL</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Shipment Terms</h4>
                                <p >EXW</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Date Issued</h4>
                                <p >14/03/2023</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Quote Reference:</h4>
                                <p >Test 1</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Valid From</h4>
                                <p >14/03/2023</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Type</h4>
                                <p >31/03/2023</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Prepared By</h4>
                                <p >Olivia Coleman</p>
                            </div>
                            <div className='card-list-item flex items-start justify-start md:justify-center gap-2 flex-row md:flex-col '>
                                <h4>Contact Number</h4>
                                <p >+44 (0) 208 572 7001</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tariffs-card tariffs-card-table ocean pt-5 md:pt-[23px] bg-white rounded-[15px] mt-[22px] md:mt-[33px]' >
                    <div className='card-heading px-[15px] md:px-[28px] '>
                        <h4 className='pb-[16px] border-bottom-light'>Ocean Freight Rates</h4>
                    </div>
                    <div className='card-body pb-[19px] md:pb-0'>
                        <div className='table-wrapper overflow-x-scroll 2xl:overflow-x-hidden pb-[17px] md:pb-0'>
                            <table className='min-w-[900px] table-fixed'>
                                <thead>
                                    <tr>
                                        <th className='text-left'>Port of Origin</th>
                                        <th className='text-left'>Port of Destination</th>
                                        <th className='text-left'>{`20’GP`}</th>
                                        <th className='text-left'>{`40’GP`}</th>
                                        <th className='text-left'>{`40’HC`}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Hong Kong</td>
                                        <td>Felixstowe</td>
                                        <td>US$795</td>
                                        <td>US$1,295</td>
                                        <td>US$1,295</td>
                                    </tr>
                                    <tr>
                                        <td>Ningbo</td>
                                        <td>Felixstowe</td>
                                        <td>US$795</td>
                                        <td>US$1,295</td>
                                        <td>US$1,295</td>
                                    </tr>
                                    <tr>
                                        <td>Qingdao</td>
                                        <td>Felixstowe</td>
                                        <td>US$795</td>
                                        <td>US$1,295</td>
                                        <td>US$1,295</td>
                                    </tr>
                                    <tr>
                                        <td>Shanghai</td>
                                        <td>Felixstowe</td>
                                        <td>US$795</td>
                                        <td>US$1,295</td>
                                        <td>US$1,295</td>
                                    </tr>
                                    <tr>
                                        <td>Yantian</td>
                                        <td>Felixstowe</td>
                                        <td>US$795</td>
                                        <td>US$1,295</td>
                                        <td>US$1,295</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
               
                <div className="mt-[29px] md:mt-[33px]">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-[30px] md:gap-[33px]">
                        <div className='tariffs-card tariffs-card-table pt-5 md:pt-[23px] bg-white rounded-[15px]'>
                            <div className='card-heading px-[15px] md:px-[28px]'>
                                <h4 className='pb-[16px] border-bottom-light'>UK Landside Charges</h4>
                            </div>
                            <div className='card-body pb-[19px] md:pb-0'>
                                <div className='table-wrapper overflow-x-scroll 2xl:overflow-x-hidden pb-[17px] md:pb-0'>
                                    <table className='min-w-[620px] table-auto'>
                                        <thead>
                                            <tr>
                                                <th className='text-left'>Charge Description</th>
                                                <th className='text-left w-[180px]'>Unit</th>
                                                <th className='text-left w-[100px]'>Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Terminal Handling Charge</td>
                                                <td>Per container</td>
                                                <td>£180</td>
                                            </tr>
                                            <tr>
                                                <td>Documentation</td>
                                                <td>Per bill</td>
                                                <td>£45</td>
                                            </tr>
                                            <tr>
                                                <td>Port Security & Infrastructure</td>
                                                <td>Per container</td>
                                                <td>£65</td>
                                            </tr>
                                            <tr>
                                                <td>Customs Clearance (Deferment Fee may apply)</td>
                                                <td>Per declaration (up to 4)</td>
                                                <td>£45</td>
                                            </tr>
                                            <tr>
                                                <td>Additonal lines</td>
                                                <td>Per commodity</td>
                                                <td>£2</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='tariffs-card tariffs-card-table pt-5 md:pt-[23px] bg-white rounded-[15px]'>
                            <div className='card-heading px-[15px] md:px-[28px]'>
                                <h4 className='pb-[16px] border-bottom-light'>Inland Haulage</h4>
                            </div>
                            <div className='card-body  pb-[19px] md:pb-0'>
                                <div className='table-wrapper overflow-x-scroll 2xl:overflow-x-hidden pb-[17px] md:pb-0'>
                                    <table className='min-w-[620px] table-auto'>
                                        <thead>
                                            <tr>
                                                <th className='text-left'>Transport</th>
                                                <th className='text-left w-[120px]'>Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Felixstowe to Dagenham</td>
                                                <td>£420</td>
                                            </tr>
                                            <tr>
                                                <td>Fuel Surcharge (Valid as at Time of Shipment)</td>
                                                <td>15.00%</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td ></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tariffs-card pt-[23px] pl-7 md:pl-6 pb-[30px] md:pb-6 pr-[31px] md:pr-[29px] bg-white rounded-[15px] mt-[29px] md:mt-[38px]'>
                    <div className='card-heading pb-[18px]'>
                        <h4>Important Notes</h4>
                    </div>
                    <div className='card-body pt-4 md:pt-[18px]'>
                        <ol className={`${showList?'':'max-h-[320px] overflow-hidden'} `}>
                            <li>Unless otherwise indicated, the rates quoted are subject to all applicable charges, current adjustment factors and {`GRI’s `}in effect in the EIO tariff at the time of shipment </li>
                            <li>The rates quoted are subject to any and all surcharges marked above that may be in effect in the EIO Tariff at the time of shipment. </li>
                            <li>If the size, weight, measurment, pick-up and or delivery location of the quoted shipment changes; promptly notify ICL UK Ltd as the changes may affect the rates quoted above </li>
                            <li>All shipments are subject to re-weigh and re-measurement by ICL UK Ltd and / or its subcontractors </li>
                            <li>The rate quoted is not applicable to dangerous goods or perishable cargo</li>
                            <li>Demurrage and detention free time and related charges are for the account of the cargo owner and subjext to local carrier and terminal terms and conditions </li>
                            <li>For deliveries arranged by ICL UK Ltd, the customer shall be responsible to pay any and all accessorial charges and liabilities which are incurred with respect to the movement and storage of the shipment unless otherwise approved in writing by ICL UK Ltd </li>
                            <li>If applicable, charges will be converted into the relevant currency based on the ruling exchange rate - a curreny conversion surcharge may apply </li>
                            <li>All rates are offered subject to space and equipment availability at port of origin</li>
                            <li>All rates exclude marine insurance - “All Risk” cargo insurance is available - please ask your ICL UK representative for more information.</li>
                        </ol>
                        <div className='important-notes-footer mt-[17px]'>
                            All business is undertaken in accordance with BIFA Standard Trading Terms & Conditions (2021) - a copy is available on request
                        </div>
                        <button className='read-more-btn w-full pt-[8px] pb-[7px] px-[15px] mt-[17px] md:hidden flex items-center justify-between ' onClick={handleShowList}>Read More <img className='rotate-90 w-[5px]' src="/assets/icons/right-white-arrow.svg" alt="" /></button>
                    </div>
                </div>

            </div>
        </>
    )
}
