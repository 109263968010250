import React from 'react';
import '../scss/pages/customs.scss';
import CustomsList from '../components/customs/list';
import Layout from '../components/common/layout';

function Customs() {
  return (
    <div>
      <Layout/>
      <div className='mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper md:pt-[26px] md:pr-9 md:pb-[27px] md:pl-[26px]'>
        <div className='customs bg-white'>
          <div className="custom-list-header">
            <h4 className='customs-heading'>Your Customs Jobs</h4>
            <p className='customs-text pr-[68px] sm:pr-0'>{`Here’s where you keep up with all your current customs jobs.`}</p>
          </div>
          <CustomsList/>
        </div>
      </div>
    </div>
  )
}

export default Customs
