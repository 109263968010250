import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import cruiseIcon from "../../assets/cruise.svg";


function Map({
  height,
  width,
  markers = [],
  points = { sea: [], currentSea: [] },
}) {
  const startingIcon = {
    url: "https://portal.sfgforwarding.co.uk/assets/images/dark-dot-circle.svg", // Replace with your custom icon image URL
    scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
  };
  const midIcon = {
    url: "https://portal.sfgforwarding.co.uk/assets/images/light-dot-circle.svg", // Replace with your custom icon image URL
    scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
  };
  const destinationIcon = {
    url: cruiseIcon, // Replace with your custom icon image URL
    scaledSize: new window.google.maps.Size(35, 35), // Adjust the size as needed
  };
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    // eslint-disable-next-line
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };
  const styles = {
    silver: [
      {
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdbdbd" }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#c9c9c9" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
    ],
  };
  const totalMarkers = markers.length - 1;
  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: width, height: height }}
      options={{ styles: styles.silver }}
    >
      {markers.map(({ id, name, position }, index) => (
        <>
          <Marker
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
            icon={
              index === 0
                ? startingIcon
                : index === totalMarkers?
                startingIcon:
                 midIcon
            }
            options={
              index === totalMarkers
                ? {
                    strokeColor: "#4BD1FD",
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  }
                : {}
            }
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <div>{name}</div>
                  <div>Latitude: {position.lat}</div>
                  <div>Longitude: {position.lng}</div>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        </>
      ))}
      {points?.currentSea?.length > 0 && (
        <Marker
          position={{
            lat: points?.currentSea[0]?.[0],
            lng: points?.currentSea[0]?.[1],
          }}
          icon={destinationIcon}
          options={{}}
          optimized={false}
        />
      )}

      {points?.sea?.length > 0 && (
        <Polyline
          path={points?.sea?.map((item) => ({
            lat: item[0],
            lng: item[1],
          }))}
          options={{
            strokeColor: "#4BD1FD",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          }}
        />
      )}
      {points?.currentSea?.length > 0 && (
        <Polyline
          path={points?.currentSea?.map((item) => ({
            lat: item[0],
            lng: item[1],
          }))}

          options={{
            strokeColor: "#4BD1FD",
            strokeOpacity: 1.0,
            strokeWeight: points?.currentSea?.length > 0 ? 0.1 : 2,
            icons:
              points?.sea?.length > 0
                ? [
                    {
                      icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 2.5,
                        strokeColor: "#4BD1FD",
                      },
                      offset: "0",
                      repeat: "20px",
                    },
                  ]
                : [],
          }}
        />
      )}
    </GoogleMap>
  );
}

export default Map;
