import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import useFetchedEvents from "./events";
import { Axios } from "../../pages/config";
import { LOCAL_CLIENT_CODE, LOCAL_STORAGE_KEYS } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getLocalValue,
  removeLocalValue,
  setLocalValue,
} from "../../common/utils";
export default function Calendar() {
  const [keyword, setKeyword] = useState("");
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilterEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const fetchDataFromAPI = async () => {
    try {
      setLoading(true);
      let url = `/dashboard-events?local_client_code=${LOCAL_CLIENT_CODE}`;
      const response = await Axios.get(url);
      const data = response?.events;
      // if(data?.length>0){
      //   setLocalValue(LOCAL_STORAGE_KEYS?.calendarEvents, JSON.stringify(data));
      //   setLocalValue(LOCAL_STORAGE_KEYS?.calendarEventLastRefreshDate,new Date().toLocaleDateString())
      // }
      // else {
      //   removeLocalValue(LOCAL_STORAGE_KEYS?.calendarEvents);
      //   removeLocalValue(LOCAL_STORAGE_KEYS?.calendarEventLastRefreshDate);
      // }
    
      let temp_events = [];
      for (const item of data) {
        temp_events.push({
          title: item.title,
          start: item.start,
          backgroundColor: item.backgroundColor,
          borderColor: item.borderColor,
          eventType: "shipped",
        });

        temp_events.push({
          title: item.title,
          start: item.end,
          backgroundColor: "#FFA500",
          borderColor: "#FFA500",
          eventType: "arriving",
        });

        if (item.delivery !== null) {
          temp_events.push({
            title: `${item.title}`,
            start: item.delivery,
            backgroundColor: "#00A7FF",
            borderColor: "#00A7FF",
            eventType: "delivery",
          });
        }
      }

      setEvents(temp_events);
      setFilterEvents(temp_events);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const navigateToDetailPage = (shipmentID) => {
    navigate(`/shipments/${shipmentID}`);
  };

  const handleEventClick = (clickInfo) => {
    const id = clickInfo.event?._def?.title;
    navigateToDetailPage(id);
  };

  useEffect(() => {
    const lastRefreshDate = getLocalValue(LOCAL_STORAGE_KEYS?.calendarEventLastRefreshDate);
    const currentDate = new Date().toLocaleDateString();
    if (isLoading === false) {
      const cachedData = getLocalValue(LOCAL_STORAGE_KEYS?.calendarEvents);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        let temp_events = [];
        for (const item of parsedData) {
          temp_events.push({
            title: item.title,
            start: item.start,
            backgroundColor: item.backgroundColor,
            borderColor: item.borderColor,
            eventType: "shipped",
          });

          temp_events.push({
            title: item.title,
            start: item.end,
            backgroundColor: "#FFA500",
            borderColor: "#FFA500",
            eventType: "arriving",
          });

          if (item.delivery !== null) {
            temp_events.push({
              title: `${item.title}`,
              start: item.delivery,
              backgroundColor: "#00A7FF",
              borderColor: "#00A7FF",
              eventType: "delivery",
            });
          }
        }

        if (events !== temp_events) {
          setEvents(temp_events);
          setFilterEvents(temp_events);
        }
      } else if ((!cachedData && events.length === 0)||(currentDate!==lastRefreshDate)) {
        fetchDataFromAPI();
      }
    }
  }, []);
 
  const filterEvent = (eventType) => {
    setLoading(true);
    if (eventType === "") {
      setFilterEvents(events);
    } else {
      const tempFilterEvents = events.filter(
        (item) => item.eventType === eventType
      );
      setFilterEvents(tempFilterEvents);
    }
    setKeyword(eventType);
    setLoading(false);
  };

  return (
    <div className="calendar" style={{ height: "610px", overflowY: "auto" }}>
      <div className="legend">
        <div
          className={`legendItem shipped ${
            keyword === "shipped" ? "active" : ""
          }`}
          onClick={() => {
            if (!isLoading) {
              filterEvent("shipped");
            }
          }}
        >
          Shipped
        </div>

        <div
          className={`legendItem arriving ${
            keyword === "arriving" ? "active" : ""
          }`}
          onClick={() => {
            if (!isLoading) {
              filterEvent("arriving");
            }
          }}
        >
          Arriving
        </div>
        <div
          className={`legendItem delivery ${
            keyword === "delivery" ? "active" : ""
          }`}
          onClick={() => {
            if (!isLoading) {
              filterEvent("delivery");
            }
          }}
        >
          Delivery
        </div>
        <div
          className={`legendItem allShipment ${keyword === "" ? "active" : ""}`}
          onClick={() => {
            if (!isLoading) {
              filterEvent("");
            }
          }}
        >
          All Shipments
        </div>
      </div>
      {isLoading ? (
        <div className="text-center loader">
          <CircularProgress />
        </div>
      ) : (
        <FullCalendar
          plugins={[dayGridPlugin]}
          headerToolbar={{
            right: "prev,next",
            left: "title",
            center: "Custom",
          }}
          eventClick={handleEventClick}
          events={filteredEvents}
          dayMaxEvents={5}
          height="auto"
        />
      )}
    </div>
  );
}
