import React, { useState, useEffect } from "react";
import Widget from "../components/common/widget";
import "../scss/pages/dashboard.scss";
import ActiveShipments from "../components/dashboard/active-shipments";
import Volumes from "../components/dashboard/volumes";
import Calendar from "../components/dashboard/calendar";
import Select from "react-dropdown-select";
// import { BsThreeDots } from 'react-icons/bs';
import Layout from "../components/common/layout";
import { Link } from "react-router-dom";
import { LOCAL_CLIENT_CODE, LOCAL_STORAGE_KEYS } from "../common/constants";
import {
  convertDateFormat,
  getLocalValue,
  resetPageNumber,
  setLocalValue,
  sortByKey,
} from "../common/utils";
import CircularProgress from "@mui/material/CircularProgress";
import { Axios } from "./config";
const options = [
  {
    value: 1,
    label: "1 month",
  },
  {
    value: 2,
    label: "2 months",
  },
  {
    value: 6,
    label: "6 months",
  },
  {
    value:12,
    label:"12 months"
  }
];

export default function Dashboard() {
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [barData, setBarData] = useState([]);
  const [selectedBarData, setSelectedBarData] = useState([]);
  const [pendingQuotes, setPendingQuotes] = useState("");
  const [confirmbooking, setConfirmBooking] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [months, setMonths] = useState();
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(5);
  const [upcomingEventsProcessing, setUpcomingEventsProcessing] =
    useState(false);

  const [pageInfo, setPageInfo] = useState("");
  const [pageLength, setPageLength] = useState("");
  const [currentPageLength, setCurrentPageLength] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(5);
  const [dailyUpdate, setDailyUpdate] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [dailyUpdateProcessing, setDailyUpdateProcessing] = useState(false);
  const [notificationProcessing, setNotificationProcessing] = useState(false);
  const [originTypes, setOriginTypes] = useState([]);

  const handlePageChange = (page) => {
    if (page >= 1) {
      setCurrentPage(page);
      setLocalValue(LOCAL_STORAGE_KEYS?.dashboardUpcomingListCurrentPage, page);
    }
  };

  function handleChange(values) {
    const value = values?.[0]?.value;
    const sOrigin = barData.find((item) => item.origin === selectedOrigin);
    const sortedOriginData=sortByKey(sOrigin.data,"x");
    let selectedData = { ...sOrigin, data:sortedOriginData.slice(-value) };
    setSelectedBarData([selectedData]);
    setMonths(12);
  }
  const onOriginChange = (values) => {
    const value = values[0].value;
    const sOrigin = barData.find((item) => item.origin === value);
    const sortedOriginData=sortByKey(sOrigin.data,"x");
    setMonths("")
    setSelectedOrigin(value);
    if (months) {
      let selectedData = { ...sOrigin,data:sortedOriginData.slice(-value)  };
      setSelectedBarData([selectedData]);
    } else {
      setSelectedBarData(barData.filter((item) => item.origin === value));
    }
  };
  // pending quotes Api
  const pendingQuotesAPI = async () => {
    try {
      const response = await Axios.get(
        `/pending-quotes?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      setPendingQuotes(response);
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };
  // daily update API
  const DailyUpdateAPI = async () => {
    try {
      setDailyUpdateProcessing(true);
      setLocalValue(
        LOCAL_STORAGE_KEYS?.dashboardLastRefreshDate,
        new Date().toLocaleDateString()
      );
      const response = await Axios.get(
        `/dashboard-daily-update?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      const data = response;
      setLocalValue(LOCAL_STORAGE_KEYS?.dailyUpdate, JSON.stringify(data));
      setDailyUpdate(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setDailyUpdateProcessing(false);
    }
  };
  useEffect(() => {
    resetPageNumber();
    pendingQuotesAPI();
    confirmBookingAPI();
    fetchVolumeAPI();
  }, []);
  // confirm booking
  const confirmBookingAPI = async () => {
    try {
      const response = await Axios.get(
        `/confirmed-bookings?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      //   setUserData({myObject : getData})
      setConfirmBooking(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //Notification Api
  const fetchNotificationFromAPI = async () => {
    try {
      setNotificationProcessing(true);
      const response = await Axios.get(
        `/notifications?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      const data = response;
      setNotificationData(data);
      if(data?.length>0){
        setLocalValue(LOCAL_STORAGE_KEYS?.notificationData, JSON.stringify(data));
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    } finally {
      setNotificationProcessing(false);
    }
  };
  useEffect(() => {
    const lastRefreshDate = getLocalValue(
      LOCAL_STORAGE_KEYS?.dashboardLastRefreshDate
    );
    const currentDate = new Date().toLocaleDateString();
    if (dailyUpdateProcessing === false) {
      const cachedData = getLocalValue(LOCAL_STORAGE_KEYS?.dailyUpdate);

      if (currentDate !== lastRefreshDate) {
        DailyUpdateAPI();
        fetchNotificationFromAPI();
      }
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (dailyUpdate !== parsedData) {
          setDailyUpdate(parsedData);
        }
      } else if (
        (!cachedData && dailyUpdate.length === 0) ||
        currentDate === lastRefreshDate
      ) {
        DailyUpdateAPI();
      }
    }
    if (notificationProcessing === false) {
      const cachedNotification = getLocalValue(
        LOCAL_STORAGE_KEYS?.notificationData
      );
      if (cachedNotification) {
        const parsedData = JSON.parse(cachedNotification);
        if (notificationData !== parsedData) {
          setNotificationData(parsedData);
        }
      } else if (
        (!cachedNotification && notificationData.length === 0) ||
        currentDate === lastRefreshDate
      ) {
        fetchNotificationFromAPI();
      }
    }
  }, []);

  const eventsDataAPI = async () => {
    try {
      setUpcomingEventsProcessing(true);
      const response = await Axios.get(
        `/dashboard-events?local_client_code=${LOCAL_CLIENT_CODE}&per_page=${itemsPerPage}&page=${currentPage}`
      );
      const events = response?.events;
      let temp_events = [];
      for (const item of events) {
        temp_events.push(
          {
            title: `${item.title}`,
            subTitle: `Shipped`,
            start: item.start,
            className: "text-shipped",
            backgroundColor: item.backgroundColor,
            borderColor: item.borderColor,

            //   end: item.end,
          },
          {
            title: `${item.title}`,
            subTitle: `Arriving`,
            start: item.end,
            className: "text-arrived",
            backgroundColor: "#FFA500",
            borderColor: "#FFA500",
          }
        );
      }

      setUpcomingEvents(temp_events);

      setLastPage(response.pagination.last_page);
      const totalRecords = response?.pagination?.total || 0;
      setTotalPages(Math.ceil(totalRecords / itemsPerPage));
      setPageInfo(response?.pagination?.total);
      setPageLength(response?.pagination?.to);
      const fromPage =
        response?.pagination?.current_page * itemsPerPage - (itemsPerPage - 1);
      setCurrentPageLength(fromPage);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setUpcomingEventsProcessing(false);
    }
  };

  useEffect(() => {
    eventsDataAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (totalPages > 0) {
      const savedPage = getLocalValue(
        LOCAL_STORAGE_KEYS?.dashboardUpcomingListCurrentPage
      );
      if (savedPage && savedPage <= totalPages) {
        setCurrentPage(parseInt(savedPage, 10));
      } else {
        setLocalValue(LOCAL_STORAGE_KEYS?.dashboardUpcomingListCurrentPage, 1);
      }
    }
  }, [totalPages]);
  // ...

  // ...

  // dashboard volume APi
  const fetchVolumeAPI = async () => {
    try {
      const response = await Axios.get(
        `/dashboard-bar-chart?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      const options = [];
      const barsData = response
      barsData.forEach((item) => {
    const dataArray = Object.keys(item?.data).map(key => ({ x: key, y: item?.data[key] }));
  dataArray.sort((a, b) => {
      // Assuming x is a string in the format 'YYYY-MM'
      return a.x.localeCompare(b.x);
  });
  item.data = dataArray;
 
         if (item?.origin && item?.origin !== null && item?.origin!=="All") {
          options.push({ label: item.origin, value: item.origin });
        }
      });
      options.unshift({label:"All",value:"All"})
      setBarData(barsData);
      setOriginTypes(options);
      const sOri = options?.[0]?.value;
      setBarData(barsData);
      setSelectedOrigin(sOri);
      setSelectedBarData(barsData.filter((item) => item.origin === sOri));
      setSelectedOrigin(sOri);
      setSelectedBarData(barsData.filter((item) => item.origin === sOri));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPageNumbers = () => {
    const pagesToShow = 3;
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  return (
    <div className="3xl:container">
      <Layout />
      {/* <div className='box'></div> */}
      <div className=" page-wrapper mt-[167px] md:mt-[89px] md:ml-[307px]  md:pt-[26px] md:pr-9 md:pb-[30px] md:pl-[26px]">
        <section className="daily-update">
          <div className="card pt-6 md:pt-[22px] px-[14px] md:pl-[21px] pb-[18px] md:pb-[21px] md:pr-[18px]">
            <div className="card-heading pb-[18px] md:pl-[5px] flex items-center justify-between">
              <h4 className="heading ">Daily Update</h4>
              {/* <BsThreeDots className='hidden md:inline-block' /> */}
            </div>
            <div className="card-body pt-4 md:pt-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[15px]  md:gap-6">
                {dailyUpdateProcessing ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Widget
                      color={`primary`}
                      heading={
                        dailyUpdate && dailyUpdate?.daily_update?.etas_updated
                      }
                      subHeading={`ETA’s Updated`}
                      iconClass={`timer.svg`}
                      redirectUrl="/eventShipments/eta-updates/"
                    />
                    <Widget
                      color={`primary`}
                      heading={
                        dailyUpdate && dailyUpdate?.daily_update?.etds_updated
                      }
                      subHeading={`ETD’s Updated`}
                      iconClass={`map-pin.svg`}
                      redirectUrl="/eventShipments/etd-updates/"
                    />
                    <Widget
                      color={`primary`}
                      heading={
                        dailyUpdate &&
                        dailyUpdate?.daily_update?.awaiting_delivery
                      }
                      subHeading={`Awaiting Delivery`}
                      iconClass={`shipping.svg`}
                      redirectUrl={`/eventShipments/at-destination-port-shipments`}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <div className="mt-[25px] md:mt-[31px]">
          <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 gap-[30px]">
            <div>
              <section className="active-shipments">
                <ActiveShipments />
              </section>
              <div className="grid md:hidden grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 md:gap-[30px] mb-6">
                <Widget
                  color={`success`}
                  heading={confirmbooking?.confirmed_bookings}
                  subHeading={`Confirmed Bookings`}
                  iconClass={`awesome-check.svg`}
                  redirectUrl={"/bookings"}
                />

                <Link to={"/quotes/yourquotes/YOUR%20QUOTES"}>
                  <Widget
                    color={`warning`}
                    heading={pendingQuotes?.pending_quotes}
                    subHeading={`Pending Quotes`}
                    iconClass={`price-tag.svg`}
                  />
                </Link>
              </div>
              <div className="card pt-[23px] md:pt-[22px] pl-[14px] pb-[22px] md:pb-[18px] pr-[18px] volumes-section">
                <div className="card-heading pb-[14px] md:pl-[12px]">
                  <div className="flex items-start justify-between md:gap-[10px]">
                    <h4 className="heading hidden md:block">Origins</h4>
                    <h4 className="heading md:hidden">Daily Update</h4>
                    <div className="xl:w-full  btn-wrapper flex items-start md:items-center justify-between md:justify-start 3xl:justify-end flex-wrap gap-[10px] md:gap-[7px]">
                      <Select
                        searchable={false}
                        className="dropdown-btn btn-dropdown min-w-[68px]"
                        placeholder={"12 months"}
                        options={options}
                        onChange={handleChange}
                      />
                      <Select
                        searchable={false}
                        className="dropdown-btn btn-dropdown min-w-[78px]"
                        placeholder={selectedOrigin || "Choose Origin"}
                        options={originTypes}
                        onChange={onOriginChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-4 md:pt-[22px]">
                  <Volumes data={selectedBarData} />
                </div>
              </div>
            </div>
            <div className="hidden md:block ">
              <div className="card pt-[22px] pl-[21px] pb-[21px] pr-[9px] mb-[27px] notifications">
                <div className="card-heading pb-[18px] pl-[5px]">
                  <h4 className="heading">Notifications</h4>
                </div>

                <div className="card-body overflow-y-auto h-[503px]">
                  {notificationProcessing ? (
                    <div className="text-center loader">
                      <CircularProgress />
                    </div>
                  ) :notificationData.length===0?<p className="text-center">No Record Found</p>: (
                    <ul className="list-wrapper pr-[21px]">
                      {notificationData &&
                        notificationData?.map((item) => {
                          const searchTerm = item?.shipment_id;
                          const iconMap = {
                            user_update: "user.svg",
                            message: "messenger.svg",
                            file_upload: "messenger.svg",
                            quote_response: "price-tag-2.svg",
                            eta_update: "container.svg",
                            etd_update: "container.svg",
                          };
                          const icon = iconMap[item?.type] || "";
                          let boldMessage = item?.message;
                          if (searchTerm) {
                            const regex = /(?:ICL\d+|DCU\d+|RND\d+)/g;

                            // Replace the matched patterns with <strong> tags
                            boldMessage = boldMessage?.replace(
                              regex,
                              (match) => `<strong>${match}</strong>`
                            );
                            // boldMessage = boldMessage.replace(/\b(?:ICL|otherPrefix)\d+\b/g, match => `<strong>${match}</strong>`);
                          } else {
                            const regex = new RegExp(searchTerm, "gi");
                            boldMessage = boldMessage?.replace(
                              regex,
                              (match) => `<strong>${match}</strong>`
                            );
                          }

                          return (
                            <Link to={`/shipments/${item?.shipment_id}`}>
                              <li
                                key={item?.id}
                                className="cursor-pointer flex items-center"
                              >
                                <div className="icon-wrapper bg-img mr-[11px] flex-none">
                                  <img
                                    src={`/assets/icons/${icon}`}
                                    alt={item?.type}
                                  />
                                </div>
                                <div
                                  className="text-darkblue"
                                  dangerouslySetInnerHTML={{
                                    __html: boldMessage,
                                  }}
                                ></div>
                                <img
                                  className="ml-auto"
                                  src="assets/icons/right-arrow.svg"
                                  alt="right-arrow"
                                />
                              </li>
                            </Link>
                          );
                        })}
                    </ul>
                  )}
                </div>
              </div>

              <div className="hidden md:grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  gap-[30px] box-shadow-wrapper">
                <Link to={"/bookings"}>
                  <Widget
                    color={`success`}
                    heading={confirmbooking?.confirmed_bookings}
                    subHeading={`Confirmed Bookings`}
                    iconClass={`awesome-check.svg`}
                    redirectUrl={"/bookings"}
                  />
                </Link>
                <Link to={"/quotes/yourquotes/YOUR%20QUOTES"}>
                  <Widget
                    color={`warning`}
                    heading={pendingQuotes?.pending_quotes}
                    subHeading={`Pending Quotes`}
                    iconClass={`price-tag.svg`}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="box-shadow rounded-[15px]">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-1 bg-white rounded-[15px] mt-6 md:mt-[30px]">
            <div className="col-span-2  calendar-wrapper hidden md:block">
              <Calendar />
            </div>
            <section
              className="upcoming-event md:pl-[14px] xl:pl-[0px]"
              style={{ position: "relative" }}
            >
              <div className="pt-5 md:pt-[21px] pb-5 md:pb-[18px] px-[15px] xl:pl-0 pl-[15px] heading-wrapper">
                <h4 className="text-darkblue heading">Upcoming Events</h4>
              </div>
              <div className="upcoming-event-list-wrapper">
                <div className="list">
                  {upcomingEventsProcessing ? (
                    <div className="text-center">
                      <CircularProgress />{" "}
                    </div>
                  ) : upcomingEvents?.length > 0 ? (
                    upcomingEvents?.map((item, index) => (
                      <div className="list-item" key={index}>
                        <div
                          className={`list-item-heading flex items-center justify-between text-primary text-strong`}
                        >
                          <h6>
                            {item?.title} &nbsp;{" "}
                            <span className={item.className}>
                              {item.subTitle}{" "}
                            </span>
                          </h6>
                          <span>{convertDateFormat(item?.start)}</span>
                        </div>
                        <div className="flex items-center justify-between list-item-footer mt-1 gap-[8px] md:gap-1">
                          <span className="text-darkblue inline-block flex-none">
                            {item?.city} {item?.country}
                          </span>
                          <div className="arrow-icon-wrapper">
                            <div className="arrow-icon">
                              <div className="triangleline"></div>
                              <i className="arrow arrow-right"></i>
                            </div>
                          </div>
                          <span className="text-darkblue inline-block flex-none">
                            {item?.destination_city}
                            {item?.destination_country}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    "No Record Found"
                  )}
                  {/* <div className='list-item'>
                                        <div className='list-item-heading flex items-center justify-between text-primary text-strong'>
                                            <h6>ICL29354</h6>
                                            <span>11/04/2023</span>
                                        </div>
                                        <div className='flex items-center justify-between list-item-footer mt-1 gap-[8px] md:gap-1'>
                                            <span className='text-darkblue inline-block flex-none'>Shenzhen, China</span>
                                            <div className="arrow-icon-wrapper">
                                                <div className="arrow-icon">
                                                    <div className="triangleline"></div>
                                                    <i className="arrow arrow-right"></i>
                                                </div>
                                            </div>
                                            <span className='text-darkblue inline-block  flex-none'>Felixstowe, United Kingdom</span>
                                        </div>
                                    </div>
                                    <div className='list-item'>
                                        <div className='list-item-heading flex items-center justify-between text-primary text-strong'>
                                            <h6>USB29495</h6>
                                            <span>20/04/2023</span>
                                        </div>
                                        <div className='flex items-center justify-between list-item-footer mt-1 gap-[8px] md:gap-1'>
                                            <span className='text-darkblue inline-block flex-none'>Shenzhen, China</span>
                                            <div className="arrow-icon-wrapper">
                                                <div className="arrow-icon">
                                                    <div className="triangleline"></div>
                                                    <i className="arrow arrow-right"></i>
                                                </div>
                                            </div>
                                            <span className='text-darkblue inline-block  flex-none'>Felixstowe, United Kingdom</span>
                                        </div>
                                    </div>
                                    <div className='list-item'>
                                        <div className='list-item-heading flex items-center justify-between text-primary text-strong'>
                                            <h6>RUD12455</h6>
                                            <span>23/04/2023</span>
                                        </div>
                                        <div className='flex items-center justify-between list-item-footer mt-1 gap-[8px] md:gap-1'>
                                            <span className='text-darkblue inline-block flex-none'>Shenzhen, China</span>
                                            <div className="arrow-icon-wrapper">
                                                <div className="arrow-icon">
                                                    <div className="triangleline"></div>
                                                    <i className="arrow arrow-right"></i>
                                                </div>
                                            </div>
                                            <span className='text-darkblue inline-block  flex-none'>Felixstowe, United Kingdom</span>
                                        </div>
                                    </div>
                                    <div className='list-item'>
                                        <div className='list-item-heading flex items-center justify-between text-primary text-strong'>
                                            <h6>RND38495</h6>
                                            <span>06/04/2023</span>
                                        </div>
                                        <div className='flex items-center justify-between list-item-footer mt-1 gap-[8px] md:gap-1'>
                                            <span className='text-darkblue inline-block flex-none'>Shenzhen, China</span>
                                            <div className="arrow-icon-wrapper">
                                                <div className="arrow-icon">
                                                    <div className="triangleline"></div>
                                                    <i className="arrow arrow-right"></i>
                                                </div>
                                            </div>
                                            <span className='text-darkblue inline-block  flex-none'>Felixstowe, United Kingdom</span>
                                        </div>
                                    </div>
                                    <div className='list-item'>
                                        <div className='list-item-heading flex items-center justify-between text-primary text-strong'>
                                            <h6>RND38495</h6>
                                            <span>06/04/2023</span>
                                        </div>
                                        <div className='flex items-center justify-between list-item-footer mt-1 gap-[8px] md:gap-1'>
                                            <span className='text-darkblue inline-block flex-none'>Shenzhen, China</span>

                                            <div className="arrow-icon-wrapper">
                                                <div className="arrow-icon">
                                                    <div className="triangleline"></div>
                                                    <i className="arrow arrow-right"></i>
                                                </div>
                                            </div>
                                            <span className='text-darkblue inline-block  flex-none'>Felixstowe, United Kingdom</span>
                                        </div>
                                    </div> */}
                </div>
              </div>

              <section
                className={totalPages === 1 || totalPages === 0 ? "hidden" : ""}
              >
                <div
                  className={
                    "table-wrapper  pt-[13px] md:pt-[17px] px-0 pb-4 md:pb-[21px] bottom-0 w-full"
                  }
                >
                  <div className="grid grid-cols-2 gap-[14px] md:gap-0">
                    <div className="page-info pl-[14px] md:pl-[26px] col-span-2 md:col-span-1">
                      Showing {currentPageLength} to {pageLength} of {pageInfo}{" "}
                      entries
                    </div>
                    <nav
                      aria-label="Page navigation "
                      className="col-span-2 md:col-span-1"
                    >
                      <ul className="pagination justify-content-center pr-[25px]">
                        <li
                          className={`page-item page-next-pre ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <button className="page-link px-1">
                            <img
                              className="rotate-180 normal-image"
                              src="/assets/icons/right-arrow.svg"
                              alt=""
                            />
                            <img
                              className="rotate-180 image-hover"
                              src="/assets/icons/right-white-arrow.svg"
                              alt=""
                            />
                          </button>
                        </li>
                        <ul className="pagination pagination-mobile">
                          {getPageNumbers().map((pageNumber) => (
                            <li
                              onClick={() => handlePageChange(pageNumber)}
                              key={pageNumber}
                              className={`page-item ${
                                currentPage === pageNumber ? "active" : ""
                              }`}
                            >
                              <button className="page-link">
                                {pageNumber}
                              </button>
                            </li>
                          ))}
                        </ul>
                        <li
                          className={`page-item  page-next-pre ${
                            currentPage === lastPage ? "disabled" : ""
                          }`}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          {currentPage < lastPage && (
                            <button
                              className="page-link px-1"
                              disabled={currentPage === lastPage}
                            >
                              <img
                                className="normal-image"
                                src="/assets/icons/right-arrow.svg"
                                alt=""
                              />
                              <img
                                className="image-hover"
                                src="/assets/icons/right-white-arrow.svg"
                                alt=""
                              />
                            </button>
                          )}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
