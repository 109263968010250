import React, { useState } from "react";
import "../../../src/scss/popup.scss";

export default function DateRangeModal({ visible, onClose,setStartDate,setEndDate,startDate,endDate }) {
  const [date, setDate] = useState({ startDate: startDate||"", endDate:endDate||"" });
  const [errors, setErrors] = useState({ startDate: "", endDate: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update date object
    setDate((prevDate) => ({
      ...prevDate,
      [name]: value,
    }));

    // Clear error for the field being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    // Validate start date and end date
    if (name === "startDate") {
      const selectedStartDate = new Date(value);
      const selectedEndDate = new Date(date.endDate);

      if (selectedEndDate && selectedStartDate > selectedEndDate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          startDate: "Start date cannot be after end date",
        }));
      }
    } else if (name === "endDate") {
      const selectedEndDate = new Date(value);
      const selectedStartDate = new Date(date.startDate);

      if (selectedStartDate && selectedEndDate < selectedStartDate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          endDate: "End date cannot be before start date",
        }));
      }
    }
  };
  const handleApply = () => {
    // Check if there are no errors for both start date and end date
    if (!errors.startDate && !errors.endDate) {
      // Call setStartDate and setEndDate with the values from the date state
      setStartDate(date.startDate);
      setEndDate(date.endDate);
      onClose();
    }
  };
  return (
    <div
      id="table-modal-wrapper"
      className={`modal-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center transition-all ease-in-out duration-300 ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div
        className={`modal w-[629px] bg-white rounded-[15px] pt-[26px] pr-[27px] pb-[26px] pl-[30px] relative transition-all ease-in-out duration-300 ${
          visible ? "block" : "hidden"
        }`}
      >
        <img
          src="/assets/icons/close.svg"
          alt="close"
          className="absolute top-4 right-[18px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-header pb-[22px]">
          <h4 className="heading">Filter data on date range</h4>
          <div className="description">
          Choose a start and an end date which will filter based upon ETD
          </div>
        </div>
        <div className="modal-body pt-[23px]">
          <div>
            <div>
              <div className="pb-[15px] modal-item flex-direction-column">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={date.startDate}
                  className="w-full"
                  onChange={handleChange}
                />
                {errors.startDate && (
                  <p className="text-red-500">{errors.startDate}</p>
                )}
              </div>
              <div className="pb-[15px] modal-item flex-direction-column">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={date.endDate}
                  className="w-full"
                  onChange={handleChange}
                />
                {errors.endDate && (
                  <p className="text-red-500">{errors.endDate}</p>
                )}
              </div>
            </div>
          </div>
          {(date.startDate || date.endDate) && (
            <button
              className="btn-modal-danger pt-[14px] pb-[18px] w-full mt-[19px] flex items-end justify-center"
              onClick={() => {
                setDate({startDate:"",endDate:""})
                setStartDate("");
                setEndDate("");
                onClose();
              }}
            >
              Clear
            </button>
          )}

          <button
            className="btn-modal pt-[14px] pb-[18px] w-full mt-[19px] flex items-end justify-center"
            onClick={handleApply}
            disabled={errors.startDate||errors.endDate}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
