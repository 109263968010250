import { getLocalValue } from "./utils";
export const USER_NAME = getLocalValue("user-name");
export const LOCAL_STORAGE_KEYS = {
  token: "user-token",
  clientCode: "client-code",
  userName: "user-name",
  profileImage: "profile-image",
  bookingListCurrentPage: `${USER_NAME}-booking-list-current-page`,
  customListCurrentPage: `${USER_NAME}-custom-list-current-page`,
  dashboardUpcomingListCurrentPage: `${USER_NAME}-dashboard-upcoming-event-list-current-page`,
  eventsListCurrentPage: `${USER_NAME}-event-list-current-page`,
  shipmentListCurrentPage: `${USER_NAME}-shipment-list-current-page`,
  containerListCurrentPage: `${USER_NAME}-container-list-current-page`,
  trackingListCurrentPage: `${USER_NAME}-tracking-shipment-list-current-page`,
  shipmentListRecords:`${USER_NAME}-shipment-list-records`,
  bookingListRecords:`${USER_NAME}-booking-list-records`,
  containerListRecords:`${USER_NAME}-container-list-records`,
  customListRecords:`${USER_NAME}-custom-list-records`,
  bookingColumns: `${USER_NAME}-booking-columns`,
  customColumns: `${USER_NAME}-custom-columns`,
  eventColumns: `${USER_NAME}-events-columns`,
  shipmentColumns: `${USER_NAME}-shipment-columns`,
  containerColumns: `${USER_NAME}-container-columns`,
  quoteColumns: `${USER_NAME}-quote-columns`,
  companyName:'company-name',
  dailyUpdate:`${USER_NAME}-daily-update`,
  notificationData:`${USER_NAME}-notification-data`,
  calendarEvents:`${USER_NAME}-calendar-events`,
  dashboardLastRefreshDate:`${USER_NAME}-dashboard-last-refresh-date`,
  calendarEventLastRefreshDate:`${USER_NAME}-calendar-events-last-refresh-date`,
  logoutMessage:`logoutMessage`,
  userOrganizations:`user-organizations`,
};
export const COOKIE_KEYS={
  shipmentCustomColumns:`${USER_NAME}-shipment-list-custom-columns`,
  bookingCustomColumns:`${USER_NAME}-booking-list-custom-columns`,
  customCustomColumns:`${USER_NAME}-custom-list-custom-columns`,
  containerCustomColumns:`${USER_NAME}-container-list-custom-columns`,
  
  
}
export const LOCAL_CLIENT_CODE =
  getLocalValue(LOCAL_STORAGE_KEYS?.clientCode) || "";
export const token = getLocalValue(LOCAL_STORAGE_KEYS?.token);
export const CURRENT_PAGES_ARRAY = [
  LOCAL_STORAGE_KEYS?.dashboardUpcomingListCurrentPage,
  LOCAL_STORAGE_KEYS?.bookingListCurrentPage,
  LOCAL_STORAGE_KEYS?.customListCurrentPage,
  LOCAL_STORAGE_KEYS?.eventsListCurrentPage,
  LOCAL_STORAGE_KEYS?.shipmentListCurrentPage,
  LOCAL_STORAGE_KEYS?.trackingListCurrentPage,
];
export const ENTRY_STATUS_OPTIONS = [
  {
    value: "CLR",
    name: "Clear",
  },
  {
    value: "CAN",
    name: "Cancelled",
  },
  {
    value: "TAX",
    name: "Accepted",
  },
];

export const allowedDocumentsTypes = ["MBL", "HBL", "CIV", "PKL", "EPR", "INV"];

export const documentTypes=[
  { label: "Booking Confirmation", value: "BKC" },
  { label: "Pre Alert", value: "PAL" },
  { label: "Bill of Lading", value: "BOL" },
  { label: "Airway Bill/Ocean Bill of Lading", value: "MBL" },
  { label: "House Waybill/Bill of Lading", value: "HBL" },
  { label: "Original Bill of Lading", value: "OBL" },
  { label: "Commercial Invoice", value: "CIV" },
  { label: "Packing List", value: "PKL" },
  { label: "Insurance Certificate", value: "INS" },
  { label: "Phyto Certificate", value: "PHY" },
  { label: "Entry Print", value: "EPR" },
  { label: "Customs Status Advice", value: "CST" },
  { label: "Customs Authority", value: "CAU" },
  { label: "Invoice", value: "INV" }
];
export const originTypes = [{value:"GBLGP",label:"GBLGP"}, {value:"IDJKT",label:"IDJKT"}, {value:"GBGRG",label:"GBGRG"}, {value:"ILHFA",label:"ILHFA"}, {value:"GBTIL",label:"GBTIL"}, {value:"BRVCP",label:"BRVCP"}];
export const MAP_KEY="AIzaSyC851d2A4PEUIPFm_AHMbUvqjBOetLtaM8";

export const IS_CONTAINER_PAGE_VISIBLE=process.env.REACT_APP_CONTAINER_PAGE==="true"||process.env.REACT_APP_CONTAINER_PAGE===true;

export const IS_UNDER_MAINTENANCE=process.env.REACT_APP_UNDER_MAINTENANCE==="true"||process.env.REACT_APP_UNDER_MAINTENANCE===true;