import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Axios } from "../../pages/config";
import "jspdf-autotable";
import PopupPdfError from "../common/PopupPdfError";
// import PopupUploads from "../common/popup-Uploads";
import PopupUploads from "../common/popup-message";
import CircularProgress from "@mui/material/CircularProgress";
import { QUOTE_COLUMNS, token,LOCAL_CLIENT_CODE } from "../../common/constants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LOCAL_STORAGE_KEYS } from "../../common/constants";
import { getLocalValue,setLocalValue } from "../../common/utils";

const columns = [
  {
    label: "",
    field: "icon",
    sort: "asc",
    width: 55,
  },
  {
    label: "",
    field: "download_icon",
    sort: "disabled",
    width: 55,
  },
  {
    label: "Quote Reference",
    field: "QuoteReference",
    sort: "asc",
    width: 180,
    attributes: {
      "data-label": "Quote Reference",
    },
  },
  {
    label: "Transport",
    field: "transport",
    sort: "asc",
    width: 120,
  },
  {
    label: "Type",
    field: "QuoteType",
    sort: "asc",
    width: 120,
  },
  {
    label: "Origin",
    field: "Origin",
    sort: "asc",
    width: 120,
  },
  {
    label: "Destination",
    field: "Destination",
    sort: "asc",
    width: 160,
  },
  {
    label: "Issue Date",
    field: "IssueDate",
    sort: "asc",
    width: 140,
  },
  {
    label: "Issued By",
    field: "IssuedBy",
    sort: "asc",
    width: 120,
  },
  {
    label: "Valid From",
    field: "ValidFrom",
    sort: "asc",
    width: 140,
  },
  {
    label: "Valid to",
    field: "Validto",
    sort: "asc",
    width: 140,
  },
];
const QuotesList = () => {
  const [quotes, setQuotes] = useState();
  const [loadingcenter, setLoadingcenter] = useState(false);
  const [pinQuoteRef, setPinQuoteRef] = useState();
  const [quoteRef, setQuoteRef] = useState("");
  const [SubmitError, setSubmitError] = useState("");
  const [modalupload, setModalupload] = useState(false);
  const [loading, setloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filterPinnedShipments, setFilterPinnedShipments] = useState(0);
  const [isHoveredIcon, setIsHoveredIcon] = useState(null);
  const [dataApi, setDataApi] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [uniqueColumns, setUniqueColumns] = useState(columns);

  useEffect(() => {
    const reorderedColumns =
      JSON.parse(getLocalValue(LOCAL_STORAGE_KEYS?.quoteColumns)) || [];
    if (reorderedColumns?.length > 0) {
      setUniqueColumns(reorderedColumns);
    }
  }, []);

  const getQuotesList = async () => {
    let request = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/quotes`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    Axios
      .request(request)
      .then((response) => {
        setloading(false);
        setQuoteData(response);
        setDataApi(response);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  useEffect(() => {
    setQuoteData(dataApi);
  }, [isHoveredIcon]);

  const hoveredFunc = (id) => {
    if (!isHoveredIcon) {
      setIsHoveredIcon(null);
    }
    setIsHoveredIcon(id);
  };
  const setQuoteData = (data = dataApi) => {
    let temp_quotes = [];

    for (let i = 0; i < data.length; i++) {
      let quote_object = data[i];
      temp_quotes.push({
        icon: (
          <img
            id={`star-${quote_object.quote_ref}`}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(
                `${quote_object.quote_ref}`,
                data,
                quote_object?.pinned
              );
            }}
            onMouseEnter={() => hoveredFunc(quote_object?.id)}
            onMouseLeave={() => hoveredFunc(null)}
            src={`/assets/icons/${
              quote_object.pinned === 1 || quote_object.id === isHoveredIcon
                ? "star.svg"
                : "star-outline.svg"
            }`}
            alt="star 123"
          />
        ),
        download_icon:
          quote_object?.status == 1 ? (
            <img
              src="/assets/icons/download-primary.svg"
              id="download_icon"
              className="download_quotes"
              alt={quote_object?.quote_ref}
            />
          ) : (
            ""
          ),
        QuoteReference: quote_object?.quote_ref,
        transport: (
          <img
            id={`mode-${quote_object.quote_ref}`}
            src={`/assets/icons/${
              quote_object?.transport_mode === "AIR"
                ? "airplane.svg"
                : quote_object?.transport_mode === "SEA"
                ? "ship.svg"
                : quote_object?.transport_mode === "ROA"
                ? "road.svg"
                : ""
            }`}
            alt="Transport Mode"
            style={{ maxHeight: 20, maxWidth: 20 }}
          />
        ),
        QuoteType: quote_object?.transport_type,
        Origin: quote_object?.origin,
        Destination: quote_object?.destination,
        IssueDate: quote_object?.issue_date,
        IssuedBy: quote_object?.issued_by,
        ValidFrom: quote_object?.valid_from,
        Validto: quote_object?.valid_to,
      });
    }
    setQuotes(temp_quotes);
  };
  const pinnedShipments = (filter_pinned) => {
    if (filterPinnedShipments) {
      setFilterPinnedShipments(false);
      setQuoteData(dataApi);
    } else {
      setFilterPinnedShipments(true);
      const pinnedCustoms = [...dataApi]?.sort((a, b) => {
        if (a.pinned === b.pinned) {
          return 0;
        }
        return a.pinned === 1 ? -1 : 1;
      });
      setQuoteData(pinnedCustoms);
    }
  };
  useEffect(() => {
    getQuotesList();
  }, []);

  //   function ImageDownloader() {
  const fetchData = () => {
    const formData = new FormData();
    formData.append("quote_ref", quoteRef);
    setLoadingcenter(true);
    Axios
      .post(`/quotes/download`, formData, {
        responseType: "blob",
      })
      .then((response) => {
        setLoadingcenter(false);
        const blob = new Blob([response], { type: "application/pdf" });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "downloaded.pdf";
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        setModalupload("Quote has successfully been downloaded");
        setQuoteRef(null);
      })

      .catch((error) => {
        console.error("error", error);
        setSubmitError("Error while downloading PDF");
        setQuoteRef(null);
      })
      .finally(() => {
        // Set loading to false after the API call completes (both success and error cases)
        setLoadingcenter(false);
      });
  };

  const handleClick = (shipmentIdToUpdate, data = dataApi) => {
    setLoadingcenter(true);

    const url = `/pin-quote`;
    Axios
      .post(url, { quoteRef: shipmentIdToUpdate })
      .then((response) => {
        let row_index = data.findIndex(
          (item) => item.quote_ref === shipmentIdToUpdate
        );
        if (row_index !== -1) {
          data[row_index].pinned = data[row_index].pinned === 1 ? 0 : 1;
          setQuoteData(data);
          setIsHoveredIcon(false);
          setLoadingcenter(false);
        } else {
          console.console.error("quoteRef not found");
        }
      })
      .catch((error) => console.error("error", error));
  };
  useEffect(() => {
    if (!searchTerm?.length) {
      getQuotesList();
    }
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm?.trim() !== "") {
      Axios
        .get(
          `/search-quote?keyword=${encodeURIComponent(
            debouncedSearchTerm
          )}&local_client_code=${LOCAL_CLIENT_CODE}`,
        )
        .then((response) => {
          setloading(false);
          setQuoteData(response);
        })
        .catch((error) => {
          setloading(false);
          console.error("Error fetching data:", error);
        });
    } else {
      getQuotesList();
    }
  }, [debouncedSearchTerm]);
  const handleTdClick = (event) => {
    const cellValue = event?.target?.alt;
    const cellId = event?.target?.id;
    const cell = event.target;
    const columnIndex = Array.from(cell.parentNode.children).indexOf(cell);
    if (columnIndex === 0) {
      if (cellId === "download_icon") {
        setQuoteRef(cellValue);
      } else if (cellId === "pinned_star") {
        setPinQuoteRef(cellValue);
      }
    }
  };
  useEffect(() => {
    if (quoteRef) {
      fetchData();
    }
  }, [quoteRef]);

  const onCloseError = () => {
    setSubmitError("");
  };
  const onCloseupload = () => {
    setModalupload("");
  };

  const data = {
    columns: uniqueColumns.map((cell, index) => ({
      label: (
        <Draggable key={index} draggableId={`header-${index}`} index={index}>
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {cell.label}
            </div>
          )}
        </Draggable>
      ),
      field: cell?.field,
      sort: cell?.sort,
      width: cell?.width,
    })),
    rows: quotes,
  };
  const handlePageLimitChange = (event) => {
    const newPageLimit = parseInt(event.target.value, 10);
    setItemsPerPage(newPageLimit);
  };
  const newhandleChange = (event) => {
    setloading(true);
    setSearchTerm(event.target.value);
  };
  const myButtons = () => {
    return (
      <div className="relative table-custom-buttons w-full">
        <div className="table-wrapper absolute right-0  2xl:top-0  flex item-center justify-between w-full flex-col md:flex-row">
          <div className="pl-[26px] select-label hidden md:inline-block">
            <label>
              <select
                value={itemsPerPage}
                onChange={handlePageLimitChange}
                className="custom-select custom-select-sm form-control form-control-sm"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              Entries per page
            </label>
          </div>
        </div>
        <div className="input-group md-form form-sm form-2  w-full  custom-button-wrapper flex flex-col sm:flex-row px-[16px]">
          <button
            className={`pinned-shipmnets-btn${
              filterPinnedShipments ? " bg-warning pinned-button-active" : ""
            } flex items-center justify-between md:inline-block`}
            onClick={pinnedShipments}
            type="button"
          >
            Pinned Quotes
            <img
              className="md:hidden"
              src="/assets/icons/start-outline-mobile.svg"
              alt="star"
            />
          </button>
          <div className="table-wrapper">
            <div className="mdb-datatable-filter">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={newhandleChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (loadingcenter) {
    return (
      <div className="text-center loader">
        <CircularProgress />
      </div>
    );
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(uniqueColumns);
    const [reorderedItem] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, reorderedItem);

    const reOrder = JSON.stringify(reorderedColumns);
    setLocalValue(LOCAL_STORAGE_KEYS?.quoteColumns,reOrder)
    setUniqueColumns(reorderedColumns);
  };
  return (
    <>
      <div className="quotes-list view-all-wrapper">
        {myButtons()}
        {loading ? (
          <div className="text-center loader">
            <CircularProgress />
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="header" direction="horizontal">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <MDBDataTable
                    key={itemsPerPage}
                    data={data}
                    entries={itemsPerPage}
                    searchLabel="Search by quote reference"
                    className="table-wrapper"
                    noBottomColumns={true}
                    scrollX={true}
                    searching={false}
                    onClick={(event) => handleTdClick(event)}
                  />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <button className="view-all-btn flex items-center justify-center md:hidden gap-[18px] text-white pt-2 pb-[7px] ">
          <span>View All</span>{" "}
          <img
            className="rotate-90 w-[5px]"
            src="/assets/icons/right-white-arrow.svg"
            alt=""
          />
        </button>
      </div>
      <PopupUploads
        visible={modalupload}
        onClose={onCloseupload}
        heading={"SUCCESS"}
      />
      <PopupPdfError
        visible={SubmitError}
        onClose={onCloseError}
        heading={"PDF download Error"}
      />
    </>
  );
};

export default QuotesList;
