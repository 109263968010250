
import * as Yup from "yup";

export const SignUpSchema = Yup.object().shape({
    firstname:Yup.string().min(2).max(25).required ("please enter your firstname"),
    surname:Yup.string().min(2).max(25).required ("please enter your surname"),
    email:Yup.string().email().required("please enter your email"),
    companyName:Yup.string().required("please enter company name"),
    contactNumber: Yup.string()
    .required("Please enter your contact number"),
    // .matches(/^\d{10}$/, "Contact number should be exactly 10 digits"),
    // multiPort: Yup.string().required("A radio option is required"),
    transportMode: Yup.string().required('transportMode is required'),
    collectDate: Yup.string().required('collectDate is required'),
    deliveryDate: Yup.string().required('deliveryDate is required'),
    volume:Yup.string().required ("please enter volume"),
    weight:Yup.string().required ("please enter weight"),
    width:Yup.string().required ("please enter width"), 
    length:Yup.string().required ("please enter length"),
    height:Yup.string().required ("please enter height"),
    // dimensions:Yup.string().required ("enter dimensions"),
    packageType: Yup.string().required('packageType is required'),
    // incotermsSelect1: Yup.string().required('incotermsSelect is required'),
    customsclearance: Yup.string().required('customsclearance is required'),
    QTY: Yup.string().required('QTY is required'),
    Incoterms: Yup.string().required('Incoterms is required'),
    hazerdousGoods: Yup.string().required("A radio option is required"),
    gauge: Yup.string().required("A radio option is required"),

    
})

const LoginUpSchema = Yup.object({
    // login validations 
    username: Yup.string().min(2).max(50).required("username or email is required"),
    password: Yup.string().required('password is required').matches(/^.{8,}$/,'It must contain 8 letter'), 
    
})
const ForgetPasswordvalidation = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
})
export {LoginUpSchema};
export {ForgetPasswordvalidation};