import moment from "moment";
import { CURRENT_PAGES_ARRAY, LOCAL_STORAGE_KEYS } from "./constants";
import Cookies from "js-cookie";
import { date } from "yup";
export const areArrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = arr1
    .slice()
    .sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));
  const sortedArr2 = arr2
    .slice()
    .sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));
  for (let i = 0; i < sortedArr1.length; i++) {
    if (JSON.stringify(sortedArr1[i]) !== JSON.stringify(sortedArr2[i])) {
      return false;
    }
  }
  return true;
};

export const getShipmentColumns = () => {
  return JSON.parse(getLocalValue(LOCAL_STORAGE_KEYS?.shipmentColumns)) || [];
};

 const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function getMonthAbbreviation(month) {

  return months[parseInt(month, 10) - 1];
}

export function convertDateFormat(inputDate) {
  //2024-04-26
  if (typeof inputDate === "string"&&!inputDate.includes("1970")&&!inputDate.includes("[")) {
    if (inputDate.includes("\\")) {
      inputDate = inputDate.replace(/\\/g, "");

    }
    if (inputDate.split("-").length === 3&&months.includes(inputDate.split("-")[1])) {
   
      return inputDate.split("-")[0] + "-" + inputDate.split("-")[1] + "-20" + inputDate.split("-")[2];
    }
     if (isNaN(new Date(inputDate))) {
      const parts = inputDate.split("/");
      if(parts.length>0){
        const formattedDate =
        parts?.[0] + "-" + getMonthAbbreviation(parts[1]) + "-" + parts?.[2];
        return formattedDate;


      }
            return "NA";
    }

    if (inputDate.split(" ").length > 1) {
      const date = inputDate.split(" ")[0];
      const parsedDate = new Date(date);

      if (isNaN(parsedDate.getTime())) {
        return "NA";
      }

      // Get the day, month, and year components
      const day = parsedDate.getDate();
      const month = parsedDate.toLocaleString("default", { month: "short" });
      const year = parsedDate.getFullYear();

      // Create the formatted date string
      const formattedDate = day + "-" + month + "-" + year;

      return formattedDate;
    } else if (inputDate.split("/").length > 1) {
      const parts = inputDate.split("/");
      const formattedDate =
        parts[0] + "-" + getMonthAbbreviation(parts[1]) + "-" + parts[2];
      return formattedDate;
    } else if (inputDate.split("T").length > 1) {

     return moment(inputDate).format("DD-MMM-YYYY HH:mm")
      
    } else if (inputDate.split("-").length > 0) {
   
      const parts = inputDate.split("-");
      const formattedDate =
        parts[2] + "-" + getMonthAbbreviation(parts[1]) + "-" + parts[0];
      // if (isNaN(formattedDate)) {
      //   return "NA";
      // }
      return formattedDate;
    }
  } else {
    return "NA";
  }
}

export function splitExtensionAndCreateDocType(fileName) {
  // Split the file name into name and extension
  const [name, extension] = fileName.split(".");

  // Create a document type based on the extension
  let docType;
  switch (extension.toLowerCase()) {
    case "pdf":
      docType = "PDF Document";
      break;
    case "doc":
    case "docx":
      docType = "Word Document";
      break;
    case "txt":
      docType = "Text Document";
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "tiff":
    case "svg":
      docType = "Image File";
      break;
    default:
      docType = "Unknown Document Type";
  }

  // Return the results
  return {
    name: name,
    extension: extension,
    docType: docType,
  };
}

export const setLocalValue = (key, value) => {
  localStorage.setItem(key, value);
};
export const removeLocalValue = (key) => {
  localStorage.removeItem(key);
};

export const getLocalValue = (key) => {
  return localStorage.getItem(key);
};

export const logoutReset = () => {
  localStorage.clear();
};
export const setCookie = (key, data, expires = 90) => {
  Cookies?.set(key, JSON?.stringify(data), { expires: expires });
};
export const getCookie = (key) => {
  const data = Cookies.get(key);
  if (data) {
    return JSON.parse(data) || "";
  }
  return "";
};

export const resetPageNumber = () => {
  CURRENT_PAGES_ARRAY?.map((key) => {
    setLocalValue(key, 1);
    return null;
  });
};

export const autoLogOut = () => {
  logoutReset();
  setLocalValue(
    LOCAL_STORAGE_KEYS?.logoutMessage,
    "You have been automatically logged out"
  );
  window.location.reload();
};

export const processContainer = (containerString) => {
  let result;

  try {
    const containerArray = JSON.parse(containerString);
    if (Array.isArray(containerArray)) {
      result = containerArray.join(", ");
    } else {
      result = containerString;
    }
  } catch (error) {
    result = containerString;
  }

  return result;
};

export function sortByKey(array, key, order = 'asc') {
  if (order === 'asc') {
      return array.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
  } else if (order === 'desc') {
      return array.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0));
  }
}


export const parseJson=(item)=>{
  try {
    const parsedItem=JSON.parse(item);
    return parsedItem;
  } catch (error) {
    return ""
    
  }
}