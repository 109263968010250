import React, { useRef, useState } from "react";
import "../../../src/scss/popup.scss";
import { documentTypes } from "../../common/constants";
import Select from "react-dropdown-select";
// import config from '../../pages/config';
// import { useEffect } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";

const validationSchema = Yup.object().shape({
  documentType: Yup.string().required("Document type is required"),
  file: Yup.mixed()
    .required("File is required")
    .test(
      "fileType",
      "Unsupported file type. Please upload a .pdf, .csv, .doc, or .docx file.",
      (value) => {
        if (!value) return true; // Allow empty file field
        const supportedTypes = [
          "application/pdf",
          "text/csv",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        return supportedTypes.includes(value.type);
      }
    ),
});

export default function PopupUploads({
  visible,
  onClose,
  handleUpload,
  uploadMessage,
  isAll,
  isSingle,
  isUploading,
}) {
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [fileName, setFileName] = useState("");
  const initialValues = { documentType: "", file: null };
  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleReset,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        handleUpload(values.file, clearFileInput, values.documentType);
      } catch (error) {
        console.error("error", error);
      }
    },
  });

  // if (!visible) return null;
  const handleClose = (e) => {
    if (e.target.id === "Upload-modal-wrapper") {
      // onClose();
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave" || e.type === "drop") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleFileChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const handleFile = function (files) {
    const file = files[0];
    const reader = new FileReader();
    setFieldValue("file", file);
    setFieldTouched("file", true);
    reader.onload = function (e) {
      const fileType = file.type;
      if (fileType.startsWith("image/")) {
        setFilePreview(e.target.result);
      } else if (fileType === "application/pdf") {
        setFilePreview("pdf");
        setFileName(file.name);
        setFieldError("file", "");
      } else if (
        fileType === "application/msword" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setFilePreview("doc");
        setFileName(file.name);
        setFieldError("file", "");
      } else if (fileType === "text/csv") {
        setFilePreview("csv");
        setFileName(file.name);
        setFieldError("file", "");
      } else {
        setFilePreview(null); // Clear preview for unsupported file types
        setFileName("");
      }
    };

    reader.readAsDataURL(file);
  };
  const clearFileInput = () => {
    handleReset();
    if (inputRef.current) {
      inputRef.current.value = "";
      setFilePreview(null);
      setFileName("");
    }
    if (selectRef.current) {
      selectRef.current.clearAll(); // Use ref to clear Select component
    }
    setFieldValue("documentType", "");
  };

  return (
    <div
      id="Upload-modal-wrapper"
      onClick={handleClose}
      className={`modal-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center transition-all duration-300 ease-in-out ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div
        className={`modal w-[629px] bg-white rounded-[15px] pt-[26px] pr-[16px] pb-[18px] pl-[19px] relative  transition-all duration-300 ease-in-out ${
          visible ? "visible" : "invisible"
        }`}
      >
        {/* {/ <Link to={"/quotes/newquotes/delveryDetails=true"}><img src="/assets/icons/close.svg" alt="close" className='absolute top-4 right-[18px] cursor-pointer' /></Link> /} */}

        <div
          className={`flex items-center  ${
            uploadMessage ? "justify-between" : "justify-end"
          } h-full mb-3 w-full`}
        >
          {uploadMessage && (
            <button
              onClick={() => onClose(clearFileInput, true)}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Add Another Doc
            </button>
          )}

          <img
            src="/assets/icons/close.svg"
            alt="close"
            className={` ${isAll || isSingle ? "" : "cursor-pointer"} `}
            onClick={isAll || isSingle ? null : () => onClose(clearFileInput)}
          />
        </div>
        <div className="modal-header pb-[23px]">
          <h4 className="heading">Upload a file</h4>
          <div className="description">
            Use this function to upload a file to the ICL cloud.{" "}
            <span>(.pdf .csv .word supported)</span>
          </div>
        </div>
        <div className="modal-body pt-2 ">
          <div className={`${uploadMessage ? "hidden" : "block"}`}>
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
              onSubmit={handleSubmit}
            >
              <div className="h-[300px] drag-drop-section mt-[13px]">
                <Select
                  ref={selectRef}
                  name="documentType"
                  value={values.documentType}
                  options={documentTypes}
                  placeholder="Select Document Type"
                  onChange={(value) =>
                    setFieldValue("documentType", value?.[0]?.value || "")
                  }
                  searchBy="label"
                  searchable
                  multi={false}
                  onBlur={handleBlur}
                />
                {
                  <p className="form-error">
                    {touched.documentType && errors.documentType}
                  </p>
                }

                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={false}
                  onChange={handleFileChange}
                  accept=".pdf,.csv,.doc,.docx"
                  name="file"
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                  onDragEnter={handleDrag}
                  onDragOver={handleDrag}
                  onDragLeave={handleDrag}
                  onDrop={handleDrop}
                >
                  <div className="flex items-center justify-center flex-col gap-2 h-full">
                    {filePreview === "pdf" && (
                      <>
                        <img
                          className="custom_pdf"
                          src="/assets/image/pdf1.png"
                          alt="PDF Preview"
                        />
                        <p>{fileName}</p>
                      </>
                    )}
                    {filePreview === "doc" && (
                      <>
                        <img
                          src="/assets/image/word.png"
                          alt="DOC Preview"
                          className="h-[100px] w-[100px]"
                        />
                        <p>{fileName}</p>
                      </>
                    )}
                    {filePreview === "csv" && (
                      <>
                        <img
                          src="/assets/image/csv2.png"
                          alt="CSV Preview"
                          className="h-[100px] w-[100px]"
                        />
                        <p>{fileName}</p>
                      </>
                    )}
                    {filePreview &&
                      filePreview !== "pdf" &&
                      filePreview !== "doc" &&
                      filePreview !== "csv" && (
                        <img
                          className="h-full"
                          src={filePreview}
                          alt="File Preview"
                        />
                      )}
                    {!filePreview && (
                      <>
                        <img src="/assets/icons/uploads-popup.svg" alt="" />
                        <p className="">
                          Drag and drop file or click to upload.
                        </p>
                      </>
                    )}
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </div>
              {<p className="form-error">{touched.file && errors.file}</p>}
              <button
                className="btn-modal pt-[14px] pb-[18px] w-full mt-[19px] flex items-end justify-center"
                type="submit"
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Upload"}
              </button>
            </form>
          </div>
          <div
            className={`min-h-[200px] popup-message flex items-center justify-center ${
              uploadMessage ? "block" : "hidden"
            }`}
          >
            <p className={`text-message`}>{uploadMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
