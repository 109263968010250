import React, { useState, useEffect,useMemo } from "react";
import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";
import PopupTable from "../../components/common/popup-Table";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { fieldToApiMap } from "../../data/data";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  LOCAL_CLIENT_CODE,
  LOCAL_STORAGE_KEYS,
  COOKIE_KEYS
} from "../../common/constants";
import {
  areArrayEqual,
  convertDateFormat,
  getCookie,
  getLocalValue,
  processContainer,
  setCookie,
  setLocalValue,
} from "../../common/utils";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Axios } from "../../pages/config";
import DateRangeModal from "../common/DateRangeModal";
import { debounce } from 'lodash';

const options = [
  {
    value: "SELECT",
    label: "All Events",
  },

  {
    value: "AIR",
    label: "By Air",
  },
  {
    value: "SEA",
    label: "By Sea",
  },
  {
    value: "ROA",
    label: "By Road",
  },
];

const ShipmentsList = ({ keyword }) => {
  const [modalexport, setModalexport] = useState(false);
  const [dataApi, setDataApi] = useState([]);
  const [loading, setloading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [loadingcenter, setLoadingcenter] = useState(false);
  const [columnsArray, setColumnsArray] = useState([]);
  const [uniqueColumn, setUniqueColumns] = useState([]);
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [customizedColumnsKeysArray, setCustomizedColumnsKeysArray] = useState(
    []
  );
  const [rowsArray, setRowsArray] = useState([]);
  const [filterPinnedShipments, setFilterPinnedShipments] = useState(0);
  const [tempColumnsArray, setTempColumnsArray] = useState([]);
  const [tempRowsArray, setTempRowsArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1); // Add this state variable
  const [pageInfo, setPageInfo] = useState("");
  const [pageLength, setPageLength] = useState("");
  const [currentPageLength, setCurrentPageLength] = useState("");
  const [enable, setEnable] = useState(false);
  const [lastPage, setLastPage] = useState("");
  const [checkboxData, setCheckboxData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isHoveredIcon, setIsHoveredIcon] = useState(null);
  const [type, setTypeState] = useState(false);
  const [eventValue, setEventValue] = useState(null);
  const [orderedColumns, setOrderedColumns] = useState([]);
  const [firstRun, setFirstRun] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");
  const [dateRangeModal, setDateRangeModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [forcedFavIconIds, setForcedFavIconIds] = useState([])

  // const entriesPerPage = 10;
  // first APi render on page load
  // const fetchDataFromAPI = async (page)
  //  => {
  //   try {
  //     const response = await axios.get(
  //       `${config.baseURL}/shipments?page=${page}&per_page=${entriesPerPage}`,
  //       {
  //         headers: config.headers,
  //       }
  //     );
  //     return response.data.data;
  //   } catch (error) {
  //     console.error('Error:', error);
  //     return [];
  //   }
  // };

  useEffect(() => {
    const reorderedColumns =
      JSON.parse(getLocalValue(LOCAL_STORAGE_KEYS?.shipmentColumns)) || [];
    setOrderedColumns(reorderedColumns);
  }, []);
  useEffect(() => {
    const unique = columnsArray?.filter((item, index) => {
      // Find the index of the first occurrence of the current item's field
      const firstIndex = columnsArray?.findIndex(
        (obj) => obj?.field === item?.field
      );

      // Return true only if the current index is the first occurrence of the field
      return index === firstIndex;
    });

    setUniqueColumns(unique);
  }, [columnsArray]);
  useEffect(() => {
    if (
      areArrayEqual(uniqueColumn, orderedColumns) &&
      orderedColumns?.length > 0 &&
      firstRun
    ) {
      setUniqueColumns(orderedColumns);
      setFirstRun(false);
    }
  }, [uniqueColumn]);

  useEffect(() => {
    setTableRows(dataApi);
  }, [isHoveredIcon]);
  const navigate = useNavigate();
  const navigateToDetailPage = (shipmentID) => {
    navigate(`/shipments/${shipmentID}`);
  };
  const hoveredFunc = (id) => {
    setIsHoveredIcon(id);
  };
  const setTableRows = (shipments = dataApi) => {
    const rows = shipments.map((shipment) => ({
      icon: (
        <>
          <img
            id={`star-${shipment.shipment_id}`}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(`${shipment.shipment_id}`, shipments);
            }}
            // src={`/assets/icons/${
            //   shipment.pinned === 1 ? "star.svg" : "star-outline.svg"
            // }`}
            // alt="star 123"
            onMouseEnter={() => hoveredFunc(shipment.shipment_id)}
            onMouseLeave={() => hoveredFunc(null)}
            src={`/assets/icons/${
              shipment.pinned === 1 || shipment.shipment_id === isHoveredIcon
                ? "star.svg"
                : "star-outline.svg"
            }`}
            alt="star 123"
          />
        </>
      ),
      mode: (
        <img
          id={`mode-${shipment.shipment_id}`}
          src={`/assets/icons/${
            shipment.mode === "AIR"
              ? "airplane.svg"
              : shipment.mode === "SEA"
              ? "ship.svg"
              : shipment.mode === "ROA"
              ? "road.svg"
              : shipment.mode === "COU"
              ? "road.svg"
              : ""
          }`}
          alt="star 123"
          style={{ maxHeight: 20, maxWidth: 20 }}
        />
      ),
      shipmentID: shipment.shipment_id ?? "NA",
      shipper: shipment.consignor_name ?? "NA",
      originPort: shipment.origin ?? "NA",
      consignee: shipment.consignee_name ?? "NA",
      consignor: shipment.consignor_name ?? "NA",
      destinationPort: shipment.destination ?? "NA",
      etd: shipment.origin_etd ? convertDateFormat(shipment.origin_etd) : "NA",
      Weight: shipment?.weight ?? "NA",
      Volume: shipment?.volume ?? "NA",
      MainVessel: shipment?.vessel ?? "NA",
      CurrentVoy: shipment.flight_voyage ?? "NA",
      container_count: shipment.container_count ?? "NA",
      MainVoy: "",
      EstimatedPickup: shipment.etd_load ?? "NA",
      ActualPickup: shipment.actual_pickup ?? "NA",
      JobNotes: shipment.job_status ?? "NA",
      AdditionalTerms: shipment.additional_terms ?? "NA",
      EstimatedDelivery: "",
      LastMilestone: "",
      NextMilestoneDesc: "",
      Quantity: shipment?.quantity ?? "NA",
      goods_description: shipment.goods_description ?? "NA",
      // destination: shipment.destination ?? "NA",
      OrderRef: shipment?.shippers_reference ?? "NA",
      Bill: shipment.house_ref ?? "NA",
      CurrentVessel: shipment.vessel ?? "NA",
      eta: shipment?.destination_eta
        ? convertDateFormat(shipment?.destination_eta)
        : "NA",
      ContainerMode: shipment.container_mode,
      ContainerNumbers: "",
      Actual_Delivery: shipment?.actual_delivery_date
        ? convertDateFormat(shipment?.actual_delivery_date)
        : "NA",
      Estimated_Date: shipment?.estimated_delivery_date
        ? convertDateFormat(shipment?.estimated_delivery_date)
        : "NA",
      Containers:processContainer(shipment?.container||""),
      Carrier: shipment?.carrier_name ?? "NA",
      TEU: shipment?.teu ?? "NA",
      clickEvent: () => navigateToDetailPage(shipment.shipment_id),
    }));

    setRowsArray(rows);
  };

  const pinnedShipments = (filter_pinned) => {
    if (filterPinnedShipments) {
      setFilterPinnedShipments(false);
      setTableRows(dataApi);
    } else {
      setFilterPinnedShipments(true);
      const pinnedShipments = [...dataApi]?.sort((a, b) => {
        if (a.pinned === b.pinned) {
          return 0;
        }
        return a.pinned === 1 ? -1 : 1;
      });
      setTableRows(pinnedShipments);
    }
  };

  const handleClick = (shipmentIdToUpdate, data = dataApi) => {
    setLoadingcenter(true);

    const url = `/pin-shipment`;
    Axios.post(url, { shipmentId: shipmentIdToUpdate })
      .then(() => {
        let temp_rows = data;
        let row_index = temp_rows.findIndex(
          (item) => item.shipment_id === shipmentIdToUpdate
        );

        if (row_index !== -1) {
          temp_rows[row_index].pinned =
            temp_rows[row_index].pinned === 1 ? 0 : 1;
          setTableRows(temp_rows);
          setIsHoveredIcon(false);
          setLoadingcenter(false);
        } else {
          console.error("Shipment not found");
        }
      })
      .catch((error) => console.error("error", error));
  };

  // const fetchAllData = async () => {
  //   setLoading(true);
  //   try {
  //     const allData = [];
  //     let currentPage = 1;
  //     let response;
  //     do {
  //       response = await fetchDataFromAPI(currentPage);
  //       allData.push(...response);
  //       currentPage++;
  //     } while (response.length > 0 && currentPage <= 3);
  //     setDataApi(allData);
  //     setLoading(false);
  //     setTableRows(allData);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };
  const defaultColumnsArray = [
    {
      label: "",
      field: "icon",
      sort: "asc",
      width: 50,
    },
    {
      label: "Mode",
      field: "mode",
      sort: "asc",
      width: 120,
    },
    {
      label: "Container Mode",
      field: "ContainerMode",
      sort: "asc",
      width: 220,
    },
    {
      label: "Shipment ID",
      field: "shipmentID",
      sort: "asc",
      width: 220,
    },
    {
      label: "Order Ref",
      field: "OrderRef",
      sort: "asc",
      width: 220,
    },
    {
      label: "Shipper",
      field: "shipper",
      sort: "asc",
      width: 360,
    },
    {
      label: "Origin Port",
      field: "originPort",
      sort: "asc",
      width: 160,
    },
    {
      label: "ETD",
      field: "etd",
      sort: "asc",
      width: 220,
    },
    {
      label: "Consignor",
      field: "consignor",
      sort: "asc",
      width: 360,
    },
    {
      label: "Consignee",
      field: "consignee",
      sort: "asc",
      width: 360,
    },

    {
      label: "Destination Port",
      field: "destinationPort",
      sort: "asc",
      width: 220,
    },
    {
      label: "ETA",
      field: "eta",
      sort: "asc",
      width: 220,
    },
    {
      label: "Vessel",
      field: "CurrentVessel",
      sort: "asc",
      width: 220,
    },

    // {
    //   label: 'Container Count',
    //   field: 'container_count',
    //   sort: 'asc',
    //   width: 220,
    // },
    // {
    //   label: 'Container Numbers',
    //   field: 'ContainerNumbers',
    //   sort: 'asc',
    //   width: 220,
    // },
    {
      label: "Bill",
      field: "Bill",
      sort: "asc",
      width: 280,
    },
    {
      label: "Goods Description",
      field: "goods_description",
      sort: "asc",
      width: 280,
    },
    // {
    //   label: 'Delivery Destination',
    //   field: 'destination',
    //   sort: 'asc',
    //   width: 220,
    // },
    {
      label: "Estimated Delivery Date",
      field: "Estimated_Date",
      sort: "asc",
      width: 220,
    },
    {
      label: "Actual Delivery Date",
      field: "Actual_Delivery",
      sort: "asc",
      width: 220,
    },
  ];

  // useEffect(() => {
  //   setDefaultColumns(defaultColumnsArray);
  //   setColumnsArray(defaultColumnsArray);
  //   // fetchAllData();
  // }, []);

  useEffect(() => {
    const savedPage = getLocalValue(
      LOCAL_STORAGE_KEYS?.shipmentListCurrentPage
    );
    const records = getLocalValue(LOCAL_STORAGE_KEYS?.shipmentListRecords);
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10));
    } else {
      setCurrentPage(1);
      setLocalValue(LOCAL_STORAGE_KEYS?.shipmentListCurrentPage, 1);
    }
    if (records) {
      setItemsPerPage(records);
    } else {
      setItemsPerPage(10);
      setLocalValue(LOCAL_STORAGE_KEYS?.shipmentListRecords, 10);
    }
  }, []);

  const handlePageChange = (page) => {
    if (page >= 1) {
      const newStartPage = Math.floor((page - 1) / 3) * 3 + 1;
      setLocalValue(LOCAL_STORAGE_KEYS?.shipmentListCurrentPage, page);
      setLocalValue(LOCAL_STORAGE_KEYS?.shipmentListRecords, itemsPerPage);
      setCurrentPage(page);
      setEnable(true);
      setStartPage(newStartPage);
    }
  };

  const handlePageLimitChange = (event) => {
    const newPageLimit = parseInt(event.target.value, 10);
    setItemsPerPage(newPageLimit);
    setEnable(true);
    handlePageChange(1);
    setLocalValue(LOCAL_STORAGE_KEYS?.shipmentListRecords, newPageLimit);
    setLocalValue(LOCAL_STORAGE_KEYS?.shipmentListCurrentPage, 1);
  };
  const fetchDataFromAPI = async () => {
    setloading(true);
    if (currentPage !== 0) {
      if (keyword !== "") {
        let url = `/search-shipment?keyword=${keyword}&local_client_code=${LOCAL_CLIENT_CODE}&per_page=${itemsPerPage}&page=${currentPage}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        if (startDate && endDate) {
          url = `${url}&dateRange=${startDate},${endDate}`;
        }
        Axios.get(url)
          .then((response) => {
            const totalRecords = response?.total || 0;
            const fromPage =
              response?.current_page * itemsPerPage - (itemsPerPage - 1);
            setTableRows(response?.data);
            setDataApi(response?.data);
            setLastPage(response?.last_page);
            setTotalPages(Math.ceil(totalRecords / itemsPerPage));
            setPageInfo(response?.total);
            setPageLength(response?.to);
            setCurrentPageLength(fromPage);;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(()=>{
            setloading(false);
          });

      } else if (type && eventValue !== "SELECT") {
        let url = `/shipments-by-event?eventType=${eventValue}&per_page=${itemsPerPage}&page=${currentPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        if (startDate && endDate) {
          url = `${url}&dateRange=${startDate},${endDate}`;
        }
        setloading(true);
        Axios.get(url)
          .then((response) => {
            setloading(false);
            setTableRows(response?.data);
            setDataApi(response?.data);
            setLastPage(response?.last_page);
            const totalRecords = response?.total || 0;
            setTotalPages(Math.ceil(totalRecords / itemsPerPage));
            setPageInfo(response?.total);
            setPageLength(response?.to);
            const fromPage =
              response?.current_page * itemsPerPage - (itemsPerPage - 1);
            setCurrentPageLength(fromPage);
          })
          .catch((error) => {
            setloading(false);
          });
      } else {
        let url = `/shipments?page=${currentPage}&per_page=${itemsPerPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        if (startDate && endDate) {
          url = `${url}&dateRange=${startDate},${endDate}`;
        }
        setloading(true);
        Axios.get(url)
          .then((response) => {
            setloading(false);
            setTableRows(response?.data);
            setDataApi(response?.data);
            setLastPage(response?.last_page);
            const totalRecords = response?.total || 0;
            setTotalPages(Math.ceil(totalRecords / itemsPerPage));
            setPageInfo(response?.total);
            setPageLength(response?.to);
            const fromPage =
              response?.current_page * itemsPerPage - (itemsPerPage - 1);
            setCurrentPageLength(fromPage);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    }
  };


  useEffect(() => {
    if (searchTerm?.trim() !== "") {
      let url = `/search-shipment?keyword=${searchTerm}&local_client_code=${LOCAL_CLIENT_CODE}`;
      if (order && orderBy) {
        url = `${url}&sort_by=${orderBy}&direction=${order}`;
      }
      if (startDate && endDate) {
        url = `${url}&dateRange=${startDate},${endDate}`;
      }
      setloading(true);
      Axios.get(url)
        .then((response) => {
          setloading(false);
          setTableRows(response);
          setDataApi(response);
          setLastPage(response?.last_page);
          const totalRecords = response?.total || 0;
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response?.total);
          setPageLength(response?.to);
          setCurrentPageLength(response?.current_page);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setloading(false);
        }).finally(()=>{
          setloading(false);
        });
    } else {
      fetchDataFromAPI();
    }
  }, [searchTerm]);

  useEffect(() => {
    const savedCheckboxData = getCookie(COOKIE_KEYS?.shipmentCustomColumns);
    // if (savedCheckboxData) {
    const checkboxData = savedCheckboxData|| [];
    const formattedArray = checkboxData?.map((item) => ({
      label: item.label,
      field: item.id,
      sort: "asc",
      width: 220,
    }));
    const defaultColumnsUpdated = [...defaultColumnsArray, ...formattedArray];
    // Fetch data from your API when the currentPage changes
    if (enable && currentPage && itemsPerPage) {
      fetchDataFromAPI();
    } else {
      fetchDataFromAPI();

      setDefaultColumns(defaultColumnsUpdated);
      setColumnsArray(defaultColumnsUpdated);
    }
    setEnable(true);
  }, [currentPage, enable, itemsPerPage, keyword, orderBy, order,startDate,endDate,startDate,endDate]);

  useEffect(() => {
    const savedCheckboxData = getCookie(COOKIE_KEYS?.shipmentCustomColumns);
    if (savedCheckboxData) {
      setCheckboxData(savedCheckboxData);
    } else {
      setColumnsArray(defaultColumnsArray);
    }
  }, []);

  const newhandleChange = (event) => {
    setSearchTerm(event?.target?.value);
  };
  const debouncedResults = useMemo(() => {
    return debounce(newhandleChange, 1000);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  if (loadingcenter) {
    return (
      <div className="text-center loader">
        <CircularProgress />
      </div>
    );
  }
  //events api
  async function handleChange(values) {
    setCurrentPage(1);
    setTypeState(true);
    let val = values[0].value;
    setEventValue(val);
    setLocalValue(LOCAL_STORAGE_KEYS?.shipmentListCurrentPage, 1);
    if (val !== "SELECT") {
      try {
        setloading(true);
        let url = `/shipments-by-event?eventType=${val}&per_page=${itemsPerPage}&page=${currentPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        if (startDate && endDate) {
          url = `${url}&dateRange=${startDate},${endDate}`;
        }
        const response = await Axios.get(url);
        setTableRows(response?.data);
        setDataApi(response?.data);
        setLastPage(response?.last_page);
        const totalRecords = response?.total || 0;
        setTotalPages(Math.ceil(totalRecords / itemsPerPage));
        setPageInfo(response?.total);
        setPageLength(response?.to);
        setCurrentPageLength(response?.current_page);
      } catch (error) {
        console.error("Error:", error);
      }
      finally{
        setloading(false);
      }
    } else {
      let url = `/shipments?page=${currentPage}&per_page=${itemsPerPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
      if (order && orderBy) {
        url = `${url}&sort_by=${orderBy}&direction=${order}`;
      }
      if (startDate && endDate) {
        url = `${url}&dateRange=${startDate},${endDate}`;
      }
      setloading(true);
      Axios.get(url)
        .then((response) => {
          setTableRows(response?.data);
          setDataApi(response?.data);
          setLastPage(response?.last_page);
          const totalRecords = response?.total || 0;
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response?.total);
          setPageLength(response?.to);
          setCurrentPageLength(response?.current_page);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        }).finally(()=>{
          setloading(false);
        });
    }
  }

  const customizeColumn = (columnId, isChecked, myColumn) => {
    let temp_columns_array = [...customizedColumnsKeysArray];
    if (isChecked) {
      temp_columns_array.push({
        columnId: columnId,
        myColumn: myColumn,
      });
    } else {
      const index = temp_columns_array.findIndex(
        (item) => item.columnId === columnId
      );
      if (index > -1) {
        temp_columns_array.splice(index, 1);
      }
    }
    setCustomizedColumnsKeysArray(temp_columns_array);
    let temp_columns = defaultColumns.filter(
      (column) => !temp_columns_array.includes(column.field)
    );

    for (let i = 0; i < temp_columns_array.length; i++) {
      temp_columns.push({
        label: temp_columns_array[i].myColumn,
        field: temp_columns_array[i].columnId,
        sort: "asc",
        width: 245,
        random: (
          <h5 searchvalue={"tourist"}>
            {" "}
            <span className="badge badge-danger"></span>
          </h5>
        ),
      });
    }
    setTempColumnsArray(temp_columns);
    let temp_rows_data = dataApi.map((item) => {
      const jsonObject = {};

      temp_columns.forEach((key) => {
        if (key.field === "icon") {
          jsonObject[key.field] = (
            <img
              id={`star-${item.shipment_id}`}
              onClick={() => handleClick(`${item.shipment_id}`)}
              src={`/assets/icons/${
                item.pinned === 1 ? "star.svg" : "star-outline.svg"
              }`}
              alt={`${item.shipment_id}`}
            />
          );
        } else {
          jsonObject[key.field] = (
            <Link to={`/shipments/${item.shipment_id}`}>
              {item[fieldToApiMap[key.field]]}
            </Link>
          );
        }
      });

      return jsonObject;
    });

    // Now temp_rows_data contains the optimized data.

    setTempRowsArray(temp_rows_data);
  };

  const data = {
    columns: uniqueColumn.map((cell, index) => ({
      label: (
        <Draggable key={index} draggableId={`header-${index}`} index={index}>
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {cell.label}
            </div>
          )}
        </Draggable>
      ),
      field: cell?.field,
      sort: cell?.sort,
      width: cell?.width,
    })),
    rows: rowsArray,
    random: (
      <h5 searchvalue={"tourist"}>
        {" "}
        <span className="badge badge-danger"></span>
      </h5>
    ),
  };

  const onCloseexport = () => {
    setModalexport(false);
    pinnedShipments();
  };

  const handleOpenexport = () => {
    setModalexport(true);
    pinnedShipments();
  };


  const customButtons = () => {
    return (
      <div className="w-full custom-container">
        <div className="select-label  md:inline-block mb-2">
          <label>
            <select
              value={itemsPerPage}
              onChange={handlePageLimitChange}
              className="custom-select custom-select-sm form-control form-control-sm "
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            &nbsp; Entries per page
          </label>
        </div>
        <div className="search-container mb-2">
          <button
            className={`pinned-shipmnets-btn${
              filterPinnedShipments ? " bg-warning pinned-button-active" : ""
            } flex items-center justify-between md:inline-block mr-2 mb-2`}
            onClick={pinnedShipments}
            type="button"
          >
            Pinned Shipments{" "}
            <img
              className="md:hidden"
              src="/assets/icons/start-outline-mobile.svg"
              alt="star"
            />
          </button>
          <button
            className="customise-columns-btn  md:inline-block mr-2 mb-2"
            type="button"
            onClick={handleOpenexport}
          >
            Customise Columns
          </button>
          <button
            className="customise-columns-btn  md:inline-block mr-2 mb-2"
            type="button"
            onClick={toggleDateModal}
          >
            Date Range
          </button>
          {/* <button
            className="customise-columns-btn  md:inline-block mr-2 mb-2"
            type="button"
            onClick={() => exportToExcel()}
            disabled={isExporting}
          >
            {isExporting ? "Exporting..." : "Export Data to XLSX"}
          </button> */}
          <div className="event-types-btn md:inline-block sm:block mb-2">
            <Select
              className="dropdown-btn btn-dropdown"
              searchable={false}
              placeholder={"Event Types"}
              options={options}
              onChange={handleChange}
            />
          </div>
          <div className="md:inline-block sm:block ml-2 ">
            <div className="mdb-datatable-filter">
              <input
                type="text"
                placeholder="Search..."
                onChange={debouncedResults}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const saveCustomizedColumn = (data) => {
    const formattedArray = data?.map((item) => ({
      label: item.label,
      field: item.id,
      sort: "asc",
      width: 220,
    }));
    const defaultColumnsUpdated = [...defaultColumnsArray, ...formattedArray];
    setColumnsArray(defaultColumnsUpdated);
    setRowsArray(tempRowsArray);

    // if (data && data.columns.length > 0 && data.rows.length > 0) {
    setCookie(COOKIE_KEYS?.shipmentCustomColumns,data)
    // }

    onCloseexport();
  };

  //   setCookiedata(data);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(uniqueColumn);
    const [reorderedItem] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, reorderedItem);

    const reOrder = JSON.stringify(reorderedColumns);
    setLocalValue(LOCAL_STORAGE_KEYS?.shipmentColumns, reOrder);
    setUniqueColumns(reorderedColumns);
  };

  const getPageNumbers = () => {
    const pagesToShow = 3;
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };
  const exportToExcel = async () => {
    try {
      setExporting(true);
      let url= `/shipments/export-listings?local_client_code=${LOCAL_CLIENT_CODE}`;
      if(startDate&&endDate){
        url = `${url}&dateRange=${startDate},${endDate}`;
      }
      if(keyword){
        url=`${url}&eventType=${keyword}`
      }
      const response = await Axios.get(
       url,
        {
          responseType: "arraybuffer",
        }
      );
      const buffer = new Uint8Array(response);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      const blob = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        "Shipment-list.xlsx"
      );
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    } finally {
      setExporting(false);
    }
  };
  const toggleDateModal = () => {
    setDateRangeModal(!dateRangeModal);
  };
  return (
    <>
      <div className="shipments-list view-all-wrapper">
        {customButtons()}
        {loading ? (
          <div className="text-center loader">
            <CircularProgress />
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="header" direction="horizontal">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <MDBDataTable
                    data={data}
                    entries={100}
                    searchLabel="Search by Shipment ref, order ref, container number or bill of lading"
                    // searchLabel={searchLabel}
                    className="table-wrapper"
                    noBottomColumns={true}
                    scrollX={true}
                    searching={false}
                    // paging
                    // disableRetreatAfterSorting={true}
                    sortable={true}
                    onSort={({ column, direction }) => {
                      if (column === "etd" || column === "eta") {
                        setOrderBy(column);
                        setOrder(direction.toUpperCase());
                      }
                    }}
                  />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <section
          className={(totalPages == 1 && currentPage == 0)||loading ? "hidden" : ""}
        >
          <div
            className={
              "table-wrapper absolute right-0 pt-[13px] md:pt-[17px] px-0 pb-4 md:pb-[21px] bottom-0 w-full"
            }
          >
            <div className="grid grid-cols-2 gap-[14px] md:gap-0">
              <div className="page-info pl-[14px] md:pl-[26px] col-span-2 md:col-span-1">
                Showing {currentPageLength} to {pageLength} of {pageInfo}{" "}
                entries
              </div>
              <nav
                aria-label="Page navigation flex justify-between items-center "
                className="col-span-2 md:col-span-1"
              >
                <ul className="pagination justify-content-center pr-[25px]">
                  <li
                    className={`page-item page-next-pre ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <button className="page-link px-1">
                      <img
                        className="rotate-180 normal-image"
                        src="/assets/icons/right-arrow.svg"
                        alt=""
                      />
                      <img
                        className="rotate-180 image-hover"
                        src="/assets/icons/right-white-arrow.svg"
                        alt=""
                      />
                    </button>
                  </li>
                  <ul className="pagination pagination-mobile">
                    {getPageNumbers().map((pageNumber) => (
                      <li
                        onClick={() => handlePageChange(pageNumber)}
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button className="page-link">{pageNumber}</button>
                      </li>
                    ))}
                  </ul>
                  <li
                    className={`page-item  page-next-pre ${
                      currentPage === lastPage ? "disabled" : ""
                    }`}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    {currentPage < lastPage && (
                      <button
                        className="page-link px-1"
                        disabled={currentPage === lastPage}
                      >
                        <img
                          className="normal-image"
                          src="/assets/icons/right-arrow.svg"
                          alt=""
                        />
                        <img
                          className="image-hover"
                          src="/assets/icons/right-white-arrow.svg"
                          alt=""
                        />
                      </button>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        <button className="view-all-btn flex items-center justify-center md:hidden gap-[18px] text-white pt-2 pb-[7px] ">
          <span>View All</span>{" "}
          <img
            className="rotate-90 w-[5px]"
            src="/assets/icons/right-white-arrow.svg"
            alt=""
          />
        </button>
      </div>
      <PopupTable
        visible={modalexport}
        onClose={onCloseexport}
        customizeColumn={customizeColumn}
        saveCustomizedColumns={saveCustomizedColumn}
      />
      <DateRangeModal
        visible={dateRangeModal}
        onClose={toggleDateModal}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </>
  );
};

export default ShipmentsList;
