import React from "react";
import { NavLink } from "react-router-dom";

export default function SidbarInner() {
  return (
    <div
      className="sidbar-list sm:max-w-[313px] w-full pt-6 pr-4 pl-[21px] lg:absolute lg:top-0 lg:left-0 h-full md:mb-10 setting-nav-container"
      
    >
      <NavLink to={"/settings"}>
        <li
          className={`sidebar-list-item flex items-center justify-start p-2 gap-[9px] mb-5 rounded-md`}
        >
          <div className="icon-wrapper w-[31px] h-[31px]  flex items-center justify-center bg-img">
            <img
              src={`/assets/icons/settings-white.svg`}
              alt="settings icone"
            />
          </div>
          <h4 className="sidebar-item-text">Settings </h4>
        </li>
      </NavLink>
      <NavLink to={"/notification"}>
        <li
          className={`sidebar-list-item flex items-center justify-start p-2 gap-[9px] mb-5 rounded-md`}
        >
          <div className="icon-wrapper w-[31px] h-[31px]  flex items-center justify-center bg-img">
            <img
              src={`/assets/icons/notifications-white.svg`}
              alt="settings icone"
            />
          </div>
          <h4 className="sidebar-item-text">Notifications </h4>
        </li>
      </NavLink>
    </div>
  );
}
