import React, { useState, useEffect,useMemo } from "react";
import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";
import PopupTableBooking from "../../components/common/popup-Table-booking";
import { Axios} from "../../pages/config";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { fieldToApiMap } from "../../data/data";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  LOCAL_CLIENT_CODE,
  LOCAL_STORAGE_KEYS,
  COOKIE_KEYS,
} from "../../common/constants";
import { setLocalValue, getLocalValue, getCookie, setCookie } from "../../common/utils";
import { areArrayEqual, convertDateFormat } from "../../common/utils";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import DateRangeModal from "../common/DateRangeModal";
import { debounce } from "lodash";
const options = [
  {
    value: "SELECT",
    label: "All Events",
  },
  {
    value: "AIR",
    label: "By Air",
  },
  {
    value: "SEA",
    label: "By Sea",
  },
  {
    value: "ROA",
    label: "By Road",
  },
];

const BookingsList = ({ keyword }) => {
  const [modalexport, setModalexport] = useState(false);
  const [dataApi, setDataApi] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [loadingcenter, setLoadingcenter] = useState(false);
  const [columnsArray, setColumnsArray] = useState([]);
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [customizedColumnsKeysArray, setCustomizedColumnsKeysArray] = useState(
    []
  );
  const [rowsArray, setRowsArray] = useState([]);
  const [filterPinnedShipments, setFilterPinnedShipments] = useState(0);
  const [tempRowsArray, setTempRowsArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pageInfo, setPageInfo] = useState("");
  const [pageLength, setPageLength] = useState("");
  const [currentPageLength, setCurrentPageLength] = useState("");
  const [enable, setEnable] = useState(false);
  const [lastPage, setLastPage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isHoveredIcon, setIsHoveredIcon] = useState(null);
  const [type, setTypeState] = useState(false);
  const [eventValue, setEventValue] = useState(null);
  const [loading, setloading] = useState(false);

  const [uniqueColumn, setUniqueColumns] = useState([]);
  const [orderedColumns, setOrderedColumns] = useState([]);
  const [firstRun, setFirstRun] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");
  const [dateRangeModal, setDateRangeModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    const reorderedColumns =
      JSON.parse(getLocalValue(LOCAL_STORAGE_KEYS?.bookingColumns)) || [];
    setOrderedColumns(reorderedColumns);
  }, []);

  useEffect(() => {
    const unique = columnsArray?.filter((item, index) => {
      // Find the index of the first occurrence of the current item's field
      const firstIndex = columnsArray?.findIndex(
        (obj) => obj?.field === item?.field
      );

      // Return true only if the current index is the first occurrence of the field
      return index === firstIndex;
    });

    setUniqueColumns(unique);
  }, [columnsArray]);
  useEffect(() => {
    if (
      areArrayEqual(uniqueColumn, orderedColumns) &&
      orderedColumns?.length > 0 &&
      firstRun
    ) {
      setUniqueColumns(orderedColumns);
      setFirstRun(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueColumn]);

  useEffect(() => {
    setTableRows(dataApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoveredIcon]);
  const navigate = useNavigate();
  const navigateToDetailPage = (shipmentID) => {
    navigate(`/bookings/${shipmentID}`);
  };

  const hoveredFunc = (id) => {
    setIsHoveredIcon(id);
  };
  const setTableRows = (bookings = dataApi) => {
    const rows = bookings?.map((booking) => ({
      icon: (
        <>
          <img
            id={`star-${booking.booking_id}`}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(`${booking.booking_id}`, bookings);
            }}
            onMouseEnter={() => hoveredFunc(booking.booking_id)}
            onMouseLeave={() => hoveredFunc(null)}
            src={`/assets/icons/${
              booking.pinned === 1 || booking.booking_id === isHoveredIcon
                ? "star.svg"
                : "star-outline.svg"
            }`}
            alt="star 123"
          />
        </>
      ),
      BookingNumber: booking?.booking_id ?? "NA",
      OrderReference: booking?.shippers_ref ?? "NA",
      originReference: booking.origin ?? "NA",
      trans: (
        <img
          id={`mode-${booking.booking_id}`}
          src={`/assets/icons/${
            booking.trans === "AIR"
              ? "airplane.svg"
              : booking.trans === "SEA"
              ? "ship.svg"
              : booking.trans === "ROA"
              ? "road.svg"
              : ""
          }`}
          alt="Transport Mode"
          style={{ maxHeight: 20, maxWidth: 20 }}
        />
      ),
      mode: booking?.mode ?? "NA",
      Consignor: booking.consignor_name ?? "NA",
      Consignee: booking.consignee_name ?? "NA",
      Destination: booking?.destination ?? "NA",
      consignee: booking?.consignee_name ?? "NA",
      etd: booking?.booking_etd
        ? convertDateFormat(booking?.booking_etd)
        : "NA",
      eta: booking?.booking_eta
        ? convertDateFormat(booking?.booking_eta)
        : "NA",
      carrier_name: booking?.carrier_name ?? "NA",
      EstimateDelivery: "",
      IncoTerm: "",
      vessel: booking?.vessel ?? "NA",
      VoyageFlight: booking?.voyage_flight ?? "NA",
      Weight: booking?.weight ?? "NA",
      Volume: booking?.volume ?? "NA",
      TEU: booking.teu ?? "NA",
      containercount: booking.con_count ?? "NA",
      packs: booking?.packs ?? "NA",
      type: booking?.type ?? "NA",
      bookeddate: booking?.booked ?? "NA",
      cargodescription: booking?.cargo_description ?? "NA",
      // MainVessel: booking.vessel,
      // CurrentVoy: booking.flight_voyage,
      MainVoy: "",
      EstimatedPickup: booking?.etd_load ?? "NA",
      ActualPickup: booking?.etd_first_load ?? "NA",
      JobNotes: booking?.job_status ?? "NA",
      AdditionalTerms: booking?.additional_terms ?? "NA",
      EstimatedDelivery: "",
      LastMilestone: "",
      NextMilestoneDesc: "",
      Quantity: "",
      clickEvent: () => navigateToDetailPage(booking?.booking_id),
    }));

    setRowsArray(rows);
  };

  const pinnedShipments = (filter_pinned) => {
    if (filterPinnedShipments) {
      setFilterPinnedShipments(false);
      setTableRows(dataApi);
    } else {
      setFilterPinnedShipments(true);
      const pinnedShipments = [...dataApi]?.sort((a, b) => {
        if (a.pinned === b.pinned) {
          return 0;
        }
        return a.pinned === 1 ? -1 : 1;
      });
      setTableRows(pinnedShipments);
    }
  };

  const handleClick = (shipmentIdToUpdate, data = dataApi) => {
    setLoadingcenter(true);
    const url = `/pin-booking`;
  Axios
      .post(url, { bookingId: shipmentIdToUpdate })
      .then((response) => {
        let temp_rows = data;
        let row_index = temp_rows.findIndex(
          (item) => item.booking_id === shipmentIdToUpdate
        );
        if (row_index !== -1) {
          temp_rows[row_index].pinned =
            temp_rows[row_index].pinned === 1 ? 0 : 1;
          setTableRows(temp_rows);
          setIsHoveredIcon(false);
          setLoadingcenter(false);
        } else {
          console.error("Shipment not found");
        }
      })
      .catch((error) => console.error("error", error));
  };

  // const fetchAllData = async () => {
  //   setLoading(true);
  //   try {
  //     const allData = [];
  //     let currentPage = 1;
  //     let response;
  //     do {
  //       response = await fetchDataFromAPI(currentPage);
  //       allData.push(...response);
  //       currentPage++;
  //     } while (response.length > 0 && currentPage <= 3);
  //     setDataApi(allData);
  //     setLoading(false);
  //     setTableRows(allData);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };
  const defaultColumnsArray = [
    {
      label: "",
      field: "icon",
      sort: "asc",
      width: 55,
    },
    {
      label: "Booking ID",
      field: "BookingNumber",
      sort: "asc",
      width: 250,
    },
    {
      label: "Order Ref",
      field: "OrderReference",
      sort: "asc",
      width: 220,
    },
    {
      label: "Transport mode",
      field: "trans",
      sort: "asc",
      width: 220,
    },
    {
      label: "Container mode",
      field: "mode",
      sort: "asc",
      width: 220,
    },
    {
      label: "Origin Port",
      field: "originReference",
      sort: "asc",
      width: 250,
    },
    {
      label: "Booking ETD",
      field: "etd",
      sort: "asc",
      width: 200,
    },

    {
      label: "Consignor Name",
      field: "Consignor",
      sort: "asc",
      width: 400,
    },
    {
      label: "Consignee Name",
      field: "Consignee",
      sort: "asc",
      width: 400,
    },

    {
      label: "Destination Port",
      field: "Destination",
      sort: "asc",
      width: 200,
    },
    {
      label: "Destination ETA",
      field: "eta",
      sort: "asc",
      width: 245,
    },
    {
      label: "Carrier Name",
      field: "carrier_name",
      sort: "asc",
      width: 300,
    },
    {
      label: "Vessel",
      field: "vessel",
      sort: "asc",
      width: 245,
    },
    {
      label: "Voyage/Flight",
      field: "VoyageFlight",
      sort: "asc",
      width: 245,
    },
    // {
    //   label: "Estimated Pickup",
    //   field: "EstimatedPickup",
    //   sort: "asc",
    //   width: 200,
    // },
    // {
    //   label: "Consignee",
    //   field: "consignee",
    //   sort: "asc",
    //   width: 245,
    // },

    // {
    //   label: "Estimate Delivery",
    //   field: "EstimateDelivery",
    //   sort: "asc",
    //   width: 245,
    // },
    // {
    //   label: "IncoTerm",
    //   field: "IncoTerm",
    //   sort: "asc",
    //   width: 245,
    // },
  ];

  // useEffect(() => {
  //   setDefaultColumns(defaultColumnsArray);
  //   setColumnsArray(defaultColumnsArray);
  //   // fetchAllData();
  // }, []);

  // useEffect(() => {
  //   const savedPage = localStorage.getItem('currentPage');
  //   if (savedPage) {
  //     setCurrentPage(parseInt(savedPage, 10));
  //   }
  // }, []);

  const handlePageChange = (page) => {
    if (page >= 1) {
      setCurrentPage(page);
      setLocalValue(LOCAL_STORAGE_KEYS?.bookingListCurrentPage, page);
      setLocalValue(LOCAL_STORAGE_KEYS?.bookingListRecords, itemsPerPage);
      setEnable(true);
    }
  };

  const handlePageLimitChange = (event) => {
    const newPageLimit = parseInt(event.target.value, 10);
    setItemsPerPage(newPageLimit);
    setEnable(true);
    handlePageChange(1);
    setLocalValue(LOCAL_STORAGE_KEYS?.bookingListCurrentPage, 1);
    setLocalValue(LOCAL_STORAGE_KEYS?.bookingListRecords, newPageLimit);
  };
  const fetchDataFromAPI = async () => {
    setloading(true);
    if (currentPage !== 0) {
      if (keyword !== "") {
        try {
          let url = `/search-booking?keyword=${keyword}&local_client_code=${LOCAL_CLIENT_CODE}&per_page=${itemsPerPage}&page=${currentPage}`;
          if (order && orderBy) {
            url = `${url}&sort_by=${orderBy}&direction=${order}`;
          }
          if (startDate && endDate) {
            url = `${url}&dateRange=${startDate},${endDate}`;
          }
          const response = await Axios.get(url);
          const totalRecords = response?.total || 0;
          const fromPage =
            response?.current_page * itemsPerPage - (itemsPerPage - 1);
          setTableRows(response.data);
          setDataApi(response.data);
          setLastPage(response.last_page);
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response.total);
          setPageLength(response.to);
          setCurrentPageLength(fromPage);
          
        } catch (error) {
          console.error("error in search-booking", error);
        }
        finally{
          setloading(false);
        }
      } else if (type && eventValue !== "SELECT") {
        try {
          let url = `/bookings-by-event?eventType=${eventValue}&per_page=${itemsPerPage}&page=${currentPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
          if (order && orderBy) {
            url = `${url}&sort_by=${orderBy}&direction=${order}`;
          }
          if (startDate && endDate) {
            url = `${url}&dateRange=${startDate},${endDate}`;
          }
          const response = Axios.get(url);
          setTableRows(response?.data);
          setDataApi(response?.data);
          setLastPage(response?.last_page);
          const totalRecords = response?.total || 0;
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response?.total);
          setPageLength(response?.to);
          const fromPage =
            response?.current_page * itemsPerPage - (itemsPerPage - 1);
          setCurrentPageLength(fromPage);
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        let url = `/bookings?page=${currentPage}&per_page=${itemsPerPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        if (startDate && endDate) {
          url = `${url}&dateRange=${startDate},${endDate}`;
        }
        Axios
          .get(url,)
          .then((response) => {
            setloading(false);
            setTableRows(response?.data);
            setDataApi(response?.data);
            setLastPage(response?.last_page);
            const totalRecords = response?.total || 0;
            setTotalPages(Math.ceil(totalRecords / itemsPerPage));
            setPageInfo(response?.total);
            setPageLength(response?.to);
            const fromPage =
              response?.current_page * itemsPerPage - (itemsPerPage - 1);
            setCurrentPageLength(fromPage);
          })
          .catch((error) => {
            setloading(false);
            console.error("Error fetching data:", error);
          });
      }
    }
  };

  useEffect(() => {
    if (totalPages > 0) {
      const savedPage = getLocalValue(
        LOCAL_STORAGE_KEYS?.bookingListCurrentPage
      );
      if (savedPage && savedPage <= totalPages) {
        setCurrentPage(parseInt(savedPage, 10));
      } else {
        setLocalValue(LOCAL_STORAGE_KEYS?.bookingListCurrentPage, 1);
      }
    }
  }, []);
  useEffect(() => {
    const savedPage = getLocalValue(LOCAL_STORAGE_KEYS?.bookingListCurrentPage);
    const records =getLocalValue(LOCAL_STORAGE_KEYS?.bookingListRecords);
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10));
    } else {
      setCurrentPage(1);
      setLocalValue(LOCAL_STORAGE_KEYS?.bookingListCurrentPage, 1);
    }
    if (records){
      setItemsPerPage(records);
    }
    else {
      setItemsPerPage(10);
      setLocalValue(LOCAL_STORAGE_KEYS?.bookingListRecords, 10);
    }
  }, []);

  const newhandleChange = (event) => {
    setSearchTerm(event?.target?.value);
  };
  const debouncedResults = useMemo(() => {
    return debounce(newhandleChange, 1000);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (searchTerm?.trim() !== "") {
      let url = `/search-booking?keyword=${searchTerm}&local_client_code=${LOCAL_CLIENT_CODE}`;
      if (order && orderBy) {
        url = `${url}&sort_by=${orderBy}&direction=${order}`;
      }
      if (startDate && endDate) {
        url = `${url}&dateRange=${startDate},${endDate}`;
      }
      setloading(true);
      Axios
        .get(url)
        .then((response) => {
          setloading(false);
          setTableRows(response);
          setDataApi(response);
          setLastPage(response?.last_page);
          const totalRecords = response?.data?.total || 0;
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response?.total);
          setPageLength(response?.to);
          setCurrentPageLength(response?.current_page);
        })
        .catch((error) => {
          setloading(false);
        });
    } else {
      fetchDataFromAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    const savedCheckboxData = getCookie(COOKIE_KEYS?.bookingCustomColumns);
    // if (savedCheckboxData) {
    const checkboxData = savedCheckboxData|| [];
    const formattedArray = checkboxData?.map((item) => ({
      label: item.label,
      field: item.id,
      sort: "asc",
      width: 220,
    }));
    const defaultColumnsUpdated = [...defaultColumnsArray, ...formattedArray];
    // Fetch data from your API when the currentPage changes
    if (enable && currentPage && itemsPerPage) {
      fetchDataFromAPI();
    } else {
      fetchDataFromAPI();

      setDefaultColumns(defaultColumnsUpdated);
      setColumnsArray(defaultColumnsUpdated);
    }
    setEnable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, enable, itemsPerPage, keyword, orderBy, order,startDate,endDate]);

  useEffect(() => {
    const savedCheckboxData = getCookie(COOKIE_KEYS?.bookingCustomColumns);
    if (savedCheckboxData) {
    } else {
      setColumnsArray(defaultColumnsArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingcenter) {
    return (
      <div className="text-center loader">
        <CircularProgress />
      </div>
    );
  }
  //events api
  async function handleChange(values) {
    setCurrentPage(1);
    setTypeState(true);
    let val = values[0].value;
    setEventValue(val);
    if (val !== "SELECT") {
      try {
        let url = `/bookings-by-event?eventType=${val}&per_page=${itemsPerPage}&page=${currentPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        if (startDate && endDate) {
          url = `${url}&dateRange=${startDate},${endDate}`;
        }
        setloading(true);
        const response = await Axios.get(url);
    
        setTableRows(response?.data);
        setDataApi(response?.data);
        setLastPage(response?.last_page);
        const totalRecords = response?.total || 0;
        setTotalPages(Math.ceil(totalRecords / itemsPerPage));
        setPageInfo(response?.total);
        setPageLength(response?.to);
        setCurrentPageLength(response?.current_page);
      } catch (error) {
        console.error("Error:", error);
      }
      finally{
        setloading(false)
      }
    } else {
      setloading(true);
      let url = `/bookings?page=${currentPage}&per_page=${itemsPerPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
      if (order && orderBy) {
        url = `${url}&sort_by=${orderBy}&direction=${order}`;
      }
      if (startDate && endDate) {
        url = `${url}&dateRange=${startDate},${endDate}`;
      }
      Axios
        .get(url)
        .then((response) => {
          setTableRows(response?.data);
          setDataApi(response?.data);
          setLastPage(response?.last_page);
          const totalRecords = response?.total || 0;
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response?.total);
          setPageLength(response?.to);
          setCurrentPageLength(response?.current_page);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        }).finally(()=>{
          setloading(false);
        });
    }
  }

  const customizeColumn = (columnId, isChecked, myColumn) => {
    let temp_columns_array = [...customizedColumnsKeysArray];
    if (isChecked) {
      temp_columns_array.push({
        columnId: columnId,
        myColumn: myColumn,
      });
    } else {
      const index = temp_columns_array.findIndex(
        (item) => item.columnId === columnId
      );
      if (index > -1) {
        temp_columns_array.splice(index, 1);
      }
    }
    setCustomizedColumnsKeysArray(temp_columns_array);
    let temp_columns = defaultColumns.filter(
      (column) => !temp_columns_array.includes(column.field)
    );

    for (let i = 0; i < temp_columns_array.length; i++) {
      temp_columns.push({
        label: temp_columns_array[i].myColumn,
        field: temp_columns_array[i].columnId,
        sort: "asc",
        width: 245,
        random: (
          <h5 searchvalue={"tourist"}>
            {" "}
            <span className="badge badge-danger"></span>
          </h5>
        ),
      });
    }
    let temp_rows_data = dataApi.map((item) => {
      const jsonObject = {};

      temp_columns.forEach((key) => {
        if (key.field === "icon") {
          jsonObject[key.field] = (
            <img
              id={`star-${item.booking_id}`}
              onClick={() => handleClick(`${item.booking_id}`)}
              src={`/assets/icons/${
                item.pinned === 1 ? "star.svg" : "star-outline.svg"
              }`}
              alt={`${item.booking_id}`}
            />
          );
        } else {
          jsonObject[key.field] = (
            <Link to={`/shipments/${item.booking_id}`}>
              {item[fieldToApiMap[key.field]]}
            </Link>
          );
        }
      });

      return jsonObject;
    });

    // Now temp_rows_data contains the optimized data.

    setTempRowsArray(temp_rows_data);
  };
  const data = {
    columns: uniqueColumn.map((cell, index) => ({
      label: (
        <Draggable key={index} draggableId={`header-${index}`} index={index}>
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {cell.label}
            </div>
          )}
        </Draggable>
      ),
      field: cell?.field,
      sort: cell?.sort,
      width: cell?.width,
    })),
    rows: rowsArray,
    random: (
      <h5 searchvalue={"tourist"}>
        {" "}
        <span className="badge badge-danger"></span>
      </h5>
    ),
  };

  const onCloseexport = () => {
    setModalexport(false);
    pinnedShipments();
  };

  const handleOpenexport = () => {
    setModalexport(true);
    pinnedShipments();
  };


  const exportToExcel = async () => {
    try {
      setExporting(true);
      let url=`/bookings/export-listings?local_client_code=${LOCAL_CLIENT_CODE}`;
      if (startDate && endDate) {
        url = `${url}&dateRange=${startDate},${endDate}`;
      }
      if(keyword){
        url=`${url}&eventType=${keyword}`
      }
      const response = await Axios.get(
        url,
        {
          responseType: "arraybuffer",
        }
      
      );
      const buffer = new Uint8Array(response);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      const blob = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        "Booking-List.xlsx"
      );
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    } finally {
      setExporting(false);
    }
  };
  const customButtons = () => {
    return (
      <div className="w-full custom-container">
        <div className="select-label  md:inline-block mb-2">
          <label>
            <select
              value={itemsPerPage}
              onChange={handlePageLimitChange}
              className="custom-select custom-select-sm form-control form-control-sm "
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            &nbsp; Entries per page
          </label>
        </div>
        <div className="search-container mb-2">
          <button
            className={`pinned-shipmnets-btn${
              filterPinnedShipments ? " bg-warning pinned-button-active" : ""
            } flex items-center justify-between md:inline-block mr-2 mb-2`}
            onClick={pinnedShipments}
            type="button"
          >
            Pinned Bookings{" "}
            <img
              className="md:hidden"
              src="/assets/icons/start-outline-mobile.svg"
              alt="star"
            />
          </button>
          <button
            className="customise-columns-btn  md:inline-block mr-2 mb-2"
            type="button"
            onClick={handleOpenexport}
          >
            Customise Columns
          </button>
          <button
            className="customise-columns-btn  md:inline-block mr-2 mb-2"
            type="button"
            onClick={toggleDateModal}
          >
            Date Range
          </button>
          {/* <button
            className="customise-columns-btn  md:inline-block mr-2 mb-2"
            type="button"
            onClick={() => exportToExcel()}
            disabled={isExporting}
          >
            {isExporting ? "Exporting..." : "Export Data to XLSX"}
          </button> */}
          <div className="event-types-btn md:inline-block sm:block mb-2">
            <Select
              className="dropdown-btn btn-dropdown"
              searchable={false}
              placeholder={"Event Types"}
              options={options}
              onChange={handleChange}
            />
          </div>
          <div className="md:inline-block sm:block ml-2 ">
            <div className="mdb-datatable-filter">
              <input
                type="text"
                placeholder="Search..."
                onChange={debouncedResults}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const saveCustomizedColumn = (data) => {
    const formattedArray = data?.map((item) => ({
      label: item.label,
      field: item.id,
      sort: "asc",
      width: 220,
    }));
    const defaultColumnsUpdated = [...defaultColumnsArray, ...formattedArray];
    setColumnsArray(defaultColumnsUpdated);
    setRowsArray(tempRowsArray);

    // if (data && data.columns.length > 0 && data.rows.length > 0) {

    setCookie(COOKIE_KEYS?.bookingCustomColumns,data)
    // }

    onCloseexport();
  };

  //   setCookiedata(data);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(uniqueColumn);
    const [reorderedItem] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, reorderedItem);
    const reOrder = JSON.stringify(reorderedColumns);
    setLocalValue(LOCAL_STORAGE_KEYS?.bookingColumns, reOrder);
    setUniqueColumns(reorderedColumns);
  };
  const getPageNumbers = () => {
    const pagesToShow = 3;
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const toggleDateModal = () => {
    setDateRangeModal(!dateRangeModal);
  };
  return (
    <>
      <div className="shipments-list view-all-wrapper">
        {customButtons()}
        {loading ? (
          <div className="text-center loader">
            <CircularProgress />
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="header" direction="horizontal">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <MDBDataTable
                    data={data}
                    entries={100}
                    searchLabel="Search by Shipment ref, order ref, container number or bill of lading"
                    // searchLabel={searchLabel}
                    className="table-wrapper"
                    noBottomColumns={true}
                    scrollX={true}
                    searching={false}
                    // paging
                    onSort={({ column, direction }) => {
                      if (column === "etd" || column === "eta") {
                        setOrderBy(column);
                        setOrder(direction.toUpperCase());
                      } 
                    }}
                  />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <section className={(totalPages === 1||loading) ? "hidden" : ""}>
          <div
            className={
              "table-wrapper absolute right-0 pt-[13px] md:pt-[17px] px-0 pb-4 md:pb-[21px] bottom-0 w-full"
            }
          >
            <div className="grid grid-cols-2 gap-[14px] md:gap-0">
              <div className="page-info pl-[14px] md:pl-[26px] col-span-2 md:col-span-1">
                Showing {currentPageLength} to {pageLength} of {pageInfo}{" "}
                entries
              </div>
              <nav
                aria-label="Page navigation "
                className="col-span-2 md:col-span-1"
              >
                <ul className="pagination justify-content-center pr-[25px]">
                  <li
                    className={`page-item page-next-pre ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <button className="page-link px-1">
                      <img
                        className="rotate-180 normal-image"
                        src="/assets/icons/right-arrow.svg"
                        alt=""
                      />
                      <img
                        className="rotate-180 image-hover"
                        src="/assets/icons/right-white-arrow.svg"
                        alt=""
                      />
                    </button>
                  </li>
                  <ul className="pagination pagination-mobile">
                    {getPageNumbers().map((pageNumber) => (
                      <li
                        onClick={() => handlePageChange(pageNumber)}
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button className="page-link">{pageNumber}</button>
                      </li>
                    ))}
                  </ul>
                  <li
                    className={`page-item  page-next-pre ${
                      currentPage === lastPage ? "disabled" : ""
                    }`}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    {currentPage < lastPage && (
                      <button
                        className="page-link px-1"
                        disabled={currentPage === lastPage}
                      >
                        <img
                          className="normal-image"
                          src="/assets/icons/right-arrow.svg"
                          alt=""
                        />
                        <img
                          className="image-hover"
                          src="/assets/icons/right-white-arrow.svg"
                          alt=""
                        />
                      </button>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        <button className="view-all-btn flex items-center justify-center md:hidden gap-[18px] text-white pt-2 pb-[7px] ">
          <span>View All</span>{" "}
          <img
            className="rotate-90 w-[5px]"
            src="/assets/icons/right-white-arrow.svg"
            alt=""
          />
        </button>
      </div>
      <PopupTableBooking
        visible={modalexport}
        onClose={onCloseexport}
        customizeColumn={customizeColumn}
        saveCustomizedColumns={saveCustomizedColumn}
      />
       <DateRangeModal
        visible={dateRangeModal}
        onClose={toggleDateModal}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </>
  );
};

export default BookingsList;
