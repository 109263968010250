import React, { useState,useEffect } from "react";
import "../../../src/scss/popup.scss";
import { COOKIE_KEYS } from "../../common/constants";
 import { getCookie } from "../../common/utils";

export default function PopupTableCustom({
  visible,
  onClose,
  customizeColumn,
  saveCustomizedColumns,
}) {
  const [checkedColumns, setValues] = useState({});

  // if (!visible) return null;

  const handleClose = (e) => {
    if (e.target.id === "table-modal-wrapper") {
      onClose();
    }
  };

  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const isChecked = e.target.checked;
    const columLabel = e.target.value;

    setValues((prevValues) => ({
      ...prevValues,
      [checkboxId]: {
        id: checkboxId,
        label: columLabel,
        checked: isChecked,
      },
    }));
    customizeColumn(checkboxId, isChecked, columLabel);
  };
  const handleSave = () => {
    const selectedColumns = Object.values(checkedColumns)
      .filter((item) => item.checked)
      .map((item) => ({
        id: item.id,
        label: item.label,
        checked: item?.checked,
      }));
    saveCustomizedColumns(selectedColumns);
    // onClose();
  };
  useEffect(() => {
    const savedCheckboxData =getCookie(COOKIE_KEYS?.customCustomColumns);
    if (savedCheckboxData) {
      const checkboxData = savedCheckboxData||[];
      if (checkboxData?.length) {
        const dataArray = checkboxData?.map((item) => ({ [item?.id]: item }));
        const mergedObject = Object.assign({}, ...dataArray);
        setValues(mergedObject);
      }
    }
  }, []);
  return (
    <div
      id="table-modal-wrapper"
      onClick={handleClose}
      className={`modal-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center transition-all ease-in-out duration-300 ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div
        className={`modal w-[629px] bg-white rounded-[15px] pt-[26px] pr-[27px] pb-[26px] pl-[30px] relative transition-all ease-in-out duration-300 ${
          visible ? "block" : "hidden"
        }`}
      >
        <img
          src="/assets/icons/close.svg"
          alt="close"
          className="absolute top-4 right-[18px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-header pb-[22px]">
          <h4 className="heading">Customise your table columns</h4>
          <div className="description">
            Choose what data you see in the columns in the shipment overview
          </div>
        </div>
        <div className="modal-body pt-[23px]">
          <div className="grid grid-cols-2 gap-x-[60px]">
            <div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Arrival Date">Arrival Date</label>
                <input
                  type="checkbox"
                  id="arrivaldate"
                  name=""
                  value="Arrival Date"
                  checked={checkedColumns?.arrivaldate?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Customscommenceddate">
                  Customs Commenced Date
                </label>
                <input
                  type="checkbox"
                  id="customscommenceddate"
                  name=""
                  value="Customs Commenced Date"
                  checked={checkedColumns?.customscommenceddate?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="supplier">Supplier</label>
                <input
                  type="checkbox"
                  id="supplier"
                  name=""
                  value="Supplier"
                  checked={checkedColumns?.supplier?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="importer">Importer</label>
                <input
                  type="checkbox"
                  id="importer"
                  name=""
                  value="Importer"
                  checked={checkedColumns?.importer?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="containermode">Container mode</label>
                <input
                  type="checkbox"
                  id="containermode"
                  name=""
                  value="Container mode"
                  checked={checkedColumns?.containermode?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="totalpacks">Total Packs</label>
                <input
                  type="checkbox"
                  id="totalpacks"
                  name=""
                  value="Total Packs"
                  checked={checkedColumns?.totalpacks?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="vessel">Vessel</label>
                <input
                  type="checkbox"
                  id="vessel"
                  name=""
                  value="Vessel"
                  checked={checkedColumns?.vessel?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="voyflight">Voyage/Flight</label>
                <input
                  type="checkbox"
                  id="voyflight"
                  name=""
                  value="Voyage/Flight"
                  checked={checkedColumns?.voyflight?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>

              {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="MainVoy">Main Voy./Flight</label>
                                <input type="checkbox" id="MainVoy" name="" value="Main Voy./Flight" checked={checkedColumns['MainVoy']}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="EstimatedPickup">Estimated Pickup</label>
                                <input type="checkbox" id="EstimatedPickup" name="" value="Estimated Pickup" checked={checkedColumns['EstimatedPickup']}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="ActualPickup">Actual Pickup</label>
                                <input type="checkbox" id="ActualPickup" name="" value="Actual Pickup" checked={checkedColumns['ActualPickup']}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="EstimatedDelivery">Estimated Delivery Date</label>
                                <input type="checkbox" id="EstimatedDelivery" name="" value="Estimated Delivery Date" checked={checkedColumns['EstimatedDelivery']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
              {/* <div className='flex items-center justify-between modal-item'>
                                <label htmlFor="LastMilestone">Last Milestone Desc</label>
                                <input type="checkbox" id="LastMilestone" name="" value="Last Milestone Desc" checked={checkedColumns['LastMilestone']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
            </div>
            <div>
              {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="Quantity">Quantity</label>
                                <input type="checkbox" id="Quantity" name="" value="Quantity" checked={checkedColumns['Quantity']}
                                    onChange={handleCheckboxChange} />
                            </div>
                            */}
              {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="NextMilestoneDesc">Next Milestone Desc</label>
                                <input type="checkbox" id="NextMilestoneDesc" name="" value="Next Milestone Desc" checked={checkedColumns['NextMilestoneDesc']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
              {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="JobNotes">Job Notes</label>
                                <input type="checkbox" id="JobNotes" name="" value="Job Notes" checked={checkedColumns['JobNotes']}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="AdditionalTerms">Additional Terms</label>
                                <input type="checkbox" id="AdditionalTerms" name="" value="Additional Terms" checked={checkedColumns['AdditionalTerms']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="masterbill">Master Bill</label>
                <input
                  type="checkbox"
                  id="masterbill"
                  name=""
                  value="Master Bill"
                  checked={checkedColumns?.masterbill?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Weight">Weight</label>
                <input
                  type="checkbox"
                  id="Weight"
                  name=""
                  value="Weight"
                  checked={checkedColumns?.Weight?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Volume">Volume</label>
                <input
                  type="checkbox"
                  id="Volume"
                  name=""
                  value="Volume"
                  checked={checkedColumns?.Volume?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>

              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="branch">Branch</label>
                <input
                  type="checkbox"
                  id="branch"
                  name=""
                  value="Branch"
                  checked={checkedColumns?.branch?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              {/* <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="type">Type</label>
                <input
                  type="checkbox"
                  id="type"
                  name=""
                  value="Type"
                  checked={checkedColumns?.type?.checked}
                  onChange={handleCheckboxChange}
                />
              </div> */}
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="transport">Transport</label>
                <input
                  type="checkbox"
                  id="transport"
                  name=""
                  value="Transport"
                  checked={checkedColumns?.transport?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <button
            className="btn-modal pt-[14px] pb-[18px] w-full mt-[19px] flex items-end justify-center"
            onClick={handleSave}
          >
            save
          </button>
        </div>
      </div>
    </div>
  );
}
