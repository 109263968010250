import React, { useState } from 'react'
import Header from './header';
import Sidebar from './sidebar';
import { Outlet } from 'react-router-dom';

export default function Layout() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div id='layout-wrapper'>
            <div className='hidden md:inline-block radius-box-wrapper'>
                <div className='radius-box'></div>
            </div>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}   />
            <Outlet/>
        </div>
    )
}
