import React from 'react';
import '../../../src/scss/widget.scss';

export default function Widget(props) {
    const handleWidgetClick = () => {
        if (props.handleOpen) {
            props.handleOpen();
        }
        else if(props.setValue){
            props.setValue();
        }
    };

    return (
        <a
            href={props.redirectUrl||props.downloadUrl || '#'} // Set a fallback URL if downloadUrl is not provided
            download={props.downloadUrl ? 'downloaded-file.xlsx' : null} // Set the download attribute conditionally
            className={`flex justify-between info-widget widget-${props.color} ${props.hover ? 'widget-hover cursor-pointer' : ''} ${props.rowReverse ? 'row-reverse-responsive flex-row-reverse' : ''} md:flex-row`}
            onClick={handleWidgetClick}
        >
            <div className='section-text-wrapper'>
                <div className='section-heading'>{props.heading}</div>
                <div className='section-text mt-[9px] md:mt-[6px]'>{props.subHeading}</div>
            </div>
            <div className={`section-image w-[63px] h-[63px] icon-wrapper flex items-center justify-center text-white flex-none`}>
                <img className='widget-img' src={`/assets/icons/${props.iconClass}`} alt="" />
                {props.iconClassHover && <img className='widget-img-hover' src={`/assets/icons/${props.iconClassHover}`} alt="" />}
            </div>
        </a>
    );
}
