import '../scss/pages/login.scss';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik'
import { Axios } from './config';
import { useState } from 'react';
import { ForgetPasswordvalidation } from './validation';
import PopupMessage from '../components/common/popup-message'
import PopupError from '../components/common/popup-error';
// import { useNavigate } from "react-router-dom";
const initialValues = {
    email: '',
}

export default function ForgetPassword() {
    const [forgetMessage] = useState("")
    const [messagePopup, setMessagePopup] = useState(false);
    const [SubmitError, setSubmitError] = useState("")


    // const navigate = useNavigate();

    const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: ForgetPasswordvalidation,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values) => {
            var formdata = new FormData();
            formdata.append("email", values.email);
         
            try {
            
                  const response = await Axios.post("/forgot-password",formdata);
                    const message=response.message;
                
                    setMessagePopup(message);
                
                
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 400) {
                        setMessagePopup("Error: Not Found");
                    } else if (error.response.status === 500) {
                        setMessagePopup("Error: Internal Server Error");
                    } else {
                      setMessagePopup(`Unexpected error: ${error.response.status}`);
                    }
                  } else {
                    // Handle other errors or show a generic error message
                    setSubmitError("Technical support has been contacted");
                    console.error(error);
                  }
                
            }
        },
    });
    const onCloseMessage = () => {
        setMessagePopup("")
    }
    const onCloseError = () => {
        setSubmitError("")
    }
    return (
        <div className='p-0 md:p-[34px] w-full  '>
            <div className='login-page relative'>
                <div className='absolute top-10 left-10'>
                    <Link to={'/'}><img className='w-[250px]' src='/assets/image/Logo.png' alt="logo" /></Link>
                </div>
                <div className='flex items-center justify-center lg:justify-start h-full' >
                    <form onSubmit={handleSubmit} className='flex items-center justify-center lg:inline-block'>
                        <div className='login-wrapper' >
                            <div className='login-card px-6 pt-6 pb-[22px]'>
                                <div className="login-card-header border-bottom-light pb-5">
                                    <h4 className='text-center'>Forget Password</h4>
                                </div>
                                <div className="login-card-body pt-5">
                                    <div className='login-item flex items-start justify-center flex-col gap-[7px] relative'>
                                        <label htmlFor="username">Enter Email</label>
                                        <input type="text" className={`w-full ${touched.email && errors.email ? 'error' : ''}`} name="email" id="email" placeholder='Email' value={values.email}
                                            onChange={handleChange} onBlur={handleBlur} />
                                        {<p className='form-error'>{touched.email && errors.email}</p>}
                                    </div>
                                    <div className={`text-center pt-6 ${forgetMessage ? 'hidden' : 'block'}`}>
                                        <Link to={'/login'} className='forget-password'>Back to Login page</Link >
                                    </div>
                                    <div className='text-mail-message flex items-center justify-center'>{forgetMessage}</div>
                                </div>
                            </div>
                            <button type='submit' className='login-button mt-[18px] w-full'>Reset</button>
                            <PopupMessage visible={messagePopup} onClose={onCloseMessage} heading={"Reset Password"} />
                            <PopupError visible={SubmitError} onClose={onCloseError} heading={"Internal Error"} />

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
