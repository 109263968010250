import React from 'react';

const UnderMaintenance = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-6">
      <div className="bg-white shadow-lg rounded-lg p-10 max-w-md">
        <svg
          className="w-20 h-20 text-yellow-500 mx-auto mb-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m0-4h.01M12 20.25c4.97 0 9-4.03 9-9S16.97 2.25 12 2.25 3 6.28 3 11.25s4.03 9 9 9z"
          ></path>
        </svg>
        <h1 className="text-3xl font-semibold text-gray-800 mb-4">Under Maintenance</h1>
        <p className="text-gray-600 mb-6">
          ICL Hub  is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.
        </p>
      </div>
    </div>
  );
};

export default UnderMaintenance;
