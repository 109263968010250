import React, { useState, useEffect } from 'react';
import '../../../src/scss/popup.scss';
import { getCookie } from '../../common/utils';
import { COOKIE_KEYS } from '../../common/constants';


export default function PopupTableBooking({ visible, onClose, customizeColumn, saveCustomizedColumns }) {
    const [checkedColumns, setValues] = useState({});

    // if (!visible) return null;

    const handleClose = (e) => {
        if (e.target.id === 'table-modal-wrapper') {
            onClose();
        }
    };

    const handleCheckboxChange = (e) => {
        const checkboxId = e.target.id;
        const isChecked = e.target.checked;
        const columLabel = e.target.value;

        setValues((prevValues) => ({
            ...prevValues,
            [checkboxId]: {
                id: checkboxId,
                label: columLabel,
                checked: isChecked,
            },
        }));
        customizeColumn(checkboxId, isChecked, columLabel);
    };
    const handleSave = () => {
        const selectedColumns = Object.values(checkedColumns)
            .filter((item) => item.checked)
            .map((item) => ({
                id: item.id,
                label: item.label,
                checked: item.checked,
            }));
        saveCustomizedColumns(selectedColumns);
        // onClose();
    };

    useEffect(() => {
        const savedCheckboxData =getCookie(COOKIE_KEYS?.bookingCustomColumns);
        if (savedCheckboxData) {
            const checkboxData =savedCheckboxData||[];
            if (checkboxData?.length) {
                const dataArray = checkboxData?.map((item) => ({ [item?.id]: item }));
                const mergedObject = Object.assign({}, ...dataArray);
                setValues(mergedObject);
            }
        }
    }, []);

    return (
        <div
            id="table-modal-wrapper"
            onClick={handleClose}
            className={`modal-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center transition-all ease-in-out duration-300 ${visible ? 'visible' : 'invisible'}`}
        >
            <div className={`modal w-[629px] bg-white rounded-[15px] pt-[26px] pr-[27px] pb-[26px] pl-[30px] relative transition-all ease-in-out duration-300 ${visible ? 'block' : 'hidden'}`}>
                <img src="/assets/icons/close.svg" alt="close" className="absolute top-4 right-[18px] cursor-pointer" onClick={onClose} />
                <div className="modal-header pb-[22px]">
                    <h4 className="heading">Customise your table columns</h4>
                    <div className="description">Choose what data you see in the columns in the shipment overview</div>
                </div>
                <div className="modal-body pt-[23px]">
                    <div className="grid grid-cols-2 gap-x-[60px]">
                        <div>
                            {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="CFSRef">CFS Ref</label>
                                <input type="checkbox" id="CFSRef" name="" value="CFSRef" checked={checkedColumns['CFSRef']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="Weight">Weight</label>
                                <input type="checkbox" id="Weight" name="" value="Weight" checked={checkedColumns.Weight?.checked}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="Volume">Volume</label>
                                <input type="checkbox" id="Volume" name="" value="Volume" checked={checkedColumns?.Volume?.checked}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="TEU">TEU</label>
                                <input type="checkbox" id="TEU" name="" value="TEU" checked={checkedColumns?.TEU?.checked}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="containercount">Container Count</label>
                                <input type="checkbox" id="containercount" name="" value="Container Count" checked={checkedColumns?.containercount?.checked}
                                    onChange={handleCheckboxChange} />
                            </div>

                            {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="packs">Packs</label>
                                <input type="checkbox" id="packs" name="" value="Packs" checked={checkedColumns['packs']}
                                    onChange={handleCheckboxChange} />
                            </div>
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="deliveryrequiredby">Delivery Required By</label>
                                <input type="checkbox" id="deliveryrequiredby" name="" value="Delivery Required By" checked={checkedColumns['deliveryrequiredby']}
                                    onChange={handleCheckboxChange} />
                            </div> */}

                            {/* <div className="flex items-center justify-between pb-[15px] modal-item">
                                <label htmlFor="estdelivery">Estimated Delivery</label>
                                <input
                                    type="checkbox"
                                    id="estdelivery"
                                    name=""
                                    value="Est Delivery"
                                    checked={checkedColumns['estdelivery']}
                                    onChange={handleCheckboxChange}

                                />
                            </div> */}

                        </div>
                        <div>

                            {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="AdditionalTerms">Additional Terms</label>
                                <input type="checkbox" id="AdditionalTerms" name="" value="Additional Terms" checked={checkedColumns['AdditionalTerms']}
                                    onChange={handleCheckboxChange} />
                            </div> 
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="Description">Description</label>
                                <input type="checkbox" id="Description" name="" value="Description" checked={checkedColumns['Description']}
                                    onChange={handleCheckboxChange} />
                            </div> 

                            <div className="flex items-center justify-between pb-[15px] modal-item">
                                <label htmlFor="pickupagent">Pickup Agent</label>
                                <input
                                    type="checkbox"
                                    id="pickupagent"
                                    name=""
                                    value="Pickup Agent"
                                    checked={checkedColumns['pickupagent']}
                                    onChange={handleCheckboxChange}

                                />
                            </div> */}
                            <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="packs">Packs</label>
                                <input type="checkbox" id="packs" name="" value="Packs" checked={checkedColumns?.packs?.checked}
                                    onChange={handleCheckboxChange} />
                            </div>

                            <div className="flex items-center justify-between pb-[15px] modal-item">
                                <label htmlFor="type"> Type</label>
                                <input
                                    type="checkbox"
                                    id="type"
                                    name=""
                                    value="Type"
                                    checked={checkedColumns?.type?.checked}
                                    onChange={handleCheckboxChange}

                                />
                            </div>
                            <div className="flex items-center justify-between pb-[15px] modal-item">
                                <label htmlFor="type"> Booked Date</label>
                                <input
                                    type="checkbox"
                                    id="bookeddate"
                                    name=""
                                    value="Booked Date"
                                    checked={checkedColumns?.bookeddate?.checked}
                                    onChange={handleCheckboxChange}

                                />
                            </div>
                            <div className="flex items-center justify-between pb-[15px] modal-item">
                                <label htmlFor="type">Cargo Description</label>
                                <input
                                    type="checkbox"
                                    id="cargodescription"
                                    name=""
                                    value="Cargo Description"
                                    checked={checkedColumns?.cargodescription?.checked}
                                    onChange={handleCheckboxChange}

                                />
                            </div>

                        </div>
                    </div>
                    <button className='btn-modal pt-[14px] pb-[18px] w-full mt-[19px] flex items-end justify-center' onClick={handleSave}>save</button>

                </div>
            </div>
        </div>
    )
}
