import React from 'react'
import '../scss/pages/containers.scss';
import ContainersList from '../components/containers/list';
import Layout from '../components/common/layout';



function Containers() {
  return (
    <div>
      <Layout/>
      <div className='mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper md:pt-[26px] md:pr-9 md:pb-[27px] md:pl-[26px]'>
        <div className='shipments  bg-white'>
          <div className="shipment-list-header">
            <div className="flex items-center md:justify-start sm:justify-center flex-wrap">
            <h4 className='shipments-heading'> Containers</h4>
            </div>
            <p className='shipments-text max-w-[237px] sm:max-w-none sm-pr-0'>Here’s where you keep up with all your  containers.</p>
          </div>
       <ContainersList keyword={""}/>
        </div>
       
      </div>
    </div>
  )
}

export default Containers
