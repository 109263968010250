import React, { useEffect } from "react";
import { useState } from "react";
import { convertDateFormat } from "../../common/utils";

export default function DeliveryDetails({ dataApi, showDbData, dbData }) {
  const [deliveryDetails, setdeliveryDetails] = useState(true);
  const [isSubShipmentInfo, setSubShipmentInfo] = useState("");

  const toggleAccordionDelivery = () => {
    setdeliveryDetails(!deliveryDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  const dates = showDbData ? dbData?.dates : dataApi?.dates;
  const deliveryDetail = dbData?.delivery_details;

  useEffect(() => {
    if (responseApi !== null) {
      if (
        responseApi?.hasOwnProperty("SubShipmentCollection") &&
        responseApi?.SubShipmentCollection.hasOwnProperty("SubShipment")
      ) {
        setSubShipmentInfo("enable");
      } else {
        setSubShipmentInfo("disable");
      }
    }
  }, [responseApi]);

  return (
    <div className="details-card  pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[21px] md:pb-[30px] mt-[32px] ">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionDelivery}
        >
          <h4 className="font-semibold">Delivery Details</h4>
          <img
            className={`transition-transform transform ${
              deliveryDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          deliveryDetails ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] sm:gap-y-[33px] gap-x-[10px] 3xl:gap-x-[40px]">
              <div className="item">
                <h6 className="item-heading">Pickup From</h6>
                <div className="item-content">
                  {showDbData
                    ? deliveryDetail?.pickup_from || "NA"
                    : isSubShipmentInfo === "disable"
                    ? `${
                        responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsignorPickupDeliveryAddress"
                        )?.[0]?.AddressShortCode
                      } (${
                        responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsignorPickupDeliveryAddress"
                        )?.[0]?.Country?.Code
                      })` ?? "NA"
                    : `${
                        responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsignorPickupDeliveryAddress"
                        )?.[0]?.AddressShortCode
                      } (${
                        responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsignorPickupDeliveryAddress"
                        )?.[0]?.Country?.Code
                      })` ?? "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Delivery Destination</h6>
                <div className="item-content">
                  {showDbData
                    ? deliveryDetail?.delivery_destination || "NA"
                    : (isSubShipmentInfo === "disable"
                        ? `${
                            responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                              (item) =>
                                item?.AddressType ===
                                "ConsigneePickupDeliveryAddress"
                            )[0]?.AddressShortCode
                          } (${
                            responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                              (item) =>
                                item?.AddressType ===
                                "ConsigneePickupDeliveryAddress"
                            )[0]?.Country?.Name
                          })`
                        : `${
                            responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                              (item) =>
                                item?.AddressType ===
                                "ConsigneePickupDeliveryAddress"
                            )[0]?.AddressShortCode
                          } (${
                            responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                              (item) =>
                                item?.AddressType ===
                                "ConsigneePickupDeliveryAddress"
                            )[0]?.Country?.Name
                          })`) || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Actual Pickup</h6>
                <div className="item-content">
                  {typeof dates?.actual_pickup == "string"
                    ? convertDateFormat(dates?.actual_pickup)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Estimated Pickup</h6>
                <div className="item-content">
                  {typeof dates?.estimated_pickup === "string"
                    ? convertDateFormat(dates?.estimated_pickup)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Estimated Delivery Date</h6>
                <div className="item-content">
                  {typeof dates?.estimated_delivery_date === "string"
                    ? convertDateFormat(dates?.estimated_delivery_date)
                    : "NA"}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Actual Delivery</h6>
                <div className="item-content">
                  {typeof dates?.actual_delivery_date === "string"
                    ? convertDateFormat(dates?.actual_delivery_date)
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
