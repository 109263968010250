import "../scss/pages/login.scss";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useParams, useLocation } from "react-router-dom";
import PopupMessage from "../components/common/popup-message";
import { Axios } from "./config";
import { useState } from "react";

const initialValues = {
  password: "",
  Cpassword: "",
};

export default function ResetPassword() {
  let { token } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const [link, setLink] = useState({ text: "", link: "" });
  const [messagePopup, setMessagePopup] = useState(false);
  const { values, errors, handleChange, handleSubmit, handleBlur, touched } =
    useFormik({
      initialValues: initialValues,
      // validationSchema: LoginUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: async (values) => {
        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("password", values.password);
        formdata.append("password_confirmation", values.Cpassword);
        formdata.append("token", token);
        try {
          await Axios.post(`/password/reset`, formdata);
          setMessagePopup(
            "The password for your account has been successfully changed."
          );
          setLink({ text: "Go to Login Page", link: "/login" });
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              setMessagePopup("Error: Not Found");
              setLink({ text: "", link: "" });
            } else if (error.response.status === 500) {
              setMessagePopup("Error: Internal Server Error");
              setLink({ text: "", link: "" });
            }
            else if (error.response.status ===422) {
              setMessagePopup(
                "Your link has expired"
              );
              setLink({ text: "Click here to resend another link", link: "/forgetpassword" });
            }
            
            else {
              setMessagePopup(`Unexpected error: ${error.response.status}`);
              setLink({ text: "", link: "" });
            }
          } else {
            setMessagePopup(`Something went wrong`);
            setLink({ text: "", link: "" });
            console.error(error);
          }
        }
      },
    });
  const onCloseMessage = () => {
    setMessagePopup("");
  };
  return (
    <div className="p-0 md:p-[34px] w-full  ">
      <div className="login-page relative">
        <div className="absolute top-10 left-10">
          <Link to={"/"}>
            <img
              className="w-[250px]"
              src="/assets/image/Logo.png"
              alt="logo"
            />
          </Link>
        </div>

        <div className="flex items-center justify-center lg:justify-start h-full">
          <form
            onSubmit={handleSubmit}
            className="flex items-center justify-center lg:inline-block"
          >
            <div className="login-wrapper">
              <div className="login-card px-6 pt-6 pb-[22px]">
                <div className="login-card-header border-bottom-light pb-5">
                  <h4 className=" text-center">Reset Password</h4>
                </div>

                <div className="login-card-body pt-5 ">
                  {/* {credError ? (
                                        <div className={`cred-error p-[14px] flex items-center justify-start gap-2 mb-4`}>
                                            <TbAlertCircle className='inline-block cred-icon' />Invalid Email or Password
                                        </div>
                                    ) : ''
                                    } */}
                  <div className="login-item flex items-start justify-center flex-col gap-[7px] relative">
                    <label htmlFor="password">Password</label>
                    <input
                      type="Password"
                      className={`w-full ${
                        touched.password && errors.password ? "error" : ""
                      }`}
                      name="password"
                      id="password"
                      placeholder="Enter your new password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {
                      <p className="form-error">
                        {touched.password && errors.password}
                      </p>
                    }
                  </div>
                  <div className="login-item pt-6 flex items-start justify-center flex-col gap-[7px] relative">
                    <label htmlFor="Cpassword">Confirm Password*</label>
                    <input
                      type="Password"
                      className={`w-full  ${
                        touched.Cpassword && errors.Cpassword ? "error" : ""
                      }`}
                      name="Cpassword"
                      id="Cpassword"
                      placeholder="Confirm your password"
                      value={values.Cpassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {
                      <p className="form-error">
                        {touched.Cpassword && errors.Cpassword}
                      </p>
                    }
                  </div>
                </div>
              </div>
              <button type="submit" className="login-button mt-[18px] w-full">
                Reset Password
              </button>
              <PopupMessage
                visible={messagePopup}
                onClose={onCloseMessage}
                heading={"Reset Password"}
                link={link.link}
                linkText={link.text}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
