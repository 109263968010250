import React, { useState, useEffect } from 'react';
import { Axios } from '../../pages/config';
import PopupMessage from '../../components/common/popup-message';
import { LOCAL_STORAGE_KEYS} from '../../common/constants';
import { getLocalValue, setLocalValue } from '../../common/utils';



export default function Settingspage() {
    const [Image, setImage] = useState('');
    const [imageName, setImageName] = useState('');
    const [messagePopup, setMessagePopup] = useState("");

    const [name, setName] = useState({
        firstname: '',
        surname: '',
        email: '',
        phone_number: '',
        address: '',
        postcode: ''
    });
    const [responseData, setResponseData] = useState("")

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const base64Image = e.target.result;
                setImage(base64Image);
                setLocalValue(LOCAL_STORAGE_KEYS?.profileImage,base64Image)
            };

            reader.readAsDataURL(file);
        }
    };
    const handleAvatarSelect = (avatarFileName) => {
        setImageName(avatarFileName);
    }

    useEffect(() => {
        fetchDataFromAPI()

        const storedImage = getLocalValue(LOCAL_STORAGE_KEYS?.profileImage);
        if (storedImage) {
            setImageName(storedImage);
        }
    }, [Image]);
    const fetchDataFromAPI = async () => {
        try {
            const response = await Axios.get(
                `/user`,
                
            );
            setResponseData(response)
            const trimParts = response?.name.trim();
            const nameParts = trimParts.split(" ")
            if (nameParts.length === 1) {
                setName({
                    firstname: nameParts[0], // Only one part, assume it's the first name
                    surname: "",
                    email: response?.email,
                    phone_number: response?.phone_number,
                    address: response?.address,
                    postcode: response?.postcode,
                });
             
            } else {
                setName({
                    firstname: nameParts[0],
                    surname: nameParts.slice(1).join(' '), // Combine the rest of the name parts
                    email: response?.email,
                    phone_number: response?.phone_number,
                    address: response?.address,
                    postcode: response?.postcode,
                });
            }
            setImageName(response?.avatar||"")
        } catch (error) {
            console.error('Error:', error);
            return [];
        }
    };

    const id = responseData.id
    // API for the profile update 
    const updateProfile = async (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append('firstname', name.firstname)
        formData.append('surname', name.surname)
        formData.append('email', name.email)
        formData.append('phone_number', name.phone_number)
        formData.append('address', name.address)
        formData.append('postcode', name.postcode)
        formData.append('avatar', imageName)

        let headers = new Headers();
        headers.append('Accept', 'application/json');
        try {
              await Axios.post(`/update-user/${id}`, formData);
        
            // success message 
            setMessagePopup("User profile updated successfully");
            setLocalValue(LOCAL_STORAGE_KEYS?.userName,name?.firstname + " " + name?.surname)
            setLocalValue(LOCAL_STORAGE_KEYS?.profileImage,imageName)
            window.location.reload();
        } catch (error) {
            console.error(error);
            setMessagePopup("Something Went Wrong");
        }


    }


    const handleNameChange = event => {
        const { name, value } = event.target;
        setName(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };
    const onCloseMessage = () => {
        setMessagePopup("")
    }

    return (
        <div>
            <div className='inline-block w-full lg:pl-[313px]'  >
                <div className='settings-card px-[18px] md:pl-[18px] profile-setting-container' >
                    <div className='border-bottom-light pb-[18px] '>
                        <h4 className='settins-card-heading'>Profile Settings</h4>
                    </div>
                    <div className="settings-card-body">
                        <div className='pt-[46px] pb-[42px] flex items-start justify-start flex-col sm:flex-row md:flex-col lg:flex-row gap-[72px] border-bottom-light'>
                            <div className='flex items-start justify-start gap-[10px] flex-col max-w-[294px] w-full flex-none '>
                                <h4 className='settins-card-heading'>Profile Settings</h4>
                                <p className='settings-card-text'>Upload your own personal profile picture.</p>
                            </div>
                            <div className='profile-avater-wrapper w-full flex items-center justify-center md:justify-start'>
                                <div className='hover-effect rounded-full max-w-[147px] w-full h-[147px]'>
                                    <div className=' profile-avater relative w-[147px] h-[147px]'>
                                        <img className='w-[147px] h-[147px] rounded-full cursor-pointer' src={`/assets/image/${imageName?imageName:"user-Image.png"}`} alt="profileImage" />
                                        <div className='cursor-pointer w-[30px] h-[30px] image-edit flex items-center justify-center absolute top-2 right-[3px]'>
                                            <img className='cursor-pointer' src={`/assets/icons/pencil-alt.svg`} alt="" />
                                        </div>
                                        <div className='w-[30px] h-[30px] absolute top-2 right-[3px] cursor-pointer'>
                                            <input className='rounded-full opacity-0 w-full cursor-pointer' type='file' name='profilePicture' id='profilePicture' onChange={handleImageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=' pb-[56px] flex items-start justify-start flex-col md:flex-col xl:flex-col 2xl:flex-row gap-[26px] border-bottom-light'>
                            <div className='flex items-start justify-start gap-[10px] flex-col w-full max-w-[340px] flex-none'>
                                <h4 className='settins-card-heading'>Avatar</h4>
                                <p className='settings-card-text '>Choose an avatar in place of your profile picture.</p>
                            </div>
                            <div className='profile-avater-wrapper flex items-center justify-evenly sm:justify-start gap-x-[18px] gap-y-5 flex-wrap w-full'>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-02.jpg" alt="avater-2" onClick={() => handleAvatarSelect('ICL_AVATARS-02.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-03.jpg" alt="avater-3" onClick={() => handleAvatarSelect('ICL_AVATARS-03.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-04.jpg" alt="avater-4" onClick={() => handleAvatarSelect('ICL_AVATARS-04.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-05.jpg" alt="avater-5" onClick={() => handleAvatarSelect('ICL_AVATARS-05.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-06.jpg" alt="avater-6" onClick={() => handleAvatarSelect('ICL_AVATARS-06.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-07.jpg" alt="avater-7" onClick={() => handleAvatarSelect('ICL_AVATARS-07.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-07.jpg" alt="avater-7" onClick={() => handleAvatarSelect('ICL_AVATARS-07.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-08.jpg" alt="avater-8" onClick={() => handleAvatarSelect('ICL_AVATARS-08.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-09.jpg" alt="avater-9" onClick={() => handleAvatarSelect('ICL_AVATARS-09.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-10.jpg" alt="avater-10" onClick={() => handleAvatarSelect('ICL_AVATARS-10.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-11.jpg" alt="avater-11" onClick={() => handleAvatarSelect('ICL_AVATARS-11.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-12.jpg" alt="avater-12" onClick={() => handleAvatarSelect('ICL_AVATARS-12.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-13.jpg" alt="avater-13" onClick={() => handleAvatarSelect('ICL_AVATARS-13.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-14.jpg" alt="avater-14" onClick={() => handleAvatarSelect('ICL_AVATARS-14.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-15.jpg" alt="avater-15" onClick={() => handleAvatarSelect('ICL_AVATARS-15.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-16.jpg" alt="avater-16" onClick={() => handleAvatarSelect('ICL_AVATARS-16.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-17.jpg" alt="avater-17" onClick={() => handleAvatarSelect('ICL_AVATARS-17.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-18.jpg" alt="avater-18" onClick={() => handleAvatarSelect('ICL_AVATARS-18.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>
                                </div>
                                <div className='profile-avater relative rounded-full max-w-[94px] w-full h-[94px] cursor-pointer'>
                                    <img className='rounded-full absolute top-0 w-full h-full' src="/assets/image/ICL_AVATARS-19.jpg" alt="avater-19" onClick={() => handleAvatarSelect('ICL_AVATARS-19.jpg')} />
                                    <div className='hover-effect rounded-full max-w-[94px] w-full h-[94px]'></div>

                                </div>
                            </div>
                        </div>

                        <div className=' pb-[46px] sm:pb-[46px] flex items-start justify-start flex-col lg:flex-col 2xl:flex-row gap-[56px]'>
                            <div className='flex items-start justify-start gap-[10px] flex-col max-w-[312px] w-full'>
                                <h4 className='settins-card-heading'>Personal Details</h4>
                                <p className='settings-card-text '>This will be displayed on your profile, as well as used for form submissions and requests.</p>
                            </div>
                            <div className='relative lg:max-w-[714px] 2xl:max-w-[450px] 3xl:max-w-[714px] w-full'>
                                <form onSubmit={updateProfile}>
                                    <div className="grid grid-cols-6 gap-[14px] gap-y-[22px] ">
                                        <div className='col-span-6 sm:col-span-3 card-items relative '>
                                            <label htmlFor="name">First Name*</label>
                                            <input type="text" name="firstname" id="name" autoComplete='off' placeholder='John' value={name.firstname} onChange={handleNameChange} />
                                        </div>
                                        <div className='col-span-6 sm:col-span-3 card-items relative'>
                                            <label htmlFor="surname">Surname*</label>
                                            <input type="text" name="surname" id="Surname" placeholder='Surname' value={name.surname} onChange={handleNameChange} />
                                        </div>
                                        <div className='col-span-6 sm:col-span-3 card-items relative'>
                                            <label htmlFor="email">Email address*</label>
                                            <input type="text" name="email" id="email" placeholder='hello@phunk.co.uk' value={name.email} onChange={handleNameChange} />
                                        </div>
                                        <div className='col-span-6 sm:col-span-3 card-items relative'>
                                            <label htmlFor="number">Contact number*</label>
                                            <input
                                                type="text"
                                                name="phone_number"
                                                id="phone_number"
                                                placeholder="01234 586 932"
                                                onKeyPress={(event) => {
                                                    const numericRegex = /^[0-9\b]+$/;
                                                    if (!numericRegex.test(event.key)) {
                                                    }
                                                }}
                                                value={name.phone_number}
                                                onChange={handleNameChange}
                                            />
                                        </div>
                                        <div className='col-span-6 sm:col-span-4 card-items relative'>
                                            <label htmlFor="Companyname">Address*</label>
                                            <input type="text" name="address" id="Companyname" value={name.address} placeholder='John' onChange={handleNameChange} />
                                        </div>
                                        <div className='col-span-6 sm:col-span-2 card-items relative'>
                                            <label htmlFor="Companyname">Postcode*</label>
                                            <input type="text" name="postcode" id="Companyname" value={name.postcode} placeholder='John' onChange={handleNameChange} />
                                        </div>
                                    </div>
                                    <button type="submit" className="update-btn mt-[17px] md:mt-5">Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupMessage visible={messagePopup} onClose={onCloseMessage} heading={messagePopup?.includes("Some")?"Profile not updated":"Profile updated"} />

        </div>
    )
}
