import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
export default function Tracking({ dataApi, trackingEvents }) {
  const [tracking] = useState(true);
  const [isSubShipmentInfo, setSubShipmentInfo] = useState("");
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;

  useEffect(() => {
    if (responseApi !== null) {
      if (
        responseApi?.hasOwnProperty("SubShipmentCollection") &&
        responseApi?.SubShipmentCollection.hasOwnProperty("SubShipment")
      ) {
        setSubShipmentInfo("enable");
      } else {
        setSubShipmentInfo("disable");
      }
    }
  }, [responseApi]);

  return (
    <div className="tracking-section">
      <div
        className={`overflow-scroll transition-max-height ease-in-out duration-300  ${
          tracking ? "max-h-[500px]" : "max-h-0"
        }`}
      >
        <div className="tracking-section-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2 gap-5">
              <div className="flex items-center justify-between  pl-[25px] pr-[18px] bg-light-primary rounded-[15px] border-light-1">
                <div>
                  <h5 className="heading text-primary">Pickup Location</h5>
                  <p className="content mt-2">
                    {isSubShipmentInfo === "disable"
                      ? `${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.Country?.Code
                        })` ?? "NA"
                      : `${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.Country?.Code
                        })` ?? "NA"}
                  </p>
                </div>
                <img
                  className="w-[22px] flex-none"
                  src="/assets/icons/location-pin-primary.svg"
                  alt="ocation-pin-primary"
                />
              </div>
              <div className="flex items-center justify-between py-[14px] pl-[25px] pr-[18px] bg-light-primary rounded-[15px] border-light-1">
                <div>
                  <h5 className="heading text-success">Delivery Location</h5>
                  <p className="content mt-2">
                    {" "}
                    {(isSubShipmentInfo === "disable"
                      ? `${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.Country?.Name
                        })`
                      : `${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.Country?.Name
                        })`) || "NA"}
                  </p>
                </div>
                <img
                  className="w-[22px] flex-none"
                  src="/assets/icons/location-pin-success.svg"
                  alt="location-pin"
                />
              </div>
            </div>
          </div>
          <div className="">
            {trackingEvents?.events?.map((item) => {
              return (
                <div
                  className="flex items-center justify-start pt-[21px] pb-[19px] pl-[25px] pr-[18px] border-bottom-light gap-[22px]"
                  key={item.order_id}
                >
                  <img
                    src={`${
                      moment(item?.date)?.isBefore(moment())
                        ? "/assets/icons/checkbox-success.svg"
                        : "/assets/icons/timer-success.svg"
                    }`}
                    alt="eventIcon"
                  />

                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <h5 className="heading text-primary">
                        {item?.description || "NA"}
                      </h5>
                      <h5 className="heading text-primary">
                        {moment(item?.date)?.format("DD-MMM-YYYY HH:mm")}
                      </h5>
                    </div>
                    <div className="flex items-center justify-between mt-1">
                      <p className="content">
                        {trackingEvents?.locations.find(
                          (location) => location.id === item.location
                        )?.name || "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
