import React, { useState, useEffect } from "react";
import Widget from "../components/common/widget";
import "../scss/pages/viewbooking.scss";
import PopupUploads from "../components/common/popup-Uploads";
import PopupDownload from "../components/common/popup-Download";
import { useFormik } from "formik";
import OrderDetails from "../components/viewBooking/orderDetails";
import DeliveryDetails from "../components/viewBooking/deliveryDetails";
import ShipmentDetails from "../components/viewBooking/shipmentDetails";
import GoodsDetails from "../components/viewBooking/goodsDetails";
import Layout from "../components/common/layout";
import { useParams } from "react-router-dom";
 import { Axios } from "./config";
import CircularProgress from "@mui/material/CircularProgress";
import PopupMessage from "../components/common/popup-message";
import PopupError from "../components/common/popup-error";
import { allowedDocumentsTypes, documentTypes} from "../common/constants";

export default function ViewBooking() {
  const [modalupload, setModalupload] = useState(false);
  const [modaldownload, setModaldownload] = useState(false);
  const [dataApi, setDataApi] = useState();
  const [isExporting, setExport] = useState(false);
  const [loadingcenter, setLoadingcenter] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [SubmitMessage, setSubmitMessage] = useState("");
  const [SubmitError, setSubmitError] = useState("");
  const [attachedDocuments, setAttachedDocuments] = useState([]);
  const [IsAttachDocLoading, setAttachDocloading] = useState(false);
  const [isUploading,setUploading]=useState(false);
  const [isError, setError] = useState(false);

 

  const onClosedownload = () => {
    setModaldownload(false);
  };
  const toggleupload = (cb,avoidToggle=false) => {
    if(!avoidToggle){
  
      setModalupload(!modalupload);
    }
    
    if (cb) {
      cb();
    }
    setUploadMessage("");
  };

  const handleOpendownload = () => {
    setModaldownload(true);
    fetchAttachDocs();
  };

  // Uploads API for booking page

  const handleUpload = async (receivedFile,cb,docType) => {
     setUploading(true);
    const formdata = new FormData();
    const doc= documentTypes.find(((item)=>item.value===docType));
     formdata.append("event_type", doc.value);
     formdata.append("event_reference",doc.value);
     formdata.append("attachment", receivedFile);
     formdata.append("code",doc.value);
     formdata.append("description",doc.label);
     formdata.append("bookingId", userId);

    try {
      await Axios.post(`/booking/upload-document`, formdata);
      setUploadMessage("Your file has successfully been added");

      if(cb){
        cb()
      }
    } catch (error) {
      console.error("Error:", error);
      setUploadMessage(error.message||"Something went wrong");
      // Show error message to the user
    }
    finally{
      setUploading(false);
    }
  };

  const initialValues = {
    textfield: "",
  };
  const onSubmit = async (values, { resetForm }) => {
    try {
      await Axios.post(`/form-entry/submit`, {
        user_id: 1,
        message: values.textfield,
        reference_id: ` ${
          dataApi &&
          dataApi.Data.UniversalShipment.Shipment.DataContext
            .DataSourceCollection.DataSource.Key
        }`,
        module: "Bookings",
      });
      resetForm();
      setSubmitMessage("Your data has been submitted successfully");
    } catch (error) {
      setSubmitError("No Booking Id Found");
      console.error("Error:", error);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  let { userId } = useParams();
  let bookingId = userId;
  const fetchData = async () => {
    setLoadingcenter(true);
    try {
      const response = await Axios.get(`/booking/${bookingId}`);
      const bookingData = response;
      const parsedData = JSON.parse(bookingData?.json);
      setError(bookingData?.status === "error");
      setDataApi({ ...parsedData, dates: bookingData?.dates });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingcenter(false);
    }
  };
  const fetchAttachDocs = async () => {
    try {
      setAttachDocloading(true);
      const apiUrl = `/shipment/get-list-of-attached-documents/${bookingId}`;
      const response = await Axios.get(`${apiUrl}`);
      const data = response;
      const docs = data.flat();
      const attachDocs = Array.isArray(docs) ? docs : [docs];
      const isPublishedDocs = attachDocs.filter(
        (item) =>
          (item.IsPublished === true || item.IsPublished === "true") &&
          allowedDocumentsTypes.includes(item?.Type?.Code)
      );
      setAttachedDocuments(isPublishedDocs);
    } catch (error) {
      console.error(error);
    } finally {
      setAttachDocloading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // if (loading) {
  //   return <div className='text-center loader'><CircularProgress /></div>;
  // }
  // if (loadingcenter) {
  //   return <div className='text-center loader loader-height'><CircularProgress /></div>;
  // }
  // export csv file api

  const fetchExcelData = async () => {
    const apiUrl = `/export-shipment`;
    const myBooking = bookingId;
    try {
      setExport(true);
      const response = await Axios.get(`${apiUrl}?shipmentId=${myBooking}`, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response], {
        type: "application/vnd.ms-excel",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${bookingId}-bookingDetails.xlsx`;
      link.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setExport(false);
    }
  };
  // download file

  const onCloseMessage = () => {
    setSubmitMessage("");
  };
  const onCloseError = () => {
    setSubmitError("");
  };
  function downloadBase64File(base64Content, fileName) {
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blobUrl;
    a.download = fileName; // Specify the desired file name

    document.body.appendChild(a);
    a.click();

    // Cleanup: remove the temporary link and revoke the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  }

  function downloadAllFiles() {
    attachedDocuments.forEach((file) => {
      downloadBase64File(file.ImageData, file.FileName);
    });
  }
  return (
    <>
      <Layout />

      <div className="mt-[185px] sm:mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper details md:pt-[26px] md:pr-9 md:pb-[30px] md:pl-[26px] ">
        {loadingcenter ? (
          <div className="text-center loader loader-height">
            <CircularProgress />
          </div>
        ) : isError ? (
          <>
            <div
              className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100"
              role="alert"
            >
              <span className="font-medium">There was an unknown error!</span>{" "}
              The site administrator has been automatically contacted. Please
              try again later
            </div>
          </>
        ) : (
          <>
            <section className="daily-update">
              <div className="card mb-[22px] px-4 md:px-6 pt-[14px] md:pt-[22px] pb-[17px] md:pb-[20px]">
                <div className="card-heading pb-[11px] md:pb-[18px]">
                  <div className="">
                    <h4 className="heading">Files</h4>
                  </div>
                </div>
                <div className="card-body pt-[14px] md:pt-[24px]">
                  <div
                    className={`grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3 md:gap-[30px] transition duration-700 ease-in-out`}
                  >
                    <Widget
                      color={`primary`}
                      heading={`UPLOAD`}
                      subHeading={`file for for ICL review`}
                      iconClass={`upload-cloud.svg`}
                      iconClassHover={"upload-cloud-hover.svg"}
                      handleOpen={toggleupload}
                      hover={true}
                      rowReverse={true}
                    />
                    {/* <Widget
                      color={`primary`}
                      heading={isExporting ? "EXPORTING..." : `EXPORT`}
                      subHeading={`booking data as CSV`}
                      iconClass={`download.svg`}
                      iconClassHover={`download-hover.svg`}
                      hover={true}
                      rowReverse={true}
                      handleOpen={() => fetchExcelData()}
                    /> */}
                    <Widget
                      color={`primary`}
                      heading={`DOWNLOAD`}
                      subHeading={`files related to this booking`}
                      iconClass={`file-export.svg`}
                      handleOpen={handleOpendownload}
                      iconClassHover={`file-export-hover.svg`}
                      hover={true}
                      rowReverse={true}
                    />
                    <PopupUploads
                      visible={modalupload}
                      onClose={toggleupload}
                      handleUpload={handleUpload}
                      uploadMessage={uploadMessage}
                     isUploading={isUploading}
                    />
                    <PopupDownload
                      visible={modaldownload}
                      onClose={onClosedownload}
                      handleDownloadClick={downloadBase64File}
                      handleDownloadAll={downloadAllFiles}
                      loading={IsAttachDocLoading}
                      documents={attachedDocuments}
                    />
                    <PopupMessage
                      visible={SubmitMessage}
                      onClose={onCloseMessage}
                      heading={"Submit Data"}
                    />
                    <PopupError
                      visible={SubmitError}
                      onClose={onCloseError}
                      heading={"Submit Data"}
                    />
                  </div>
                </div>
              </div>
            </section>
            <div>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-[33px]">
                <div>
                  <OrderDetails dataApi={dataApi} />
                  <DeliveryDetails dataApi={dataApi} />
                  <ShipmentDetails dataApi={dataApi} />
                </div>
                <div>
                  <GoodsDetails dataApi={dataApi} />
                  <section>
                    <div className="mt-[25px] md:mt-[31px] bg-white support">
                      <div className="support-heading pb-[14px] md:pb-4">
                        <h4>Support</h4>
                        <p className="max-w-[241px] sm:max-w-none">
                          Leave a message regarding your shipment, and one of
                          our team will be in touch.
                        </p>
                      </div>
                      <div className="pt-[21px] md:pt-4">
                        <form
                          className="w-full flex items-center justify-center flex-col"
                          onSubmit={formik.handleSubmit}
                        >
                          <textarea
                            name="textfield"
                            id=""
                            className="support-textfield"
                            placeholder="Your Message…"
                            value={formik.values.textfield}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          ></textarea>
                          <button
                            type="submit"
                            className="support-btn mt-[17px] md:mt-5"
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
