import Shipment from './pages/shipments'
import Dashboard from './pages/dashboard';
import Bookings from './pages/bookings';
import ViewBookings from './pages/view-bookings';
import Customs from './pages/customs';
import CustomsDetails from './pages/custom-details';
import Tariffs from './pages/tariffs';
import Quotes from './pages/quotes';
import NewQuotes from './pages/new-quotes';
import Tracking from './pages/tracking'
import ShipmentsDetails from './pages/shipment-details';
import NotificationPreferences from './pages/notification-preferences';
import Login from './pages/login';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Settings from './pages/settings';
import Notifications from './pages/notifications';
import ResetPassword from './pages/resetPassword';
import ForgetPassword from './pages/forgetPassword';
import { IS_CONTAINER_PAGE_VISIBLE, token,IS_UNDER_MAINTENANCE } from './common/constants';
import ScrollToTop from './components/scrollToTop/ScrolltoTop';
import Containers from './pages/containers';
import ContainerDetails from './pages/container-details';
import UnderMaintenance from './pages/UnderMaintenance';
import { logoutReset } from './common/utils';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
   
  const checkUserToken = () => {
    const userToken = token;
    setIsLoggedIn(!!userToken);
  }

  useEffect(() => {
    checkUserToken();
  }, []);
  if (IS_UNDER_MAINTENANCE) {
    logoutReset()
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/maintenance" replace />} />
          <Route exact path="/maintenance" element={<UnderMaintenance />} />
        </Routes>
      </BrowserRouter>
    );
  }
  
  return (

    <>
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route exact path="/maintenance" element={<UnderMaintenance/>}/>
          <Route exact path="/login" element={!isLoggedIn ? <Login /> : <Navigate to="/" replace />} />
          <Route exact
            path="/"
            element={token ? <Dashboard /> : <Navigate to="/login" replace />}
          />
          <Route 
            path="/dashboard"
            element={token ? <Dashboard /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/shipments"
            element={token ? <Shipment /> : <Navigate to="/login" replace />}
          />
          {IS_CONTAINER_PAGE_VISIBLE?<>          <Route
          exact
            path="/containers"
            element={token ? <Containers /> : <Navigate to="/login" replace />}
          />

            <Route
          exact
            path="/containers/:containerId"
            element={token ? <ContainerDetails /> : <Navigate to="/login" replace />}
          /></>:null}
    
          <Route
          exact
            path="/eventShipments/:keyword"
            element={token ? <Shipment /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/shipments/:userId"
            element={token ? <ShipmentsDetails /> : <Navigate to="/login" replace />}
          />

          <Route
          exact
            path="/bookings"
            element={token ? <Bookings /> : <Navigate to="/login" replace />}
          />
              <Route
          exact
            path="/eventBookings/:keyword"
            element={token ? <Bookings /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/bookings/:userId"
            element={token ? <ViewBookings /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/customs"
            element={token ? <Customs /> : <Navigate to="/login" replace />}
          />
          <Route
            exact
            path="/customs/:customId"
            element={token ? <CustomsDetails /> : <Navigate to="/login" replace />}
          />

          <Route
          exact
            path="/tariffs"
            element={token ? <Tariffs /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/quotes/yourquotes/:userId"
            element={token ? <Quotes /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/quotes/newquotes"
            element={token ? <NewQuotes /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/quotes/newquotes/:userId"
            element={token ? <NewQuotes /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/tracking"
            element={token ? <Tracking /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/notification-preferences"
            element={token ? <NotificationPreferences /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/settings"
            element={token ? <Settings /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/notification"
            element={token ? <Notifications /> : <Navigate to="/login" replace />}
          />
          <Route
          exact
            path="/password/reset/:token"
            element={<ResetPassword />}
          />
          <Route
          exact
            path="/password/reset"
            element={<ResetPassword />}
          />
          <Route
          exact
            path="/forgetpassword"
            element={<ForgetPassword />}
          />

        </Routes>

      </BrowserRouter >
    </>
  );
}

export default App;









