import React from "react";
import { useState } from "react";

export default function GoodsDetails({ dataApi }) {
  const [goodsDetails, setgoodsDetails] = useState(true);

  const toggleAccordionGoods = () => {
    setgoodsDetails(!goodsDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;

  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[21px] md:pb-[34px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionGoods}
        >
          <h4 className="font-semibold">Goods Details</h4>
          <img
            className={`transition-transform transform ${
              goodsDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          goodsDetails ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] md:gap-y-[33px]">
              <div className="item">
                <h6 className="item-heading">Containers</h6>
                <div className="item-content">NA</div>
              </div>
              <div className="item">
                <h6 className="item-heading">Container Number</h6>
                <div className="item-content">
                  {responseApi?.ContainerCount &&
                  Object?.keys(responseApi?.ContainerCount)?.length > 0
                    ? responseApi?.ContainerCount
                    : "NA"}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Description</h6>
                <div className="item-content">
                  {typeof responseApi?.GoodsDescription === "string"
                    ? responseApi?.GoodsDescription
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Weight</h6>
                <div className="item-content">
                  {responseApi?.TotalWeight? Number(responseApi?.TotalWeight).toFixed(2):"NA"}
                  {typeof responseApi?.TotalWeightUnit?.Code === "string"
                    ? responseApi?.TotalWeightUnit?.Code
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Volume</h6>
                <div className="item-content">
                  {typeof responseApi?.TotalVolume === "string"
                    ? responseApi?.TotalVolume
                    : "NA"}
                  {typeof responseApi?.TotalVolumeUnit?.Code === "string"
                    ? responseApi?.TotalVolumeUnit?.Code
                    : "NA"}
                </div>
              </div>
              {/* <div className="item">
                <h6 className="item-heading">Quantity</h6>
                <div className="item-content">
                  {typeof responseApi?.OuterPacks === "string"
                    ? responseApi?.OuterPacks
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Total Packs</h6>
                <div className="item-content">
                  {typeof responseApi?.TotalNoOfPacksDecimal === "string"
                    ? responseApi?.TotalNoOfPacksDecimal
                    : "NA"}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
