import React, { useState, useEffect, useMemo } from "react";
import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Select from "react-dropdown-select";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LOCAL_CLIENT_CODE, LOCAL_STORAGE_KEYS } from "../../common/constants";
import {
  areArrayEqual,
  convertDateFormat,
  getLocalValue,
  parseJson,
  processContainer,
  setLocalValue,
} from "../../common/utils";
import { Axios } from "../../pages/config";
import { debounce, isArray } from "lodash";
import moment from "moment";
const options = [
  {
    value: "SELECT",
    label: "All Events",
  },

  {
    value: "AIR",
    label: "By Air",
  },
  {
    value: "SEA",
    label: "By Sea",
  },
  {
    value: "ROA",
    label: "By Road",
  },
];

const ContainersList = ({ keyword }) => {
  const [dataApi, setDataApi] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadingcenter, setLoadingcenter] = useState(false);
  const [columnsArray, setColumnsArray] = useState([]);
  const [uniqueColumn, setUniqueColumns] = useState([]);
  const [rowsArray, setRowsArray] = useState([]);
  const [filterPinnedContainers, setFilterPinnedContainers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pageInfo, setPageInfo] = useState("");
  const [pageLength, setPageLength] = useState("");
  const [currentPageLength, setCurrentPageLength] = useState("");
  const [enable, setEnable] = useState(false);
  const [lastPage, setLastPage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isHoveredIcon, setIsHoveredIcon] = useState(null);
  const [type, setTypeState] = useState(false);
  const [eventValue, setEventValue] = useState(null);
  const [orderedColumns, setOrderedColumns] = useState([]);
  const [firstRun, setFirstRun] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  useEffect(() => {
    const reorderedColumns =
      JSON.parse(getLocalValue(LOCAL_STORAGE_KEYS?.containerColumns)) || [];
    setOrderedColumns(reorderedColumns);
  }, []);
  useEffect(() => {
    const unique = columnsArray?.filter((item, index) => {
      // Find the index of the first occurrence of the current item's field
      const firstIndex = columnsArray?.findIndex(
        (obj) => obj?.field === item?.field
      );

      // Return true only if the current index is the first occurrence of the field
      return index === firstIndex;
    });

    setUniqueColumns(unique);
  }, [columnsArray]);
  useEffect(() => {
    if (
      areArrayEqual(uniqueColumn, orderedColumns) &&
      orderedColumns?.length > 0 &&
      firstRun
    ) {
      setUniqueColumns(orderedColumns);
      setFirstRun(false);
    }
  }, [uniqueColumn, firstRun, orderedColumns]);

  useEffect(() => {
    setTableRows(dataApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoveredIcon]);
  const navigate = useNavigate();
  const navigateToDetailPage = (id) => {
    navigate(`/containers/${id}`);
  };
  const hoveredFunc = (id) => {
    setIsHoveredIcon(id);
  };
const getParsedValue=(value)=>{
  let parsedValue = parseJson(value);
  if (parsedValue && parsedValue.length >= 2) {
    let parsedTime=typeof parsedValue[1]==="string"?parsedValue[1].split(" "):"";
     parsedTime=isArray(parsedTime)?`${parsedTime[1]} ${parsedTime[2]}`:"";
     const parsedDate=typeof parsedValue?.[0] ==="string"?convertDateFormat(parsedValue?.[0]):""
  
    parsedValue = `${parsedDate} ${parsedTime}`;
    
  } else {
    parsedValue = "";
  }
  parsedValue= parsedValue.trim();
  parsedValue=parsedValue?moment(parsedValue, "DD-MMM-YYYY hh:mm A")?.format("DD-MMM-YYYY HH:mm"):"NA"
  return parsedValue;
 
}
  const setTableRows = (containers = dataApi) => {
    const rows = containers.map((container) => ({
      icon: (
        <>
          <img
            id={`star-${container?.container_number}`}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(`${container?.container_number}`, containers);
            }}
            onMouseEnter={() => hoveredFunc(container?.container_number)}
            onMouseLeave={() => hoveredFunc(null)}
            src={`/assets/icons/${
              container?.pinned === 1 ||
              container?.container_number === isHoveredIcon
                ? "star.svg"
                : "star-outline.svg"
            }`}
            alt="star 123"
          />
        </>
      ),
      ctrNo: processContainer(container?.container_number || ""),
      containerType: container?.container_type || "",
      containerMode: container.container_mode,
      originPort: container.origin ?? "NA",
      consignee: container.consignee_name ?? "NA",
      consignor: container.consignor_name ?? "NA",
      etd: container.origin_etd
        ? convertDateFormat(container.origin_etd)
        : "NA",

      // destination: container.destination ?? "NA",
      orderRef: container?.shippers_reference ?? "NA",
      Bill: container.house_ref ?? "NA",
      CurrentVessel: container.vessel ?? "NA",
      destination_eta: container?.destination_eta
        ? convertDateFormat(container?.destination_eta)
        : "NA",
      empty_return: container?.empty_return
        ? convertDateFormat(container?.empty_return)
        : "NA",
        actual_delivery_date:getParsedValue(container?.atd)||"NA",
      estimated_delivery_date:getParsedValue(container?.etd)||"NA",

      offQuay: container?.off_quay
        ? convertDateFormat(container?.off_quay)
        : "NA",
        order_ref:container?.order_ref||"NA",
        shipment_ref:container?.shipment_ref||"NA",
        consol_ata: container?.consol_ata
        ?moment(container?.consol_ata)?.format("DD-MMM-YYYY") 

        : "NA",

      clickEvent: () => navigateToDetailPage(container?.container_number),
    }));

    setRowsArray(rows);
  };

  const pinnedContainers = () => {
    if (filterPinnedContainers) {
      setFilterPinnedContainers(false);
      setTableRows(dataApi);
    } else {
      setFilterPinnedContainers(true);
      const pinnedContainers = [...dataApi]?.sort((a, b) => {
        if (a.pinned === b.pinned) {
          return 0;
        }
        return a.pinned === 1 ? -1 : 1;
      });
      setTableRows(pinnedContainers);
    }
  };

  const handleClick = (container_number, data = dataApi) => {
    setLoadingcenter(true);

    const url = `/pin-container`;
    Axios.post(url, { containerNumber: container_number })
      .then(() => {
        let temp_rows = data;
        let row_index = temp_rows.findIndex(
          (item) => item.container_number === container_number
        );

        if (row_index !== -1) {
          temp_rows[row_index].pinned =
            temp_rows[row_index].pinned === 1 ? 0 : 1;
          setTableRows(temp_rows);
          setIsHoveredIcon(false);
        } else {
          console.error("Container not found");
        }
      })
      .catch((error) => console.error("error", error))
      .finally(() => {
        setLoadingcenter(false);
      });
  };
  const defaultColumnsArray = [
    {
      label: "",
      field: "icon",
      sort: "asc",
      width: 50,
    },
    {
      label: "CTR No",
      field: "ctrNo",
      sort: "asc",
      width: 220,
    },

    {
      label:"Shipment Number",
      field:"shipment_ref",
      sort: "asc",
      width: 220,

    },
    {
      label: "Order Ref",
      field: "orderRef",
      sort: "asc",
      width: 220,
    },
    {
      label: "Type",
      field: "containerType",
      sort: "asc",
      width: 120,
    },
    {
      label: "Mode",
      field: "containerMode",
      sort: "asc",
      width: 220,
    },
    {
      label: "ETA",
      field: "destination_eta",
      sort: "asc",
      width: 220,
    },
    {
      label: "EST Del Date/Time ",
      field: "estimated_delivery_date",
      sort: "asc",
      width: 220,
    },
    {
      label: "ACT Del Date/Time",
      field: "actual_delivery_date",
      sort: "asc",
      width: 220,
    },
    {
      label: "Empty Return",
      field: "empty_return",
      sort: "asc",
      width: 220,
    },
    {
      label: "ATA",
      field:"consol_ata", 
      sort: "asc",
      width: 220,
      }
  ];

  useEffect(() => {
    const savedPage = getLocalValue(
      LOCAL_STORAGE_KEYS?.containerListCurrentPage
    );
    const records = getLocalValue(LOCAL_STORAGE_KEYS?.containerListRecords);
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10));
    } else {
      setCurrentPage(1);
      setLocalValue(LOCAL_STORAGE_KEYS?.containerListCurrentPage, 1);
    }
    if (records) {
      setItemsPerPage(records);
    } else {
      setItemsPerPage(10);
      setLocalValue(LOCAL_STORAGE_KEYS?.containerListRecords, 10);
    }
  }, []);

  const handlePageChange = (page) => {
    if (page >= 1) {
      setLocalValue(LOCAL_STORAGE_KEYS?.containerListCurrentPage, page);
      setLocalValue(LOCAL_STORAGE_KEYS?.containerListRecords, itemsPerPage);
      setCurrentPage(page);
      setEnable(true);
    }
  };

  const handlePageLimitChange = (event) => {
    const newPageLimit = parseInt(event.target.value, 10);
    setItemsPerPage(newPageLimit);
    setEnable(true);
    handlePageChange(1);
    setLocalValue(LOCAL_STORAGE_KEYS?.containerListRecords, newPageLimit);
    setLocalValue(LOCAL_STORAGE_KEYS?.containerListCurrentPage, 1);
  };
  const fetchDataFromAPI = async () => {
    setloading(true);
    if (currentPage !== 0) {
      if (keyword !== "") {
        let url = `/search-container?keyword=${keyword}&local_client_code=${LOCAL_CLIENT_CODE}&per_page=${itemsPerPage}&page=${currentPage}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        Axios.get(url)
          .then((response) => {
            const totalRecords = response?.total || 0;
            const fromPage =
              response?.current_page * itemsPerPage - (itemsPerPage - 1);
            setTableRows(response?.data);
            setDataApi(response?.data);
            setLastPage(response?.last_page);
            setTotalPages(Math.ceil(totalRecords / itemsPerPage));
            setPageInfo(response?.total);
            setPageLength(response?.to);
            setCurrentPageLength(fromPage);

            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      } else if (type && eventValue !== "SELECT") {
        let url = `/containers-by-event?eventType=${eventValue}&per_page=${itemsPerPage}&page=${currentPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        Axios.get(url)
          .then((response) => {
            setloading(false);
            setTableRows(response?.data);
            setDataApi(response?.data);
            setLastPage(response?.last_page);
            const totalRecords = response?.total || 0;
            setTotalPages(Math.ceil(totalRecords / itemsPerPage));
            setPageInfo(response?.total);
            setPageLength(response?.to);
            const fromPage =
              response?.current_page * itemsPerPage - (itemsPerPage - 1);
            setCurrentPageLength(fromPage);
          })
          .catch((error) => {
            setloading(false);
          });
      } else {
        let url = `/containers?page=${currentPage}&per_page=${itemsPerPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        Axios.get(url)
          .then((response) => {
            setloading(false);
            setTableRows(response?.data);
            setDataApi(response?.data);
            setLastPage(response?.last_page || 0);
            const totalRecords = response?.total || 0;
            setTotalPages(Math.ceil(totalRecords / itemsPerPage));
            setPageInfo(response?.total);
            setPageLength(response?.to);
            const fromPage =
              response?.current_page * itemsPerPage - (itemsPerPage - 1);
            setCurrentPageLength(fromPage);
          })
          .catch((error) => {
            console.error(error);
            setloading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (searchTerm?.trim() !== "") {
      let url = `/search-container?keyword=${searchTerm}&local_client_code=${LOCAL_CLIENT_CODE}`;
      if (order && orderBy) {
        url = `${url}&sort_by=${orderBy}&direction=${order}`;
      }
      setloading(true);
      Axios.get(url)
        .then((response) => {
          setloading(false);
          setTableRows(response);
          setDataApi(response);
          setLastPage(response?.last_page);
          const totalRecords = response?.total || 0;
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response?.total);
          setPageLength(response?.to);
          setCurrentPageLength(response?.current_page);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setloading(false);
        });
    } else {
      fetchDataFromAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useMemo(() => {
    const defaultColumnsUpdated = [...defaultColumnsArray];
    setColumnsArray(defaultColumnsUpdated);
    // Fetch data from your API when the currentPage changes
    fetchDataFromAPI();

    setEnable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, enable, itemsPerPage, keyword, orderBy, order]);

  const newhandleChange = (event) => {
    setSearchTerm(event?.target?.value);
  };
  const debouncedResults = useMemo(() => {
    return debounce(newhandleChange, 1000);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  if (loadingcenter) {
    return (
      <div className="text-center loader">
        <CircularProgress />
      </div>
    );
  }
  //events api
  async function handleChange(values) {
    setCurrentPage(1);
    setTypeState(true);
    let val = values[0].value;
    setEventValue(val);
    setLocalValue(LOCAL_STORAGE_KEYS?.containerListCurrentPage, 1);
    if (val !== "SELECT") {
      try {
        setloading(true);
        let url = `/containers-by-event?eventType=${val}&per_page=${itemsPerPage}&page=${currentPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
        if (order && orderBy) {
          url = `${url}&sort_by=${orderBy}&direction=${order}`;
        }
        const response = await Axios.get(url);
        setTableRows(response?.data);
        setDataApi(response?.data);
        setLastPage(response?.last_page);
        const totalRecords = response?.total || 0;
        setTotalPages(Math.ceil(totalRecords / itemsPerPage));
        setPageInfo(response?.total);
        setPageLength(response?.to);
        setCurrentPageLength(response?.current_page);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setloading(false);
      }
    } else {
      let url = `/containers?page=${currentPage}&per_page=${itemsPerPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
      if (order && orderBy) {
        url = `${url}&sort_by=${orderBy}&direction=${order}`;
      }
      setloading(true);
      Axios.get(url)
        .then((response) => {
          setTableRows(response?.data);
          setDataApi(response?.data);
          setLastPage(response?.last_page);
          const totalRecords = response?.total || 0;
          setTotalPages(Math.ceil(totalRecords / itemsPerPage));
          setPageInfo(response?.total);
          setPageLength(response?.to);
          setCurrentPageLength(response?.current_page);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }

  const data = {
    columns: uniqueColumn.map((cell, index) => ({
      label: (
        <Draggable key={index} draggableId={`header-${index}`} index={index}>
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {cell.label}
            </div>
          )}
        </Draggable>
      ),
      field: cell?.field,
      sort: cell?.sort,
      width: cell?.width,
    })),
    rows: rowsArray,
    random: (
      <h5 searchvalue={"tourist"}>
        {" "}
        <span className="badge badge-danger"></span>
      </h5>
    ),
  };

  const customButtons = () => {
    return (
      <div className="w-full custom-container">
        <div className="select-label  md:inline-block mb-2">
          <label>
            <select
              value={itemsPerPage}
              onChange={handlePageLimitChange}
              className="custom-select custom-select-sm form-control form-control-sm "
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            &nbsp; Entries per page
          </label>
        </div>
        <div className="search-container mb-2">
          <button
            className={`pinned-shipmnets-btn${
              filterPinnedContainers ? " bg-warning pinned-button-active" : ""
            } flex items-center justify-between md:inline-block mr-2 mb-2 ml-auto`}
            onClick={pinnedContainers}
            type="button"
          >
            Pinned Containers
            <img
              className="md:hidden"
              src="/assets/icons/start-outline-mobile.svg"
              alt="star"
            />
          </button>

          <div className="event-types-btn md:inline-block sm:block mb-2">
            <Select
              className="dropdown-btn btn-dropdown"
              searchable={false}
              placeholder={"Types"}
              options={options}
              onChange={handleChange}
            />
          </div>
          <div className="md:inline-block sm:block ml-2 ">
            <div className="mdb-datatable-filter">
              <input
                type="text"
                placeholder="Search by CTR No"
                onChange={debouncedResults}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //   setCookiedata(data);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(uniqueColumn);
    const [reorderedItem] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, reorderedItem);

    const reOrder = JSON.stringify(reorderedColumns);
    setLocalValue(LOCAL_STORAGE_KEYS?.containerColumns, reOrder);
    setUniqueColumns(reorderedColumns);
  };

  const getPageNumbers = () => {
    const pagesToShow = 3;
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };
  return (
    <>
      <div className="shipments-list view-all-wrapper">
        {customButtons()}
        {loading ? (
          <div className="text-center loader">
            <CircularProgress />
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="header" direction="horizontal">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <MDBDataTable
                    data={data}
                    entries={100}
                    searchLabel="Search by Shipment ref, order ref, container number or bill of lading"
                    // searchLabel={searchLabel}
                    className="table-wrapper"
                    noBottomColumns={true}
                    scrollX={true}
                    searching={false}
                    // paging
                    // disableRetreatAfterSorting={true}
                    sortable={true}
                    onSort={({ column, direction }) => {

                      if (
                        column === "destination_eta" ||
                        column === "estimated_delivery_date" ||
                        column === "empty_return"||
                        column === "actual_delivery_date"
                      ) {
                        setOrderBy(column);
                        setOrder(direction.toUpperCase());
                      }
                    }}
                  />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <section
          className={totalPages === 1 && currentPage === 0 ? "hidden" : ""}
        >
          <div
            className={
              "table-wrapper absolute right-0 pt-[13px] md:pt-[17px] px-0 pb-4 md:pb-[21px] bottom-0 w-full"
            }
          >
            <div className="grid grid-cols-2 gap-[14px] md:gap-0">
              <div className="page-info pl-[14px] md:pl-[26px] col-span-2 md:col-span-1">
                Showing {currentPageLength} to {pageLength} of {pageInfo}{" "}
                entries
              </div>
              <nav
                aria-label="Page navigation flex justify-between items-center "
                className="col-span-2 md:col-span-1"
              >
                <ul className="pagination justify-content-center pr-[25px]">
                  <li
                    className={`page-item page-next-pre ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <button className="page-link px-1">
                      <img
                        className="rotate-180 normal-image"
                        src="/assets/icons/right-arrow.svg"
                        alt=""
                      />
                      <img
                        className="rotate-180 image-hover"
                        src="/assets/icons/right-white-arrow.svg"
                        alt=""
                      />
                    </button>
                  </li>
                  <ul className="pagination pagination-mobile">
                    {getPageNumbers().map((pageNumber) => (
                      <li
                        onClick={() => handlePageChange(pageNumber)}
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button className="page-link">{pageNumber}</button>
                      </li>
                    ))}
                  </ul>
                  <li
                    className={`page-item  page-next-pre ${
                      currentPage === lastPage ? "disabled" : ""
                    }`}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    {currentPage < lastPage && (
                      <button
                        className="page-link px-1"
                        disabled={currentPage === lastPage}
                      >
                        <img
                          className="normal-image"
                          src="/assets/icons/right-arrow.svg"
                          alt=""
                        />
                        <img
                          className="image-hover"
                          src="/assets/icons/right-white-arrow.svg"
                          alt=""
                        />
                      </button>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        <button className="view-all-btn flex items-center justify-center md:hidden gap-[18px] text-white pt-2 pb-[7px] ">
          <span>View All</span>{" "}
          <img
            className="rotate-90 w-[5px]"
            src="/assets/icons/right-white-arrow.svg"
            alt=""
          />
        </button>
      </div>
    </>
  );
};

export default ContainersList;
