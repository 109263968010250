import { convertDateFormat } from "../../common/utils";
import React from "react";
import { useState } from "react";
export default function DeliveryDetails({ dataApi }) {
  const [deliveryDetails, setdeliveryDetails] = useState(true);

  const toggleAccordionDelivery = () => {
    setdeliveryDetails(!deliveryDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  const dates = dataApi?.dates;

  return (
    <div className="details-card  pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[21px] md:pb-[30px] mt-[27px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionDelivery}
        >
          <h4 className="font-semibold">Delivery Details</h4>
          <img
            className={`transition-transform transform ${
              deliveryDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          deliveryDetails ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-y-[21px] sm:gap-y-[33px] 3xl:gap-x-[10px] ">
              <div className="item">
                <h6 className="item-heading">Origin</h6>
                <div className="item-content">
                  {responseApi?.PortOfOrigin?.Name ? (
                    <>
                      {responseApi?.PortOfOrigin?.Name} (
                      {responseApi?.PortOfOrigin?.Code})
                    </>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Destination</h6>
                <div className="item-content">
                  {responseApi?.PortOfDestination?.Name ? (
                    <>
                      {responseApi?.PortOfDestination?.Name} (
                      {responseApi?.PortOfDestination?.Code})
                    </>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Departure</h6>

                <div className="item-content  text-font-bold">
                  {typeof dates?.etd == "string"
                    ? convertDateFormat(dates?.etd)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Arrival</h6>
                <div className="item-content text-font-bold">
                  {typeof dates?.eta == "string"
                    ? convertDateFormat(dates?.eta)
                    : "NA"}
                </div>
              </div>
              {/* <div className='item'>
                                <h6 className='item-heading'>Estimated Delivery</h6>
                                <div className='item-content'>{dataApi && dataApi.Data.UniversalShipment.Shipment.LocalProcessing.EstimatedDelivery}</div>
                            </div>
                            <div className='item'>
                                <h6 className='item-heading'>Date Of Arrival</h6>
                                <div className='item-content'>{dataApi && dataApi.Data.UniversalShipment.Shipment.DateCollection.Date[4].Value}</div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
