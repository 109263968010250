import React, { useState } from 'react'

export default function GoodsDetails({ dataApi }) {
    const [goodsDetails, setgoodsDetails] = useState(true);
    const toggleAccordionGoods = () => {
        setgoodsDetails(!goodsDetails);
    };
    const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment


    return (
        <div className='details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[21px] md:pb-[30px]'>
            <div className="card-heading">
                <button className="flex items-center justify-between w-full text-left" onClick={toggleAccordionGoods}>
                    <h4 className="font-semibold">Goods Details</h4>
                    <img className={`transition-transform transform ${goodsDetails ? 'rotate-[270deg]' : 'rotate-90'}`} src="/assets/icons/right-arrow.svg" alt="right-arrow-icon" />
                </button>
            </div>
            <div className={`overflow-hidden transition-max-height ease-in-out duration-300  ${goodsDetails ? 'max-h-96' : 'max-h-0'}`}>

                <div className="details-card-body">
                    <div className="container-xl">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] md:gap-y-[33px]">
                            <div className='item'>
                                <h6 className='item-heading'>Container Number</h6>
                                <div className='item-content'>{
                                   responseApi?.ContainerCollection?.Container?.ContainerNumber &&
                                        Object.keys(responseApi.ContainerCollection.Container.ContainerNumber).length > 0
                                        ? responseApi.ContainerCollection.Container.ContainerNumber
                                        : "NA"
                                }</div>
                            </div>
                            <div className='item'>
                                <h6 className='item-heading'>Type</h6>
                                <div className='item-content'>{responseApi?.ContainerCollection?.Container?.ContainerType?.Code ?? "NA"}</div>
                            </div>
                            <div className='item'>
                                <h6 className='item-heading'>Count</h6>
                                <div className='item-content'>{responseApi?.ContainerCollection?.Container?.ContainerCount ?? "NA"}</div>
                            </div>
                            <div className='item'>
                                <h6 className='item-heading'>Description</h6>
                                <div className='item-content'>{
                                  responseApi?.GoodsDescription&&
                                        Object.keys(responseApi?.GoodsDescription).length > 0
                                        ? responseApi?.GoodsDescription
                                        : "NA"
                                }</div>
                            </div>
                            <div className='item'>
                                <h6 className='item-heading'>Pieces</h6>
                                <div className='item-content'>{responseApi?.OuterPacks ?? "NA"} </div>
                            </div>
                            <div className='item'>
                                <h6 className='item-heading'>Pack Type</h6>
                                <div className='item-content'>{responseApi?.OuterPacksPackageType?.Code ?? "NA"} </div>
                            </div>
                            <div className='item'>
                                <h6 className='item-heading'>Weight</h6>
                                <div className='item-content'>{dataApi && typeof parseInt(responseApi?.TotalWeight)==='number'?parseInt(responseApi?.TotalWeight).toFixed(2):"NA"} {responseApi?.TotalWeightUnit?.Code}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
