import React from 'react';
import '../scss/pages/quotes.scss';
import QuotesList from '../components/quotes/list';
import Layout from '../components/common/layout';

function Quotes() {
  return (
    <div>
      <Layout/>
      <div className='mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper md:pt-[26px] md:pr-9 md:pb-[27px] md:pl-[26px]'>
        <div className='quotes bg-white'>
          <div className='pt-5  md:pt-[25px] pl-[15px] pb-[14px] md:pl-[26px] md:pb-[22px] flex items-start justify-start flex-col gap-[13px]'>
            <h4 className='quotes-heading'>Your Quotes</h4>
            <p className='quotes-text pr-[68px] sm:pr-0'>Here’s where you keep up with all your quotes</p>
          </div>
          <QuotesList/>
        </div>
      </div>
    </div>
  )
}

export default Quotes
