import "../scss/pages/login.scss";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { LoginUpSchema } from "./validation";
import { Axios, baseURL } from "./config";
import axios from "axios";
import { useEffect, useState } from "react";
import { TbAlertCircle } from "react-icons/tb";
import loginBg from "../assets/longin.svg";
import { getLocalValue, setLocalValue } from "../common/utils";
import { LOCAL_STORAGE_KEYS } from "../common/constants";
import PopupMessage from '../components/common/popup-message';
const initialValues = {
  username: "",
  password: "",
};

export default function Login() {
  const [credError, setCredError] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const { values, errors, handleChange, handleSubmit, handleBlur, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LoginUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: async (values) => {
        let payload = {
          username: values.username,
          email: values.username,
          password: values.password,
        };
        try {
          const response = await Axios.post(`/login`, payload);
          const token = response.token;
          if (token) {
            const userRes = await axios.get(`${baseURL}/user`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const userData=userRes?.data;
            const localClientCode =
              userData?.organisation?.local_client_code;
            const companyName = userData?.organisation?.name || "";
            const userName = userData?.name || "";
            const avatar = userData?.avatar || "";
            const organisations= userData?.organisations||[]
            setLocalValue(LOCAL_STORAGE_KEYS?.userOrganizations,JSON.stringify(organisations))
            setLocalValue(LOCAL_STORAGE_KEYS?.token, token);
            if (
              localClientCode &&
              localClientCode !== null &&
              localClientCode !== "null"
            ) {
              setLocalValue(LOCAL_STORAGE_KEYS?.clientCode, localClientCode);
            } else {
              setLocalValue(LOCAL_STORAGE_KEYS?.clientCode, "");
            }
            if (
              companyName &&
              companyName !== null &&
              companyName !== "null"
            ) {
              setLocalValue(LOCAL_STORAGE_KEYS?.companyName, companyName);
            } else {
              setLocalValue(LOCAL_STORAGE_KEYS?.companyName, "");
            }
            setLocalValue(LOCAL_STORAGE_KEYS?.profileImage,avatar)
            setLocalValue(LOCAL_STORAGE_KEYS?.userName, userName);
            window.location = "/";
          } else {
            setCredError(true);
          }
        } catch (error) {
          console.error("Error posting data:", error);
        }
      },
    });
useEffect(()=>{
 const msg= getLocalValue(LOCAL_STORAGE_KEYS?.logoutMessage)||"";
 setMessagePopup(msg);
 if(msg){
  localStorage.removeItem(LOCAL_STORAGE_KEYS?.logoutMessage)
 }


},[])
const onCloseMessage = () => {
  setMessagePopup("")
}
  return (
    <div className="p-0 md:p-[34px] w-full  ">
      <div className="login-page relative">
        <div className="absolute top-10 left-10">
          <Link to={"/"}>
            <img
              className="w-[250px]"
              src="/assets/image/Logo.png"
              alt="logo"
            />
          </Link>
        </div>

        <div className="flex items-center justify-center lg:justify-start h-full">
          <form
            onSubmit={handleSubmit}
            className="flex items-center justify-center lg:inline-block"
          >
            <div className="login-wrapper">
              <div className="login-card px-6 pt-6 pb-[22px]">
                <div className="login-card-header border-bottom-light pb-5">
                  <h4 className=" text-center">Log In Details</h4>
                </div>

                <div className="login-card-body pt-5 ">
                  {credError ? (
                    <div
                      className={`cred-error p-[14px] flex items-center justify-start gap-2 mb-4`}
                    >
                      <TbAlertCircle className="inline-block cred-icon" />
                      Invalid Email or Password
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="login-item flex items-start justify-center flex-col gap-[7px] relative">
                    <label htmlFor="username">Username or Email*</label>
                    <input
                      type="text"
                      className={`w-full ${
                        touched.username && errors.username ? "error" : ""
                      }`}
                      name="username"
                      id="username"
                      placeholder="User Name"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {
                      <p className="form-error">
                        {touched.username && errors.username}
                      </p>
                    }
                  </div>
                  <div className="login-item pt-6 flex items-start justify-center flex-col gap-[7px] relative">
                    <label htmlFor="password">Password*</label>
                    <input
                      type="password"
                      className={`w-full  ${
                        touched.password && errors.password ? "error" : ""
                      }`}
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {
                      <p className="form-error">
                        {touched.password && errors.password}
                      </p>
                    }
                  </div>
                  <div className="text-center pt-6 ">
                    <Link
                      to={"/forgetpassword"}
                      className="forget-password"
                    >{`I’ve forgotten my username or password`}</Link>
                  </div>
                </div>
              </div>
              <button type="submit" className="login-button mt-[18px] w-full">
                Login
              </button>
            </div>

            <div className="image_enter">
              <img
                src={loginBg}
                style={{ width: "700px" }}
                alt="missingLogoBg"
              ></img>
            </div>
          </form>
        </div>
      </div>
      <PopupMessage visible={messagePopup} onClose={onCloseMessage} heading={"Logout"} />
    </div>
    
  );
}
