export const fieldToApiMap = {
    originPort: 'origin',
    Mode: 'mode',
    shipper: 'consignor_name',
    consignee: 'consignee_name',
    destinationPort: 'destination_country_region',
    ETDOriginPort: 'origin_etd',
    Weight: 'weight',
    Volume: 'volume',
    MainVessel: 'vessel',
    CurrentVoy: 'flight_voyage',
    'Container Numbers': 'container_count',
    MainVoy: '',
    EstimatedPickup: 'etd_load',
    ActualPickup: 'actual_pickup',
    JobNotes: 'job_status',
    AdditionalTerms: 'additional_terms',
    EstimatedDelivery: '',
    LastMilestone: '',
    NextMilestoneDesc: '',
    Quantity: '',
    'Goods Description': 'goods_description',
    'Delivery Destination': 'destination',
  };