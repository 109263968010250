import React, { useState, useEffect } from "react";
import Widget from "../components/common/widget";
import "../scss/pages/shipmentsdetails.scss";
import PopupUploads from "../components/common/popup-Uploads";
import PopupDownload from "../components/common/popup-Download";
import { useFormik } from "formik";
import { Axios } from "./config";
import OrderDetails from "../components/shipmentDetails/orderDetails";
import ShipmentDetails from "../components/shipmentDetails/shipmentDetails";
import DeliveryDetails from "../components/shipmentDetails/deliveryDetails";
import GoodsDetails from "../components/shipmentDetails/goodsDetails";
import { useParams } from "react-router-dom";
import Layout from "../components/common/layout";
import CircularProgress from "@mui/material/CircularProgress";
import PopupMessage from "../components/common/popup-message";
import {
  LOCAL_CLIENT_CODE,
  token,
  allowedDocumentsTypes,
  documentTypes,
} from "../common/constants";
import Tracking from "../components/shipmentDetails/tracking";
import ContainerDetails from "../components/shipmentDetails/containerDetails";
import { IS_CONTAINER_PAGE_VISIBLE } from "../common/constants";
export default function ShipmentsDetails() {
  const [modalupload, setModalupload] = useState(false);
  const [modaldownload, setModaldownload] = useState(false);
  const [dataApi, setDataApi] = useState({});
  const [trackingData, setTrackingData] = useState([]);
  const [isTracking, setTracking] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [loadingcenter, setLoadingcenter] = useState(true);
  const [SubmitMessage, setSubmitMessage] = useState("");
  const [attachedDocuments, setAttachedDocuments] = useState([]);
  const [IsAttachDocLoading, setAttachDocloading] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [polyline, setPolyLine] = useState({ sea: [] });
  const [isError, setError] = useState(false);
  const [dbData, setDbData] = useState(null);
  const [showDbData, setShowDbData] = useState(false);
  const [isUploading, setUploading] = useState(false);

  const onClosedownload = () => {
    setModaldownload(false);
  };
  const toggleupload = (cb,avoidToggle=false) => {
    if(!avoidToggle){
  
      setModalupload(!modalupload);
    }
    
    if (cb) {
      cb();
    }
    setUploadMessage("");
  };

  const handleOpendownload = () => {
    setModaldownload(true);
    fetchAttachDocs();
  };
  const initialValues = {
    textfield: "",
  };

  const handleUpload = async (receivedFile, cb,docType) => {
    setUploading(true);
  const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
   const doc= documentTypes.find(((item)=>item.value===docType));
    formdata.append("event_type", doc.value);
    formdata.append("event_reference",doc.value);
    formdata.append("attachment", receivedFile);
    formdata.append("code",doc.value);
    formdata.append("description",doc.label);
    formdata.append("shipmentId", shipmentId);
   

    try {
      await Axios.post(`/shipment/upload-document`, formdata);
      if(cb){
        cb()
      }
      setUploadMessage("Your file has successfully been added");
    } catch (error) {
      console.error("Error:", error);
      setUploadMessage(error.message||"Something went wrong");
      // Show error message to the user
    } finally {
      setUploading(false);
    }
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;

  //submit api.
  const onSubmit = async (values, { resetForm }) => {
    try {
      await Axios.post(`/form-entry/submit`, {
        user_id: 1,
        message: values.textfield,
        reference_id: `${
          Array.isArray(
            responseApi?.DataContext?.DataSourceCollection?.DataSource
          )
            ? responseApi?.DataContext?.DataSourceCollection?.DataSource[1]?.Key
            : responseApi?.DataContext?.DataSourceCollection?.DataSource?.Key
        }`,
        module: "Shipments",
      });
      resetForm();
      setSubmitMessage("Your data has been submitted successfully");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  let { userId } = useParams();
  let shipmentId = userId;
  // shipment-list Api..
  const fetchData = async () => {
    try {
      const shipmentResponse = await Axios.get(
        `/shipment/${shipmentId}?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      const shipmentData = await shipmentResponse;
      const parsedData = JSON.parse(shipmentData.json);
      const data = {
        dates: shipmentData?.dates,
        orderDetails: shipmentData?.order_details,
        shipmentDetails: shipmentData?.shipment_details,
        goodsDetails: shipmentData?.goods_details,
        ...parsedData,
      };
      setDataApi(data);
      const isSeaMode =
        parsedData?.Data?.UniversalShipment?.Shipment?.ContainerCount > 0;
      fetchTrackingData(isSeaMode);
      setError(shipmentData?.status === "error");
      setShowDbData(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingcenter(false); // Stop loader in case of an error
    }
  };
  const fetchDbData = async () => {
    try {
      const databaseResponse = await Axios.get(
        `/shipment/db/${shipmentId}?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      const databaseData = databaseResponse;
      setDbData(databaseData);
      setShowDbData(true);
      // const isSeaMode = databaseData?.shipment_details?.container_count > 0;
      // fetchTrackingData(isSeaMode);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingcenter(false);
    }
  };

  const fetchTrackingData = async (isSeaMode) => {
    try {
      setTracking(true);
      if (isSeaMode) {
        const trackingResponse = await Axios.get(`/tracking/${shipmentId}`);
        const marks = trackingResponse?.locations
        .filter(item => typeof Number(item?.lat) === 'number' && typeof Number(item?.lng) === 'number')
        .map((item) => ({
          id: item?.id,
          name: item?.name,
          position: { lat: Number(item.lat), lng: Number(item.lng) },
        }));
        let mergedPath = [];
        trackingResponse?.searatesRoute.forEach(route => {
            mergedPath = mergedPath.concat(route.path);
        });
        const currentPath=trackingResponse?.searatesCurrentLocation||[];
        const indexOfMatch = mergedPath.findIndex(point => point[0] === currentPath[0] && point[1] === currentPath[1]);
      
        setTrackingData(trackingResponse||{});

        if (indexOfMatch !== -1) {
          const dottedPath = mergedPath.slice(0, indexOfMatch + 1);
          const solidPath = mergedPath.slice(indexOfMatch);

          setPolyLine({ sea:dottedPath,currentSea:solidPath });
      } else {
          console.log('Point not found in mergedPath');
          setPolyLine({ sea:[],currentSea:mergedPath });
      }

    
        setMarkers(marks);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTracking(false);
    }
  };

  const [isExporting, setExporting] = useState(false);
  // export csv file api

  const fetchExcelData = async () => {
    setExporting(true);
    const apiUrl = `/export-shipment`;

    try {
      const response = await Axios.get(`${apiUrl}?shipmentId=${shipmentId}`, {
        responseType: "arraybuffer",
      });
      const blob = new Blob([response], {
        type: "application/vnd.ms-excel",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${shipmentId}-shipmentDetails.xlsx`;
      link.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setExporting(false);
    }
  };
  const fetchAttachDocs = async () => {
    try {
      setAttachDocloading(true);
      const apiUrl = `/shipment/get-list-of-attached-documents/${shipmentId}`;
      const response = await Axios.get(`${apiUrl}`, {
        timeout: 60000,
      });
      const data = response;
      const docs = data.flat();
      const attachDocs = Array.isArray(docs) ? docs : [docs];
      const isPublishedDocs = attachDocs.filter(
        (item) =>
          (item.IsPublished === true || item.IsPublished === "true") &&
          allowedDocumentsTypes.includes(item?.Type?.Code)
      );
      setAttachedDocuments(isPublishedDocs);
    } catch (error) {
      console.error(error);
    } finally {
      setAttachDocloading(false);
    }
  };

  useEffect(() => {
    fetchDbData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function downloadBase64File(base64Content, fileName) {
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blobUrl;
    a.download = fileName; // Specify the desired file name

    document.body.appendChild(a);
    a.click();

    // Cleanup: remove the temporary link and revoke the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  }

  const onCloseMessage = () => {
    setSubmitMessage("");
  };

  function downloadAllFiles() {
    attachedDocuments.forEach((file) => {
      downloadBase64File(file.ImageData, file.FileName);
    });
  }
  return (
    <div>
      <Layout />
      <div className="mt-[185px] sm:mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper details md:pt-[26px] md:pr-9 md:pb-[48px] md:pl-[26px]">
        {loadingcenter ? (
          <div className="text-center loader loader-height">
            <CircularProgress />
          </div>
        ) : isError ? (
          <>
            <div
              className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100"
              role="alert"
            >
              <span className="font-medium">There was an unknown error!</span>{" "}
              The site administrator has been automatically contacted. Please
              try again later
            </div>
          </>
        ) : (
          <>
            <section className="daily-update">
              <div className="card mb-[22px] px-4 md:px-6 pt-[14px] md:pt-[22px] pb-[17px] md:pb-[20px]">
                <div className="card-heading pb-[11px] md:pb-[18px]">
                  <h4 className="heading">Files</h4>
                </div>
                <div className="card-body pt-[14px] md:pt-[24px]">
                  <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3 md:gap-[30px]">
                    <Widget
                      color={`primary`}
                      heading={`UPLOAD`}
                      subHeading={`file for for ICL review`}
                      iconClass={`upload-cloud.svg`}
                      iconClassHover={"upload-cloud-hover.svg"}
                      handleOpen={toggleupload}
                      hover={true}
                      rowReverse={true}
                    />
                    {/* <Widget
                      color={`primary`}
                      heading={isExporting ? "EXPORTING..." : `EXPORT`}
                      subHeading={`shipment data as CSV`}
                      iconClass={`download.svg`}
                      iconClassHover={`download-hover.svg`}
                      hover={true}
                      rowReverse={true}
                      handleOpen={() => fetchExcelData()}
                    /> */}
                    <Widget
                      color={`primary`}
                      heading={`DOWNLOAD`}
                      subHeading={`files related to this shipment`}
                      iconClass={`file-export.svg`}
                      iconClassHover={`file-export-hover.svg`}
                      handleOpen={handleOpendownload}
                      hover={true}
                      rowReverse={true}
                    />
                  </div>
                </div>
              </div>
            </section>

            <div>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                <div>
                  <OrderDetails
                    dataApi={dataApi}
                    dbData={dbData}
                    showDbData={showDbData}
                  />
                  <ShipmentDetails
                    dataApi={dataApi}
                    dbData={dbData}
                    showDbData={showDbData}
                  />
                  <DeliveryDetails
                    dataApi={dataApi}
                    dbData={dbData}
                    showDbData={showDbData}
                  />
                  <GoodsDetails
                    dataApi={dataApi}
                    dbData={dbData}
                    showDbData={showDbData}
                  />
                </div>
                <div>
                  {dataApi?.Data?.UniversalShipment?.Shipment?.ContainerCount >
                    0 && (
                    <Tracking
                      dataApi={dataApi}
                      trackingEvents={trackingData}
                      markers={markers}
                      points={polyline}
                      isTracking={isTracking}
                    />
                  )}

                  <div className="mt-[25px] md:mt-0 bg-white support">
                    <div className="support-heading pb-[14px] md:pb-[15px]">
                      <h4>Support</h4>
                      <p className="max-w-[241px] sm:max-w-none">
                        Leave a message regarding your shipment, and one of our
                        team will be in touch.
                      </p>
                    </div>
                    <div className="pt-[21px] md:pt-4">
                      <form
                        className="w-full flex items-center justify-center flex-col"
                        onSubmit={formik.handleSubmit}
                      >
                        <textarea
                          name="textfield"
                          id=""
                          className="support-textfield"
                          placeholder="Your Message…"
                          value={formik.values.textfield}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        <button
                          type="submit"
                          className="support-btn mt-[17px] md:mt-3"
                        >
                          Submit
                        </button>
                        <p>{SubmitMessage}</p>
                      </form>
                    </div>
                  </div>
                </div>
        
              </div>
            </div>
          </>
        )}
        {IS_CONTAINER_PAGE_VISIBLE&&(
          <ContainerDetails
                      dataApi={dataApi}
                      dbData={dbData}
                      showDbData={showDbData}
                  
                  />
        )}
                
      </div>

      <PopupUploads
        visible={modalupload}
        onClose={toggleupload}
        handleUpload={handleUpload}
        uploadMessage={uploadMessage}
        isUploading={isUploading}
      />
      <PopupDownload
        visible={modaldownload}
        onClose={onClosedownload}
        handleDownloadClick={downloadBase64File}
        handleDownloadAll={downloadAllFiles}
        loading={IsAttachDocLoading}
        documents={attachedDocuments}
      />
      <PopupMessage
        visible={SubmitMessage}
        onClose={onCloseMessage}
        heading={"Submit Data"}
      />
    </div>
  );
}
