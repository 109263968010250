import React from "react";
import { useState } from "react";
import { ENTRY_STATUS_OPTIONS } from "../../common/constants";
export default function ShipmentDetails({ dataApi }) {
  const [shipmentDetails, setshipmentDetails] = useState(true);

  const toggleAccordionShipments = () => {
    setshipmentDetails(!shipmentDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-5 md:pb-[30px] mt-7">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionShipments}
        >
          <h4 className="font-semibold">Shipment Details</h4>
          <img
            className={`transition-transform transform ${
              shipmentDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          shipmentDetails ? "max-h-[1000px]" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className=" grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] md:gap-y-[33px] 3xl:gap-x-[10px]">
              <div className="item">
                <h6 className="item-heading">Transport Mode</h6>
                <div className="item-content">
                  {responseApi?.TransportMode?.Description ?? "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Declarants Ref</h6>
                <div className="item-content">
                  {typeof responseApi?.OwnerRef === "string"
                    ? responseApi?.OwnerRef
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Supplier</h6>
                <div className="item-content">
                  {typeof responseApi?.CommercialInfo
                    ?.CommercialInvoiceCollection?.CommercialInvoice?.Supplier
                    ?.CompanyName === "string"
                    ? responseApi?.CommercialInfo?.CommercialInvoiceCollection
                        ?.CommercialInvoice?.Supplier?.CompanyName
                    : Array.isArray(
                        responseApi?.OrganizationAddressCollection
                          ?.OrganizationAddress
                      ) &&
                      typeof responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                        (item) =>
                          item?.AddressType === "SupplierDocumentaryAddress"
                      )?.CompanyName === "string"
                    ? responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                        (item) =>
                          item.AddressType === "SupplierDocumentaryAddress"
                      )?.CompanyName
                    : "NA"}
                  (
                  {typeof responseApi?.CommercialInfo
                    ?.CommercialInvoiceCollection?.CommercialInvoice?.Supplier
                    ?.AddressShortCode === "string"
                    ? responseApi?.CommercialInfo?.CommercialInvoiceCollection
                        ?.CommercialInvoice?.Supplier?.AddressShortCode
                    : Array.isArray(
                        responseApi?.OrganizationAddressCollection
                          ?.OrganizationAddress
                      ) &&
                      typeof responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                        (item) =>
                          item?.AddressType === "SupplierDocumentaryAddress"
                      )?.AddressShortCode == "string"
                    ? responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                        (item) =>
                          item.AddressType === "SupplierDocumentaryAddress"
                      )?.AddressShortCode
                    : "NA"}
                  )
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Vessel</h6>
                <div className="item-content">
                  {responseApi?.TransportMode?.Code === "SEA" ? (
                    <>
                      {responseApi?.VesselName &&
                      Object?.keys(responseApi?.VesselName)?.length > 0
                        ? responseApi?.VesselName
                        : "NA"}
                    </>
                  ) : (
                    <>NA</>
                  )}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Voyage/Flight</h6>
                <div className="item-content">
                  {responseApi?.TransportMode?.Code !== "ROA" ? (
                    <>
                      {responseApi?.VoyageFlightNo &&
                      Object.keys(responseApi?.VoyageFlightNo)?.length > 0
                        ? responseApi?.VoyageFlightNo
                        : "NA"}
                    </>
                  ) : (
                    <>NA</>
                  )}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Entry Number</h6>
                <div className="item-content">
                  {responseApi &&
                    responseApi?.EntryHeaderCollection?.EntryHeader
                      ?.EntryNumberCollection.EntryNumber?.[1]?.Number}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Entry Status</h6>
                <div className="item-content">
                  {responseApi?.EntryStatus?.Code &&
                  Object.keys(responseApi?.EntryStatus.Code)?.length > 0
                    ? ENTRY_STATUS_OPTIONS.find(
                        (item) => item.value === responseApi?.EntryStatus?.Code
                      )?.name || responseApi?.EntryStatus?.Code
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Entry Status Desc.</h6>
                <div className="item-content">
                  {responseApi && (responseApi?.EntryStatus?.Description||"NA")}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Container Mode</h6>
                <div className="item-content">
                  {responseApi?.CustomsContainerMode?.Description || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Master Bill</h6>
                <div className="item-content">
                  {" "}
                  {responseApi &&
                    responseApi?.EntryHeaderCollection?.EntryHeader
                      ?.EntryNumberCollection?.EntryNumber?.[1]?.Number}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">House Bill</h6>
                <div className="item-content">
                  {responseApi?.WayBillNumber || "NA"}
                </div>
              </div>
              {/* <div className="item">
                <h6 className="item-heading">TEU</h6>
                <div className="item-content">
                  {dataApi && responseApi?.TotalWeight / 20}{" "}
                </div>
              </div> */}
              <div className="item">
                <h6 className="item-heading">Date Created</h6>
                <div className="item-content">NA</div>
              </div>
              <div className="item">
                <h6 className="item-heading">Importer</h6>
                <div className="item-content">
                  {Array.isArray(
                    responseApi?.OrganizationAddressCollection
                      ?.OrganizationAddress
                  ) &&
                  responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                    (item) => item?.AddressType === "ImporterDocumentaryAddress"
                  )?.OrganizationCode === "string"
                    ? responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                        (item) =>
                          item.AddressType === "ImporterDocumentaryAddress"
                      )?.OrganizationCode
                    : "NA"}
                  (
                  {Array.isArray(
                    responseApi?.OrganizationAddressCollection
                      ?.OrganizationAddress
                  ) &&
                  typeof responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                    (item) => item?.AddressType === "ImporterDocumentaryAddress"
                  )?.AddressShortCode == "string"
                    ? responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                        (item) =>
                          item.AddressType === "ImporterDocumentaryAddress"
                      )?.AddressShortCode
                    : "NA"}
                  )
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
