import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../../scss/sidebar.scss";
import { BiLogOut } from "react-icons/bi";
import Cookies from "js-cookie";
import { logoutReset } from "../../common/utils";
import { IS_CONTAINER_PAGE_VISIBLE } from "../../common/constants";

export default function Sidebar({ isSidebarOpen, toggleSidebar }) {
  const [quotes, setQuotes] = useState(false);
  const location = useLocation();
  const toggleQuotes = () => {
    setQuotes(!quotes);
  };
  const userLogout = () => {
   logoutReset();
    Cookies.remove("customColumn");
    Cookies.remove("customColumnCustom");
    Cookies.remove("customColumnBooking");
    window.location = "/login";
  };

  const sidebardata = [
    { name: "Dashboard", link: "/dashboard", iconclass: "dashboard.svg" },
    { name: "Bookings", link: "/bookings", iconclass: "calendar.svg" },
    { name: "Shipments", link: "/shipments", iconclass: "ship-white.svg" },
    ...(IS_CONTAINER_PAGE_VISIBLE ? [{ name: "Containers", link: "/containers", iconclass: "container.svg" }] : []),
    { name: "Customs", link: "/customs", iconclass: "awesome-check-2.svg" },

  ];

  return (
    <div
      className={`pt-[167px] md:pt-[89px] pr-[21px] pl-[18px] sidebar md:bg-transparent w-[307px] flex justify-between h-[100vh] flex-col fixed top-0 md:left-0  ${
        isSidebarOpen ? "left-0" : "-left-[307px]"
      }`}
    >
      <ul className="pt-[26px] sidbar-list">
        {sidebardata.map((item, index) => {
          return (
            <NavLink to={item.link} key={index} onClick={toggleSidebar}>
              <li
                className={`flex items-center justify-start p-2 gap-[9px] mb-5 sidebar-list-item rounded-md`}
              >
                <div className="w-[31px] h-[31px] icon-wrapper flex items-center justify-center bg-img">
                  <img
                    src={`/assets/icons/${item.iconclass}`}
                    alt={`${item.name}`}
                  />
                </div>
                <h4 className="sidebar-item-text">{item.name} </h4>
              </li>
            </NavLink>
          );
        })}
        <div
          className={`${
            location.pathname === "/quotes/newquotes/NEW%20QUOTES" ||
            location.pathname === "/quotes/yourquotes/YOUR%20QUOTES"
              ? "active"
              : ""
          }`}
        >
          <li
            className={`flex items-center justify-start p-2 gap-[9px] sidebar-list-item rounded-md cursor-pointer`}
            onClick={toggleQuotes}
          >
            <div className="w-[31px] h-[31px] icon-wrapper flex items-center justify-center bg-img flex-none">
              <img src="/assets/icons/price-tag-3.svg" alt="quotes" />
            </div>
            <div className="flex items-center justify-between w-full text-left">
              <h4 className="sidebar-item-text">Quotes</h4>
              <img
                className={`transition-transform transform ${
                  quotes ? "rotate-180" : ""
                }`}
                src="/assets/icons/sidebar-dropdown.svg"
                alt=""
              />
            </div>
          </li>

          <div
            className={`overflow-hidden transition-max-height ease-in-out duration-300 ${
              quotes ? "max-h-96" : "max-h-0"
            }`}
          >
            <ul className="">
              <NavLink to="/quotes/newquotes/NEW QUOTES" onClick={toggleQuotes}>
                <li
                  className={`pl-12 sidebar-list-sub-item rounded-md mt-[14px]`}
                >
                  <h4 className="sidebar-item-text">New Quote</h4>
                </li>
              </NavLink>
              <NavLink
                to="/quotes/yourquotes/YOUR QUOTES"
                onClick={toggleQuotes}
              >
                <li
                  className={`pl-12 sidebar-list-sub-item rounded-md mt-[10px]`}
                >
                  <h4 className="sidebar-item-text">Your Quotes</h4>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>

        <NavLink to={"/tracking"}  onClick={toggleSidebar}>
              <li
                className={`flex items-center justify-start p-2 gap-[9px] mt-5 sidebar-list-item rounded-md`}
              >
                <div className="w-[31px] h-[31px] icon-wrapper flex items-center justify-center bg-img">
                  <img
                    src={`/assets/icons/location.svg`}
                    alt={`Tracking`}
                  />
                </div>
                <h4 className="sidebar-item-text">{"Tracking"} </h4>
              </li>
            </NavLink>

      </ul>
      <ul className="sidbar-list mb-8">
        <NavLink to="/settings">
          <li className="btn-account flex items-center sidebar-list-item-profile justify-start rounded-md gap-4 pl-[12px] ">
            <div className="text-primary">
              <img
                className="img-primary"
                src="/assets/icons/settings.svg"
                alt="settings"
              />
              <img
                className="img-darkblue"
                src="/assets/icons/settings-darkblue.svg"
                alt="settings-darkblue"
              />
            </div>
            <p className="sidebar-item-text">Account Settings</p>
          </li>
        </NavLink>
        <li
          onClick={userLogout}
          className="btn-logout flex items-center sidebar-list-item-profile justify-start mt-[18px] rounded-md gap-4 pl-[12px] cursor-pointer"
        >
          <div className="text-primary">
            <BiLogOut className="font-size-20 btn-logout-icon" />
          </div>
          <p className="sidebar-item-text">Logout</p>
        </li>
      </ul>
    </div>
  );
}
