import * as React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { useState, useEffect } from "react";
import { Axios } from "../../pages/config";
import { LOCAL_CLIENT_CODE } from "../../common/constants";

const ActiveShipments = () => {
  const [charts, setCharts] = useState([]);
  const fetchDataFromAPI = async () => {
    try {
      const response = await Axios.get(
        `/dashboard-chart?local_client_code=${LOCAL_CLIENT_CODE}`,
      );
      setCharts(response.dataPoints);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  const options = {
    animationEnabled: true,
    height: 306,
    data: [
      {
        type: "doughnut",
        horizontalAlign: "center",
        showInLegend: false,
        fontweight: "900",
        radius: "100%",
        innerRadius: "95%",
        yValueFormatString: "#,###",
        dataPoints: charts?.filter((item) => item.name !== "" && item.y !== 0),
      },
    ],
  };

  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  return (
    <>
      <div className="card pt-[31px] md:pt-[22px] pl-[15px] md:pl-[21px] pb-[15px] md:pb-[23px] pr-[16px] md:pr-[28px] mb-[25px] md:mb-[28px] ">
        <div className="card-heading pb-[18px] pl-[5px]">
          <div className="">
            <h4 className="heading">Active Shipments</h4>
          </div>
        </div>

        <div className="card-body pt-9 md:pt-[37px]">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:gap-[8px] 2xl:gap-[30px]">
            <div className="canvas-chart-wrapper relative">
              <div className="canvas-chart-title w-full h-full flex items-center justify-center absolute z-[100]">
                <div className="text-center flex items-center justify-center flex-col gap-1">
                  {charts?.map((item, index) => {
                    if (index === 0) {
                      return (
                        <div key={item.id} className="canvas-chart-title-count">
                          {item.y}
                        </div>
                      );
                    }
                    return null;
                  })}

                  <div className="canvas-chart-title-text">
                    Active Shipments{" "}
                  </div>
                </div>
              </div>
              <CanvasJSChart options={options} />
            </div>
            <ul className="list-wrapper pl-[6px] md:pl-0 pr-[6px]">
              {charts?.map((element) => {
                if (element.name !== "") {
                  return (
                    <li key={element.name}>
                      <div
                        style={{ backgroundColor: `${element.color}` }}
                        className={`circle flex-none`}
                      ></div>
                      <div className="text text-darkblue w-full">
                        {element.name === "All Shipments" ? (
                          <a
                            // href="/eventShipments/active-shipments"
                            className="flex items-center justify-between w-full"
                          >
                            <div>
                              {element.name}:&nbsp;
                              <span className="text-strong">{element.y}</span>
                            </div>

                            {/* <img
                              className="block"
                              src="assets/icons/right-arrow.svg"
                              alt="right-arrow"
                            /> */}
                          </a>
                        ) : element.name === "At Origin Port" ? (
                          <a
                            href="/eventBookings/at-origin-port-shipments/"
                            className="flex items-center justify-between w-full"
                          >
                            <div>
                              {element.name}:&nbsp;
                              <span className="text-strong">{element.y}</span>
                            </div>

                            <img
                              className="block"
                              src="assets/icons/right-arrow.svg"
                              alt="right-arrow"
                            />
                          </a>
                        ) : element.name === "In Transit" ? (
                          <a href="/eventShipments/in-transit-shipments/"   className="flex items-center justify-between w-full">
                            <div>
                            {element.name}:&nbsp;
                            <span className="text-strong">{element.y}</span>
                            </div>
                            <img
                              className="block"
                              src="assets/icons/right-arrow.svg"
                              alt="right-arrow"
                            />
                          </a>
                        ) : element.name === "At Destination Port" ? (
                          <a href="/eventShipments/at-destination-port-shipments" className="flex items-center justify-between w-full">
                            <div>
                            {element.name}:&nbsp;
                            <span className="text-strong">{element.y}</span>
                            </div>
                            <img
                              className="block"
                              src="assets/icons/right-arrow.svg"
                              alt="right-arrow"
                            />
                          </a>
                        ) : element.name === "Gate Out" ? (
                          <a href="/eventShipments/gate-out-shipments">
                            {element.name}:&nbsp;
                            <span className="text-strong">{element.y}</span>
                          </a>
                        ) : (
                          <div className="text text-darkblue">
                            {element.name}:&nbsp;
                            <span className="text-strong">{element.y}</span>
                          </div>
                        )}
                      </div>
                      {/* <img
                        className="ml-auto"
                        src="assets/icons/right-arrow.svg"
                        alt="right-arrow"
                      /> */}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveShipments;
