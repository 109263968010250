import React, { useState, useEffect } from "react";
import "../scss/pages/shipmentsdetails.scss";
import { useFormik } from "formik";
import { Axios } from "./config";
import ContainerDetail from "../components/containerDetails/containerDetails";
import DeliveryDetails from "../components/containerDetails/deliveryDetails";
import { useParams } from "react-router-dom";
import Layout from "../components/common/layout";
import CircularProgress from "@mui/material/CircularProgress";
import { LOCAL_CLIENT_CODE } from "../common/constants";

export default function ContainerDetails() {
  const [dataApi, setDataApi] = useState({});
  const [loadingcenter, setLoadingcenter] = useState(true);
  const [SubmitMessage, setSubmitMessage] = useState("");
  const [isError, setError] = useState(false);
  const [dbData] = useState(null);
  const [showDbData, setShowDbData] = useState(false);

  const initialValues = {
    textfield: "",
  };


  //submit api.
  const onSubmit = async (values, { resetForm }) => {
    try {
      await Axios.post(`/form-entry/submit`, {
        user_id: 1,
        message: values.textfield,
        reference_id: `${containerId
        }`,
        module: "Containers",
      });
      resetForm();
      setSubmitMessage("Your data has been submitted successfully");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  let { containerId } = useParams();
  // shipment-list Api..
  const fetchData = async () => {
    try {
      setLoadingcenter(true);
      const containerResponse = await Axios.get(
        `/container/${containerId}?local_client_code=${LOCAL_CLIENT_CODE}`
      );
      const containerData = containerResponse;
      const parsedData = JSON.parse(containerData.json);
      const data = {
       container:containerData?.container,
        ...parsedData,
      };
      setDataApi(data);
      setError(containerData?.status === "error");
      setShowDbData(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingcenter(false); // Stop loader in case of an error
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      <Layout />
      <div className="mt-[185px] sm:mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper details md:pt-[26px] md:pr-9 md:pb-[48px] md:pl-[26px]">
        {loadingcenter ? (
          <div className="text-center loader loader-height">
            <CircularProgress />
          </div>
        ) : isError ? (
          <>
            <div
              className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100"
              role="alert"
            >
              <span className="font-medium">There was an unknown error!</span>{" "}
              The site administrator has been automatically contacted. Please
              try again later
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                <div>
                  <ContainerDetail
                    dataApi={dataApi}
                    dbData={dbData}
                    showDbData={showDbData}
                  />
                  <DeliveryDetails
                    dataApi={dataApi}
                    dbData={dbData}
                    showDbData={showDbData}
                  />
                </div>
                <div>
                  <div className="mt-[25px] bg-white support">
                    <div className="support-heading pb-[14px] md:pb-[15px]">
                      <h4>Support</h4>
                      <p className="max-w-[241px] sm:max-w-none">
                        Leave a message regarding your container, and one of our
                        team will be in touch.
                      </p>
                    </div>
                    <div className="pt-[21px] md:pt-4">
                      <form
                        className="w-full flex items-center justify-center flex-col"
                        onSubmit={formik.handleSubmit}
                      >
                        <textarea
                          name="textfield"
                          id=""
                          className="support-textfield"
                          placeholder="Your Message…"
                          value={formik.values.textfield}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        <button
                          type="submit"
                          className="support-btn mt-[17px] md:mt-3"
                        >
                          Submit
                        </button>
                        <p>{SubmitMessage}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
