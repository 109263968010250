import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { useLoadScript } from "@react-google-maps/api";
import Map from "../Map/Map";
import CircularProgress from "@mui/material/CircularProgress";
import { MAP_KEY } from "../../common/constants";
export default function Tracking({
  dataApi,
  trackingEvents,
  markers = [],
  points = { sea: [],currentSea:[] },
  isTracking=false,
}) {
  const [tracking, settracking] = useState(true);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey:MAP_KEY, // Add your API key
  });
  const [isSubShipmentInfo, setSubShipmentInfo] = useState("");
  const toggleAccordionTracking = () => {
    settracking(!tracking);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;

  useEffect(() => {
    if (responseApi !== null) {
      if (
        responseApi?.hasOwnProperty("SubShipmentCollection") &&
        responseApi?.SubShipmentCollection.hasOwnProperty("SubShipment")
      ) {
        setSubShipmentInfo("enable");
      } else {
        setSubShipmentInfo("disable");
      }
    }
  }, [responseApi]);

  return (
    <div className="tracking-section pt-[19px] pl-[18px] pr-[18px] pb-[19px] mb-7">
      <div className="card-heading pb-4">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionTracking}
        >
          <h4 className="">Tracking</h4>
          <img
            className={`transition-transform transform ${
              tracking ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          tracking ? "max-h-[1200px]" : "max-h-0"
        }`}
      >
        <div className="tracking-section-body pt-[19px]">
          <div className="container-xl">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2 gap-5">
              <div className="flex items-center justify-between py-[14px] pl-[25px] pr-[18px] bg-light-primary rounded-[15px] border-light-1">
                <div>
                  <h5 className="heading text-primary">Pickup Location</h5>
                  <p className="content mt-2">
                    {isSubShipmentInfo === "disable"
                      ? `${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.Country?.Code
                        })` ?? "NA"
                      : `${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsignorPickupDeliveryAddress"
                          )[0]?.Country?.Code
                        })` ?? "NA"}
                  </p>
                </div>
                <img
                  className="w-[22px] flex-none"
                  src="/assets/icons/location-pin-primary.svg"
                  alt="ocation-pin-primary"
                />
              </div>
              <div className="flex items-center justify-between py-[14px] pl-[25px] pr-[18px] bg-light-primary rounded-[15px] border-light-1">
                <div>
                  <h5 className="heading text-success">Delivery Location</h5>
                  <p className="content mt-2">
                    {" "}
                    {(isSubShipmentInfo === "disable"
                      ? `${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.Country?.Name
                        })`
                      : `${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.AddressShortCode
                        } (${
                          responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                            (item) =>
                              item?.AddressType ===
                              "ConsigneePickupDeliveryAddress"
                          )[0]?.Country?.Name
                        })`) || "NA"}
                  </p>
                </div>
                <img
                  className="w-[22px] flex-none"
                  src="/assets/icons/location-pin-success.svg"
                  alt="location-pin"
                />
              </div>
            </div>
          </div>

          {isTracking ? (
            <div className="text-center loader loader-height">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="border-bottom-light py-[19px]">
                <div className="pb-20px h-[306px] w-full relative">
                  {markers?.length > 0 && isLoaded && (
                    <Map
                      markers={markers}
                      height={"306px"}
                      width={"100%"}
                      points={points}
                    />
                  )}
                </div>
              </div>
              <div className="">
                {trackingEvents?.events?.map((item) => {
                  return (
                    <div
                      className="flex items-center justify-start pt-[21px] pb-[19px] md:pl-1 border-bottom-light gap-[22px]"
                      key={item.order_id}
                    >
                      <img
                        src={`${
                          moment(item?.date)?.isBefore(moment())
                            ? "/assets/icons/checkbox-success.svg"
                            : "/assets/icons/timer-success.svg"
                        }`}
                        alt="eventIcon"
                      />

                      <div className="w-full">
                        <div className="flex items-center justify-between">
                          <h5 className="heading text-primary">
                            {item?.description || "NA"}
                          </h5>
                          <h5 className="heading text-primary">
                            {moment(item?.date)?.format("DD-MMM-YYYY HH:mm")}
                          </h5>
                        </div>
                        <div className="flex items-center justify-between mt-1">
                          <p className="content">
                            {trackingEvents?.locations.find(
                              (location) => location.id === item.location
                            )?.name || "NA"}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
