import React, { useState } from "react";
import { useEffect } from "react";
 import { Axios } from "../../pages/config";
 import CircularProgress from "@mui/material/CircularProgress";
 import "./NotificationPage.scss";
 import PopupMessage from '../../components/common/popup-message';
export default function NotificationsPage() {
  const [userData, setUserData] = useState(null);
  const [state, setState] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const fetchDataFromAPI = async () => {
    try {
      const response = await Axios.get(`/user`);
      const apiData = response;
      setUserData(apiData);
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };
  const handleUpdateFunc = async () => {
    const formData = new FormData();
    formData.append("shipments_push_enabled", userData?.shipments_push_enabled);
    formData.append(
      "shipments_email_enabled",
      userData?.shipments_email_enabled
    );
    formData.append(
      "shipments_sms_enabled",
      Number(userData?.shipments_sms_enabled)
    );
    formData.append(
      "bookings_push_enabled",
      Number(userData?.bookings_push_enabled)
    );
    formData.append(
      "bookings_email_enabled",
      Number(userData?.bookings_email_enabled)
    );
    formData.append(
      "bookings_sms_enabled",
      Number(userData?.bookings_sms_enabled)
    );
    formData.append(
      "customs_push_enabled",
      Number(userData?.customs_push_enabled)
    );
    formData.append(
      "customs_email_enabled",
      Number(userData?.customs_email_enabled)
    );
    formData.append(
      "customs_sms_enabled",
      Number(userData?.customs_sms_enabled)
    );
    formData.append(
      "notification_frequency",
     userData?.notification_frequency||"",
    );
    try {
      await Axios.post(`/update-settings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await fetchDataFromAPI();
      setMessagePopup("Notification setting updated successfully");
    } catch (error) {
      console.error("Error updating notification preference:", error);
      setMessagePopup("Something Went Wrong");
    }
    finally{
      setState(false);
    }
  };
  const handleCheckboxChange = async (event, notificationType) => {
    const { name, checked } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: checked ? 1 : 0,
    }));
    setState(true);
  };
  const handleNotification=(e)=>{
    const { name,value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]:value,
    }));
    setState(true);
  }
  useEffect(() => {
    fetchDataFromAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state) {
      handleUpdateFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  const onCloseMessage = () => {
    setMessagePopup("")
}

  return (
    <div className="bg-white notifications-preferences lg:pl-[313px] notification-setting-container" >
      <div className=" pt-[25px] pl-[26px] pb-[22px] pr-[26px]">
        <div className="notifications-heading-wrapper pb-4 flex items-start justify-start flex-col gap-[8px]">
          <h4 className="notifications-heading">Notification settings</h4>
          <p className="notifications-text">
            we may still send you important notifications about your account
            outside of your notification settings
          </p>
        </div>
        {
          state?<div className="text-center loader" > <CircularProgress/> </div>:<div className="conatiner-2xl">
          <div className="grid grid-cols-2 border-bottom-light">
            <div className="notifications-list col-span-2 xl:col-span-1">
              <div className="notifications-item">
                <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                  <div className="col-span-3 sm:col-span-2">
                    <h4>Shipments</h4>
                    <p className="mt-2">
                      These are notifications for shipments when shipments will
                      be created, updated or deleted.
                    </p>
                  </div>
                  <div className="flex items-start sm:items-end  justify-center flex-col gap-4">
                    {/* <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="shipments_push_enabled"
                        checked={userData?.shipments_push_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "shipments_push_enabled")
                        }
                      />
                      <span className="ml-3 ">Push</span>
                    </label> */}
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="shipments_email_enabled"
                        checked={userData?.shipments_email_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "shipments_email_enabled")
                        }
                      />
                      <span className="ml-3 ">Email</span>
                    </label>
                    {/* <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="shipments_sms_enabled"
                        checked={userData?.shipments_sms_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "shipments_sms_enabled")
                        }
                      />
                      <span className="ml-3 ">SMS</span>
                    </label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
         

          <div className="grid grid-cols-2 border-bottom-light">
            <div className="notifications-list col-span-2 xl:col-span-1">
              <div className="notifications-item">
                <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                  <div className="col-span-3 sm:col-span-2">
                    <h4>Bookings</h4>
                    <p className="mt-2">
                      These are notifications for bookings when bookings will be
                      created, updated or deleted.{" "}
                    </p>
                  </div>
                  <div className="flex items-start sm:items-end  justify-center flex-col gap-4">
                    {/* <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="bookings_push_enabled"
                        checked={userData?.bookings_push_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "bookings_push_enabled")
                        }
                      />
                      <span className="ml-3 ">Push</span>
                    </label> */}
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="bookings_email_enabled"
                        checked={userData?.bookings_email_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "bookings_email_enabled")
                        }
                      />
                      <span className="ml-3 ">Email</span>
                    </label>
                    {/* <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="bookings_sms_enabled"
                        checked={userData?.bookings_sms_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "bookings_sms_enabled")
                        }
                      />
                      <span className="ml-3 ">SMS</span>
                    </label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 border-bottom-light">
            <div className="notifications-list col-span-2 xl:col-span-1">
              <div className="notifications-item">
                <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                  <div className="col-span-3 sm:col-span-2">
                    <h4>Custom</h4>
                    <p className="mt-2">
                      These are notifications for custom when custom will be
                      created, updated or deleted.
                    </p>
                  </div>
                  <div className="flex items-start sm:items-end  justify-center flex-col gap-4">
                    {/* <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="customs_push_enabled"
                        checked={userData?.customs_push_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "customs_push_enabled")
                        }
                      />
                      <span className="ml-3 ">Push</span>
                    </label> */}
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="customs_email_enabled"
                        checked={userData?.customs_email_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "customs_email_enabled")
                        }
                      />
                      <span className="ml-3 ">Email</span>
                    </label>
                    {/* <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="customs_sms_enabled"
                        checked={userData?.customs_sms_enabled === 1}
                        onChange={(e) =>
                          handleCheckboxChange(e, "customs_sms_enabled")
                        }
                      />
                      <span className="ml-3 ">SMS</span>
                    </label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 border-bottom-light">
            <div className="notifications-list col-span-2 xl:col-span-1">
              <div className="notifications-item">
                <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                  <div className="col-span-3 sm:col-span-2">
                    <h4>Email notifications</h4>
                    <p className="mt-2">
                      These email notification will be send according to the frequency chosen
                    </p>
                  </div>
                  <div className="flex items-start sm:items-end  justify-center ml-4 flex-col gap-4 ">
                    <label className="d-flex align-items-center justify-content-start radio-input">
                      <input
                        type="radio"
                        name="notification_frequency"
                        className="mr-3"
                        checked={userData?.notification_frequency==="off"}
                        onChange={handleNotification}
                        value={"off"}
                      />
                      <span className="ml-3">Off</span>
                    </label>
                    <label className="d-flex align-items-center justify-content-start  radio-input">
                      <input
                        type="radio"
                        name="notification_frequency"
                        className="mr-3"
                        value={"per_change"}
                        checked={userData?.notification_frequency==="per_change"}
                        onChange={handleNotification}
                      />
                      <span>Per Change</span>
                    </label>
                   
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="grid grid-cols-2 border-bottom-light">
                        <div className='notifications-list col-span-2 xl:col-span-1'>
                            <div className='notifications-item'>
                                <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                                    <div className='col-span-3 sm:col-span-2'>
                                        <h4>More activity about you</h4>
                                        <p className="mt-2">These are notifications for More activity about you when More activity about you will be created, updated or deleted.</p>
                                    </div>
                                    <div className='flex items-start sm:items-end  justify-center flex-col gap-4'>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input type="checkbox" defaultChecked={true} />
                                            <span className="ml-3 ">Push</span>
                                        </label>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input type="checkbox" />
                                            <span className="ml-3 ">Email</span>
                                        </label>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input type="checkbox" />
                                            <span className="ml-3 ">SMS</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
        }
        
      </div>
      <PopupMessage visible={messagePopup} onClose={onCloseMessage} heading={"Notification Settings"} />
    </div>
  );
}
