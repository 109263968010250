import React from "react";
import { useState, useEffect } from "react";

export default function GoodsDetails({ dataApi, showDbData, dbData }) {
  const [goodsDetails, setgoodsDetails] = useState(true);
  const [isSubShipmentInfo, setSubShipmentInfo] = useState("");

  const toggleAccordionGoods = () => {
    setgoodsDetails(!goodsDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  const goodsDetail = dbData?.goods_details;
  
  useEffect(() => {
    if (responseApi !== null) {
      if (
        responseApi?.hasOwnProperty("SubShipmentCollection") &&
        responseApi?.SubShipmentCollection.hasOwnProperty("SubShipment")
      ) {
        setSubShipmentInfo("enable");
      } else {
        setSubShipmentInfo("disable");
      }
    }
  }, [responseApi]);
  const tempTEU = showDbData
  ? goodsDetail?.teu || 0
  : responseApi?.TotalVolume / 38.31;

const TEU = dataApi?.dates?.teu
  ? Math.round(dataApi?.dates?.teu).toFixed(0)
  : typeof tempTEU === "number" && tempTEU !== 0 && tempTEU < 0.5
  ? Math.round(tempTEU).toFixed(0)
  : "NA";
  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[21px] md:pb-[30px] mt-[32px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionGoods}
        >
          <h4 className="font-semibold">Goods Details</h4>
          <img
            className={`transition-transform transform ${
              goodsDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          goodsDetails ? "max-h-[500px]" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] md:gap-y-[33px]">
              <div className="item">
                <h6 className="item-heading">Last Milestone Desc</h6>
                <div className="item-content">
                  {responseApi?.MilestoneCollection?.Milestone[0]
                    ?.Description ?? "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Goods Description</h6>
                <div className="item-content">
                  {showDbData
                    ? goodsDetail?.goods_description || "NA"
                    : isSubShipmentInfo === "disable"
                    ? responseApi?.GoodsDescription &&
                      Object.keys(responseApi?.GoodsDescription).length > 0
                      ? responseApi.GoodsDescription
                      : "NA"
                    : responseApi?.SubShipmentCollection?.SubShipment
                        ?.GoodsDescription &&
                      Object.keys(
                        responseApi?.SubShipmentCollection?.SubShipment
                          ?.GoodsDescription
                      ).length > 0
                    ? responseApi?.SubShipmentCollection?.SubShipment
                        ?.GoodsDescription
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">TEU</h6>
                <div className="item-content">{TEU}</div>
              </div>
              <div className="item">
                <h6 className="item-heading">Incoterm</h6>
                <div className="item-content">
                  {showDbData
                    ? goodsDetail?.incoterm || "NA"
                    : isSubShipmentInfo === "disable"
                    ? responseApi?.ShipmentIncoTerm?.Code &&
                      Object.keys(responseApi?.ShipmentIncoTerm?.Code).length >
                        0
                      ? responseApi.ShipmentIncoTerm.Code
                      : "NA"
                    : responseApi?.SubShipmentCollection?.SubShipment
                        ?.ShipmentIncoTerm?.Code &&
                      Object.keys(
                        responseApi?.SubShipmentCollection?.SubShipment
                          ?.ShipmentIncoTerm?.Code
                      ).length > 0
                    ? responseApi?.SubShipmentCollection?.SubShipment
                        ?.ShipmentIncoTerm?.Code
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Container Mode</h6>
                <div className="item-content">
                  {showDbData
                    ? goodsDetail?.container_mode || "NA"
                    : responseApi?.ContainerMode?.Code ?? "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Container Number</h6>
                <div className="item-content">
                  {Array.isArray(responseApi?.ContainerCollection?.Container)
                    ? responseApi.ContainerCollection.Container.map(
                        (item) => item.ContainerNumber
                      ).join(",")
                    : responseApi?.ContainerCollection?.Container
                        ?.ContainerNumber || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Weight</h6>
                <div className="item-content">
                  {showDbData
                    ? goodsDetail?.weight || "NA"
                    : responseApi?.TotalWeight}

                  {showDbData ? "" : responseApi?.TotalWeightUnit?.Code ?? "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Quantity</h6>
                <div className="item-content">
                  {showDbData
                    ? goodsDetail?.quantity || "NA"
                    :isSubShipmentInfo === "disable"
                    ? responseApi?.PreCarriageShipmentCollection
                        ?.PreCarriageShipment?.InstructionCollection
                        ?.Instruction?.InstructionPackingLineLinkCollection
                        ?.InstructionPackingLineLink?.Quantity ||
                      responseApi?.CommercialInfo?.CommercialInvoiceCollection
                        ?.CommercialInvoice
                        ?.CustomsSupportingInformationCollection
                        ?.CustomsSupportingInformation?.Quantity||
                        responseApi?.OuterPacks?`${responseApi?.OuterPacks} ${responseApi?.OuterPacksPackageType?.Code}`: 
                      "NA"
                    : responseApi?.SubShipmentCollection?.SubShipment
                        ?.PreCarriageShipmentCollection?.PreCarriageShipment
                        ?.InstructionCollection?.Instruction
                        ?.InstructionPackingLineLinkCollection
                        ?.InstructionPackingLineLink?.Quantity ||
                      responseApi?.OuterPacks?`${responseApi?.OuterPacks} ${responseApi?.OuterPacksPackageType?.Code}`:
                      "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Volume</h6>
                <div className="item-content">
                  {showDbData
                    ? goodsDetail?.volume || "NA"
                    : responseApi?.TotalVolume ?? "NA"}

                  {responseApi?.TotalVolumeUnit?.Code.split(
                    ""
                  )[0].toLowerCase()}
                  <sup>
                    {dataApi &&
                      dataApi?.Data?.UniversalShipment?.Shipment?.TotalVolumeUnit?.Code.split(
                        ""
                      )[1]}
                  </sup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
