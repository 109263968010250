import React from 'react'
import { useState } from 'react';

export default function OrderDetails({dataApi}) {
  const [orderDetail, setOrderDetail] = useState(true);
  const toggleAccordionOrder = () => {
    setOrderDetail(!orderDetail);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment

  return (
    <div className='details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[20px] md:pb-[30px]'>
      <div className="card-heading">
        <button className="flex items-center justify-between w-full text-left" onClick={toggleAccordionOrder}>
          <h4 className="font-semibold">Order Details</h4>
          <img className={`transition-transform transform ${orderDetail ? 'rotate-[270deg]' : 'rotate-90'}`} src="/assets/icons/right-arrow.svg" alt="right-arrow-icon" />
        </button>
      </div>
      <div className={`overflow-hidden transition-max-height ease-in-out duration-300 ${orderDetail ? 'max-h-96' : 'max-h-0'}`}>
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 gap-y-[21px] sm:gap-y-4 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-4 3xl:gap-x-[55px]">
              <div className='item'>
                <h6 className='item-heading'>Consignor Name</h6>
                <div className='item-content'>{responseApi?.OrganizationAddressCollection?.OrganizationAddress[0]?.CompanyName??"NA"}</div>
              </div>
              <div className='item'>
                <h6 className='item-heading'>Consignee Name</h6>
                <div className='item-content'>{responseApi?.OrganizationAddressCollection?.OrganizationAddress[2]?.CompanyName??"NA"}</div>
              </div>
              <div className='item'>
                <h6 className='item-heading'>Booking Number</h6>
                <div className='item-content'>{responseApi?.DataContext?.DataSourceCollection.DataSource?.Key ?? "NA"}</div>
              </div>
              <div className='item'>
                <h6 className='item-heading'>Order Ref</h6>
                <div className='item-content'>{responseApi?.LocalProcessing?.OrderNumberCollection?.OrderNumber?.OrderReference||"NA"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
