import React from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import '../scss/pages/shipments.scss';
import DatatablePage from '../components/shipments/list';
import Layout from '../components/common/layout';



function Shipment() {
const {keyword}=useParams();
  const title=keyword?.includes("origin")?"Origin":keyword?.includes("transit")?"Transit":keyword?.includes("destination")?"Destination":keyword?.includes("gate")?"Gate Out":
  keyword?.includes("eta")?"Eta's":keyword?.includes("etd")?"Etd's":"";
  const navigate=useNavigate();
  return (
    <div>
      <Layout/>
      <div className='mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper md:pt-[26px] md:pr-9 md:pb-[27px] md:pl-[26px]'>
        <div className='shipments  bg-white'>
          <div className="shipment-list-header">
            <div className="flex items-center md:justify-start sm:justify-center flex-wrap">
            <h4 className='shipments-heading'>{title} Shipments</h4>
            {keyword?.includes("origin")?<>
            <button
              className="redirect-btn  md:inline-block ml-5 mt-5"
              type="button"
              onClick={() =>
                navigate("/eventBookings/at-origin-port-shipments/")
              }
            >
              Go to Bookings at Origin Port
            </button>
            </>:null}
            </div>
            <p className='shipments-text max-w-[237px] sm:max-w-none sm-pr-0'>Here’s where you keep up with all your {title.toLocaleLowerCase()} shipments.</p>
          </div>
          <DatatablePage keyword={keyword||""}/>
        </div>
       
      </div>
    </div>
  )
}

export default Shipment
