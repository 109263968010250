import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ContainerDetails({ dataApi}) {
  const navigate=useNavigate();
  const [shipmentDetails, setshipmentDetails] = useState(true);
  const toggleAccordionShipments = () => {
    setshipmentDetails(!shipmentDetails);
  };
  const responseApi = dataApi?.Data?.UniversalShipment?.Shipment;

  



  const navigateToShipment = (id) => {
    navigate(`/shipments/${id}`);

    
  };
 
  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-5 md:pb-[30px] mt-[26px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionShipments}
        >
          <h4 className="font-semibold">Container Details</h4>
          <img
            className={`transition-transform transform ${
              shipmentDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          shipmentDetails ? "max-h-[800px]" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] md:gap-y-[33px]  3xl:gap-x-[62px]">
              <div className="item">
                <h6 className="item-heading">Container Number</h6>
                <div className="item-content">
                  {dataApi?.container?.container_number || "NA"}
                </div>
              </div>
              <div className="item cursor" onClick={()=>navigateToShipment(dataApi?.container?.shipment_id)} >
                <h6 className="item-heading">Shipment Number</h6>
                <div className="item-content">
                  {dataApi?.container?.shipment_id || "NA"}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Consol Number</h6>
                <div className="item-content">
                  {dataApi?.container?.consol_id || dataApi?.container?.consol_ref || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">BOL</h6>
                <div className="item-content">
                {typeof responseApi?.SubShipmentCollection?.SubShipment
                        ?.WayBillNumber === "string"
                    ? responseApi?.SubShipmentCollection?.SubShipment
                        ?.WayBillNumber
                    : typeof responseApi?.WayBillNumber === "string"
                    ? responseApi?.WayBillNumber
                    : "NA"}


                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Carrier </h6>
                <div className="item-content">
                  {`${dataApi?.container?.carrier_name ||""} (${dataApi?.container?.carrier_code ||""})`}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Vessel</h6>
                <div className="item-content">
                  {dataApi?.container?.vessel || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Voyage</h6>
                <div className="item-content">
                  {dataApi?.container?.voyage || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Mode</h6>
                <div className="item-content">
                  {dataApi?.container?.container_mode || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">CTR</h6>
                <div className="item-content">
                  {dataApi?.container?.container_type || "NA"}
                </div>
              </div>
              {/* <div className="item">
                <h6 className="item-heading">Order Ref</h6>
                <div className="item-content">
                  {responseApi?.container?.shippers_reference || "NA"}
                </div>
              </div> */}
             
{/* 
              <div className="item">
                <h6 className="item-heading">Seal</h6>
                <div className="item-content">
                  {responseApi?.container?.seal || "NA"}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
