import React, { useState, useEffect, useMemo } from "react";
import "../scss/pages/tracking.scss";
import { BsThreeDots } from "react-icons/bs";
import Layout from "../components/common/layout";
 import { Axios } from "./config";
import { LOCAL_CLIENT_CODE, LOCAL_STORAGE_KEYS, MAP_KEY } from "../common/constants";
import CircularProgress from "@mui/material/CircularProgress";
import TrackingDetail from "../components/trackingDetails/tracking";
import { useLoadScript } from "@react-google-maps/api";
import Map from "../components/Map/Map";
import { convertDateFormat, getLocalValue, setLocalValue } from "../common/utils";
import { useNavigate } from "react-router-dom";
function Tracking() {
  const [keys, setKeys] = useState([]);
  const [shipmentsData, setShipmentData] = useState({ data: [] });
  const [saerchhShipmentsData, setSearchShipmentData] = useState([]);
  const [shipmentDetail, setShipmentDetail] = useState();
  const [trackingData, setTrackingData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [polyline, setPolyLine] = useState({ sea: [],currentSea:[] });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [startPage, setStartPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [pageInfo, setPageInfo] = useState("");
  const [pageLength, setPageLength] = useState("");
  const [currentPageLength, setCurrentPageLength] = useState("");
  const [shipmentId, setShipmentId] = useState("");
  const [detailLoading, setDetailLoading] = useState(false);
  const [shipmentLoading, setShipmentLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    if (page >= 1) {
      const newStartPage = Math.floor((page - 1) / 3) * 3 + 1;
      setLocalValue(LOCAL_STORAGE_KEYS?.trackingListCurrentPage, page);
      setCurrentPage(page);
      setStartPage(newStartPage);
    }
  };
  const fetchDataFromAPI = async () => {
    try {
      const response = await Axios.get(
        `/dashboard-chart?local_client_code=${LOCAL_CLIENT_CODE}`,
      
      );
      setKeys(response.dataPoints);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchShipmentData = async (keyword) => {
    try {
      setShipmentLoading(true);
      let url = `/shipments-by-event?eventType=SEA&per_page=${itemsPerPage}&page=${currentPage}&local_client_code=${LOCAL_CLIENT_CODE}`;
      if (keyword?.trim() !== "") {
        url = `/search-shipment?eventType=SEA&keyword=${keyword}&local_client_code=${LOCAL_CLIENT_CODE}`;
        setShipmentData({ data: [] });
        setCurrentPageLength("");
        setLastPage("");
        setPageInfo("");
        setPageLength("");
      }
      const shipmentRes = await Axios.get(url);
      if (keyword?.trim() !== "") {
        setSearchShipmentData(shipmentRes);
        setShipmentId(shipmentRes?.[0]?.shipment_id || "");
      } else {
        setShipmentData(shipmentRes);
        setLastPage(shipmentRes.last_page);
        setPageInfo(shipmentRes?.total);
        setPageLength(shipmentRes?.to);
        setSearchShipmentData([]);
        setShipmentId(shipmentRes?.data?.[0]?.shipment_id || "");
        const fromPage =
          shipmentRes?.current_page * itemsPerPage - (itemsPerPage - 1);
        setCurrentPageLength(fromPage);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setShipmentLoading(false);
    }
  };
  const fetchShipmentDetails = async (id) => {
    try {
      setDetailLoading(true);
      const response = await Axios.get(
        `/shipment/${id}?local_client_code=${LOCAL_CLIENT_CODE}`
      );

      const shipmentData = response;
      const parsedData = JSON.parse(shipmentData.json);

      const trackingResponse = await Axios.get(
        `/tracking/${id}`
      );
      setTrackingData(trackingResponse||{});
      let mergedPath = [];
      trackingResponse?.searatesRoute.forEach(route => {
          mergedPath = mergedPath.concat(route.path);
      });
      const currentPath=trackingResponse?.searatesCurrentLocation||[];
      const indexOfMatch = mergedPath.findIndex(point => point[0] === currentPath[0] && point[1] === currentPath[1]);
    
      setTrackingData(trackingResponse||{});

      if (indexOfMatch !== -1) {
        const dottedPath = mergedPath.slice(0, indexOfMatch + 1);
        const solidPath = mergedPath.slice(indexOfMatch);

        setPolyLine({ sea:dottedPath,currentSea:solidPath });
    } else {
        console.log('Point not found in mergedPath');
        setPolyLine({ sea:[],currentSea:mergedPath });
    }
      const marks = trackingResponse?.locations?.map((item) => {
        return {
          id: item?.id,
          name: item?.name,
          position: { lat: item?.lat, lng: item?.lng },
        };
      })||[];
      setShipmentDetail({ ...parsedData, dates: shipmentData?.dates });
      setMarkers(marks);
    } catch (error) {
      console.error("Error:", error);
      setMarkers({});
      setTrackingData({})
    } finally {
      setDetailLoading(false);
    }
  };
  useMemo(() => {
    const savedPage =getLocalValue(LOCAL_STORAGE_KEYS?.trackingListCurrentPage)
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10));
      setStartPage(Math.floor((parseInt(savedPage, 10) - 1) / 3) * 3 + 1);
    }
    else {
      setLocalValue(LOCAL_STORAGE_KEYS?.trackingListCurrentPage, 1);
      setCurrentPage(1)
    }
    fetchDataFromAPI();
  }, []);
  useMemo(() => {
    fetchShipmentData("");
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage]);
  useEffect(() => {
    // if (!searchTerm?.length) {
    //   fetchShipmentData("");
    // }
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);
  useEffect(() => {
    if (debouncedSearchTerm?.trim() !== "") {
      fetchShipmentData(debouncedSearchTerm);
     
    } else {
      fetchShipmentData("");
    }
  }, [debouncedSearchTerm]);
  useMemo(() => {
    if (shipmentId !== "") {
      fetchShipmentDetails(shipmentId);
    }
  }, [shipmentId]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_KEY, // Add your API key
  });
  const navigateToListing = (to, event) => {
    event.preventDefault();
    if (to === "origin") {
      navigate("/eventBookings/at-origin-port-shipments/");
    } else if (to === "in") {
      navigate("/eventShipments/in-transit-shipments/");
    } else if (to === "des") {
      navigate("/eventShipments/at-destination-port-shipment");
    } else {
      navigate("/eventShipments/gate-out-shipments");
    }
    event.stopPropagation(); // Add this line
  };
  const itemsToMap =
    debouncedSearchTerm?.trim() !== "" ? saerchhShipmentsData : shipmentsData.data;
  return (
    <div>
      <Layout />
      <div className="mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper tracking md:pt-[26px] md:pr-9 md:pb-9 md:pl-[26px]">
        <section className="">
          <div className="card pt-[14px] md:pt-[22px] pl-[17px] pr-[14px] md:px-[26px] pb-[19px] md:pb-[21px]">
            <div className="card-heading pb-[11px] md:pb-[18px] flex items-center justify-between">
              <h4 className="heading">Key</h4>
              <BsThreeDots className="hidden md:inline-block md:w-4 md:h-4" />
            </div>
            <div className="card-body pt-[14px] md:pt-[24px]">
              <div className=" ">
                <div
                  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 gap-[9px] md:gap-[20px] bg-light-blue"
                  onClick={(event) => navigateToListing("origin", event)}
                  id="origin"
                >
                  <div className="py-[7px] md:pt-[22px] md:pb-[21px] pl-[8px] md:pl-[21px] flex items-center justify-start gap-[11px] md:gap-4 tracking-card cursor-pointer">
                    <div className="w-7 h-7 md:w-10 md:h-10 bg-success rounded-lg md:rounded-xl flex-none"></div>
                    <h6 className="text-darkblue">
                      At Origin Port:{" "}
                      <span className="text-success">
                        {keys.find((item) => item?.name === "At Origin Port")
                          ?.y || 0}
                      </span>
                    </h6>
                  </div>
                  <div
                    className="py-[7px] md:pt-[22px] md:pb-[21px] pl-[8px] md:pl-[21px] flex items-center justify-start gap-[11px] md:gap-4 tracking-card cursor-pointer"
                    onClick={(event) => navigateToListing("in", event)}
                    id="in"
                  >
                    <div className="w-7 h-7 md:w-10 md:h-10 bg-sky-blue-dark rounded-lg md:rounded-xl flex-none"></div>
                    <h6 className="text-darkblue">
                      In Transit:{" "}
                      <span className="text-sky-blue-dark">
                        {" "}
                        {keys?.find((item) => item?.name === "In Transit")?.y ||
                          0}
                      </span>
                    </h6>
                  </div>
                  <div
                    className="py-[7px] md:pt-[22px] md:pb-[21px] pl-[8px] md:pl-[21px] flex items-center justify-start gap-[11px] md:gap-4 tracking-card cursor-pointer"
                    onClick={(event) => navigateToListing("des", event)}
                    id="des"
                  >
                    <div className="w-7 h-7 md:w-10 md:h-10 bg-warning rounded-lg md:rounded-xl flex-none"></div>
                    <h6 className="text-darkblue">
                      At Destination Port:
                      <span className="text-warning">
                        {keys.find(
                          (item) => item?.name === "At Destination Port"
                        )?.y || 0}
                      </span>
                    </h6>
                  </div>
                  <div
                    className="py-[7px] md:pt-[22px] md:pb-[21px] pl-[8px] md:pl-[21px] flex items-center justify-start gap-[11px] md:gap-4 tracking-card cursor-pointer"
                    onClick={(event) => navigateToListing("gate", event)}
                    id="gate"
                  >
                    <div className="w-7 h-7 md:w-10 md:h-10 bg-darkblue rounded-lg md:rounded-xl flex-none"></div>
                    <h6 className="text-darkblue">
                      Gate Out:{" "}
                      <span className="text-darkblue">
                        {keys.find((item) => item?.name === "Gate Out")?.y || 0}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!detailLoading && markers.length > 0 && (
          <section className="tracking-map mt-[13px] md:mt-[23px]">
            <div className="card pt-[13px] md:pt-[26px] pl-[15px] md:pl-6 pr-[19px] md:pr-[23px] pb-[19px] md:pb-8">
              <div className="card-heading pb-[11px] md:pb-[18px]">
                <h4 className="heading">Tracking Map</h4>
              </div>
              {isLoaded ? (
                <Map
                  height={"500px"}
                  width={"100%"}
                  markers={markers}
                  points={polyline}
                  
                />
              ) : null}
            </div>
          </section>
        )}
        <section className="mt-[17px] md:mt-[25px]">
          <div className="bg-white rounded-[15px] box-shadow">
            <div className="grid grid-cols-1 xl:grid-cols-2">
              <div className="goods-details bg-white pt-[14px] md:pt-[19px] pl-[14px] md:pl-[25px] pb-[17px] md:pb-[16px] pr-[17px] md:pr-[14px] rounded-[15px]">
                <div className="pb-[11px] md:pb-[18px] heading-wrapper">
                  <h4 className="text-darkblue heading">Goods Details</h4>
                </div>
                {shipmentLoading ? (
                  <div className="text-center loader loader-height">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="list-wrapper">
                    <div className="list-wrapper-item mt-[13px] relative block md:hidden">
                      <input
                        className="w-full pl-10"
                        type="search"
                        name="searchTerm"
                        id="searchTerm"
                        placeholder="Search Shipment"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                      <img
                        className="absolute top-[15px] left-[12px]"
                        src="/assets/icons/search.svg"
                        alt="search-icon"
                      />
                    </div>
                    <div className="list mt-[15px] md:mt-[18px] pr-[11px] md:pr-5">
                      <div className="hidden md:block">
                        <div className="list-item mb-4 relative">
                          <input
                            className="w-full pl-10"
                            type="text"
                            name="search"
                            id="search"
                            placeholder="Search Shipment"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                          />
                          <img
                            className="absolute top-[15px] left-[12px]"
                            src="/assets/icons/search.svg"
                            alt="search-icon"
                          />
                        </div>
                      </div>
                      {itemsToMap.length === 0
                        ? "No Record Found"
                        : itemsToMap.map((item) => {
                            return (
                              <div
                                className={`list-item  pr-[14px] pl-[17px] md:px-4 pb-[10px] pt-[9px] mb-[13px] md:mb-4 ${
                                  shipmentId === item?.shipment_id
                                    ? "active-shipment-list-item"
                                    : ""
                                }`}
                                key={item?.id}
                                onClick={() => setShipmentId(item?.shipment_id)}
                              >
                                <div
                                  className={
                                    "list-item-heading flex items-center justify-between text-primary text-strong"
                                  }
                                >
                                  <h6 onClick={()=>  navigate(`/shipments/${item?.shipment_id}`)}>{item?.shipment_id}</h6>
                                  <span>
                                    {item?.destination_eta
                                      ? convertDateFormat(item?.destination_eta)
                                      : "NA"}
                                  </span>
                                </div>
                                <div className="flex items-center justify-between list-item-footer gap-1 mt-1">
                                  <span className="text-darkblue inline-block flex-none">
                                    {item?.consignor_city && item?.consignor_state
                                      ? `${item?.consignor_city}, ${item?.consignor_state}`
                                      : item?.consignor_city ||
                                        item?.consignor_state ||
                                        ""}
                                  </span>
                                  <div className="arrow-icon-wrapper">
                                    <div className="arrow-icon">
                                      <div className="triangleline"></div>
                                      <i className="arrow arrow-right"></i>
                                    </div>
                                  </div>
                                  <span className="text-darkblue inline-block  flex-none">
                              

                                    {item?.consignee_city && item?.consignee_state
                                      ? `${item?.consignee_city}, ${item?.consignee_state}`
                                      : item?.consignee_city ||
                                        item?.consignee_state ||
                                        ""}
                                    {/* <span className="md:hidden">UK</span> */}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      {shipmentsData?.data?.length > 0 && (
                        <section>
                          <div
                            className={
                              "table-wrapper  pt-[13px] md:pt-[17px] px-0 pb-4  w-full"
                            }
                          >
                            <div className="grid grid-cols-2 gap-[14px] md:gap-0">
                              <div className="page-info pl-[14px] md:pl-[26px] col-span-2 md:col-span-1">
                                Showing {currentPageLength} to {pageLength} of{" "}
                                {pageInfo} entries
                              </div>
                              <nav
                                aria-label="Page navigation flex justify-between items-center "
                                className="col-span-2 md:col-span-1"
                              >
                                <ul className="pagination justify-content-center">
                                  <li
                                    className={`page-item page-next-pre ${
                                      currentPage === 1 ? "disabled" : ""
                                    }`}
                                  >
                                    <button
                                      className="page-link px-1"
                                      onClick={() =>
                                        handlePageChange(currentPage - 1)
                                      }
                                    >
                                      <img
                                        className="rotate-180 normal-image"
                                        src="/assets/icons/right-arrow.svg"
                                        alt=""
                                      />
                                      <img
                                        className="rotate-180 image-hover"
                                        src="/assets/icons/right-white-arrow.svg"
                                        alt=""
                                      />
                                    </button>
                                  </li>
                                  <ul className="pagination pagination-mobile trackingPagination">
                                    {Array.from({ length: 3 })?.map(
                                      (_, index) => {
                                        const pageNumber = startPage + index; // Calculate the actual page number to display
                                        return (
                                          <li
                                            onClick={() =>
                                              handlePageChange(pageNumber)
                                            }
                                            key={pageNumber}
                                            className={`page-item ${
                                              currentPage === pageNumber
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            <button className="page-link">
                                              {pageNumber}
                                            </button>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                  <li
                                    className={`page-item  page-next-pre ${
                                      currentPage === lastPage ? "disabled" : ""
                                    }`}
                                  >
                                    {currentPage < lastPage && (
                                      <button
                                        className="page-link px-1"
                                        onClick={() =>
                                          handlePageChange(currentPage + 1)
                                        }
                                        disabled={currentPage === lastPage}
                                      >
                                        <img
                                          className="normal-image"
                                          src="/assets/icons/right-arrow.svg"
                                          alt=""
                                        />
                                        <img
                                          className="image-hover"
                                          src="/assets/icons/right-white-arrow.svg"
                                          alt=""
                                        />
                                      </button>
                                    )}
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="route-details bg-white pt-0 md:pt-[19px] pl-[15px] md:pl-[34px] pb-[14px] md:pb-[16px] pr-[17px] md:pr-[26px] rounded-[15px]">
                <div className="pb-[11px] md:pb-[18px] heading-wrapper">
                  <h4 className="text-darkblue heading">Tracking</h4>
                </div>

                <div className="w-full  flex items-center justify-center details-section ">
                  {detailLoading ? (
                    <div className="text-center loader loader-height">
                      <CircularProgress />
                    </div>
                  ) : shipmentDetail?.Data?.UniversalShipment?.Shipment
                      ?.ContainerCount > 0 ? (
                    <>
                      <TrackingDetail
                        dataApi={shipmentDetail}
                        trackingEvents={trackingData}
                      />
                    </>
                  ) : shipmentId ? (
                    "No Record Found"
                  ) : (
                    "Select a shipment to load details"
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Tracking;
