import React, { useState, useEffect } from "react";
import "../../../src/scss/popup.scss";
import { COOKIE_KEYS } from "../../common/constants";
import { getCookie } from "../../common/utils";

export default function PopupTable({
  visible,
  onClose,
  customizeColumn,
  saveCustomizedColumns,
}) {
  const [checkedColumns, setValues] = useState({});

  // if (!visible) return null;

  const handleClose = (e) => {
    if (e.target.id === "table-modal-wrapper") {
      onClose();
    }
  };
  const handleCheckboxChange = (e) => {
    const checkboxId = e.target.id;
    const isChecked = e.target.checked;
    const columLabel = e.target.value;

    setValues((prevValues) => ({
      ...prevValues,
      [checkboxId]: {
        id: checkboxId,
        label: columLabel,
        checked: isChecked,
      },
    }));
    customizeColumn(checkboxId, isChecked, columLabel);
  };
  const handleSave = () => {
    const selectedColumns = Object.values(checkedColumns)
      .filter((item) => item.checked)
      .map((item) => ({
        id: item.id,
        label: item.label,
        checked: item.checked,
      }));
    saveCustomizedColumns(selectedColumns);
    // onClose();
  };

  useEffect(() => {
    const savedCheckboxData = getCookie(COOKIE_KEYS?.shipmentCustomColumns);
    if (savedCheckboxData) {
      const checkboxData =savedCheckboxData;
      if (checkboxData?.length) {
        const dataArray = checkboxData?.map((item) => ({ [item?.id]: item }));
        const mergedObject = Object.assign({}, ...dataArray);
        setValues(mergedObject);
      }
    }

    // setValues({
    //     [checkboxId]: {
    //       id: checkboxId,
    //       label: columLabel,
    //       checked: isChecked,
    //     },
    //   }));
  }, []);
  return (
    <div
      id="table-modal-wrapper"
      onClick={handleClose}
      className={`modal-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center transition-all ease-in-out duration-300 ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div
        className={`modal w-[629px] bg-white rounded-[15px] pt-[26px] pr-[27px] pb-[26px] pl-[30px] relative transition-all ease-in-out duration-300 ${
          visible ? "block" : "hidden"
        }`}
      >
        <img
          src="/assets/icons/close.svg"
          alt="close"
          className="absolute top-4 right-[18px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-header pb-[22px]">
          <h4 className="heading">Customise your table columns</h4>
          <div className="description">
            Choose what data you see in the columns in the shipment overview
          </div>
        </div>
        <div className="modal-body pt-[23px]">
          <div className="grid grid-cols-2 gap-x-[60px]">
            <div>
              {/* <div className="flex items-center justify-between pb-[15px] modal-item">
                                <label htmlFor="MainVessel">Main Vessel</label>
                                <input
                                    type="checkbox"
                                    id="MainVessel"
                                    name=""
                                    value="Main Vessel"
                                    checked={checkedColumns['MainVessel']}
                                    onChange={handleCheckboxChange }
                                    
                                />
                            </div> */}
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="CurrentVoy">Voy./Flight</label>
                <input
                  type="checkbox"
                  id="CurrentVoy"
                  name=""
                  value="Voy./Flight"
                  checked={checkedColumns?.CurrentVoy?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="MainVoy">Main Voy./Flight</label>
                                <input type="checkbox" id="MainVoy" name="" value="Main Voy./Flight" checked={checkedColumns['MainVoy']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="EstimatedPickup">Estimated Pickup</label>
                <input
                  type="checkbox"
                  id="EstimatedPickup"
                  name=""
                  value="Estimated Pickup"
                  checked={checkedColumns?.EstimatedPickup?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="ActualPickup">Actual Pickup</label>
                <input
                  type="checkbox"
                  id="ActualPickup"
                  name=""
                  value="Actual Pickup"
                  checked={checkedColumns?.ActualPickup?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="EstimatedDelivery">Estimated Delivery Date</label>
                                <input type="checkbox" id="EstimatedDelivery" name="" value="Estimated Delivery Date" checked={checkedColumns['EstimatedDelivery']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
              {/* <div className='flex items-center justify-between modal-item'>
                                <label htmlFor="LastMilestone">Last Milestone Desc</label>
                                <input type="checkbox" id="LastMilestone" name="" value="Last Milestone Desc" checked={checkedColumns['LastMilestone']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Containers">Containers</label>
                <input
                  type="checkbox"
                  id="Containers"
                  name=""
                  value="Containers"
                  checked={checkedColumns?.Containers?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Carrier">Carrier</label>
                <input
                  type="checkbox"
                  id="Carrier"
                  name=""
                  value="Carrier"
                  checked={checkedColumns?.Carrier?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="TEU">TEU</label>
                <input
                  type="checkbox"
                  id="TEU"
                  name=""
                  value="TEU"
                  checked={checkedColumns?.TEU?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Weight">Weight</label>
                <input
                  type="checkbox"
                  id="Weight"
                  name=""
                  value="Weight"
                  checked={checkedColumns?.Weight?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Quantity">Quantity</label>
                <input
                  type="checkbox"
                  id="Quantity"
                  name=""
                  value="Quantity"
                  checked={checkedColumns?.Quantity?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="Volume">Volume</label>
                <input
                  type="checkbox"
                  id="Volume"
                  name=""
                  value="Volume"
                  checked={checkedColumns?.Volume?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              {/* <div className='flex items-center justify-between pb-[15px] modal-item'>
                                <label htmlFor="NextMilestoneDesc">Next Milestone Desc</label>
                                <input type="checkbox" id="NextMilestoneDesc" name="" value="Next Milestone Desc" checked={checkedColumns['NextMilestoneDesc']}
                                    onChange={handleCheckboxChange} />
                            </div> */}
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="JobNotes">Job Notes</label>
                <input
                  type="checkbox"
                  id="JobNotes"
                  name=""
                  value="Job Notes"
                  checked={checkedColumns?.JobNotes?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center justify-between pb-[15px] modal-item">
                <label htmlFor="AdditionalTerms">Additional Terms</label>
                <input
                  type="checkbox"
                  id="AdditionalTerms"
                  name=""
                  value="Additional Terms"
                  checked={checkedColumns?.AdditionalTerms?.checked}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <button
            className="btn-modal pt-[14px] pb-[18px] w-full mt-[19px] flex items-end justify-center"
            onClick={handleSave}
          >
            save
          </button>
        </div>
      </div>
    </div>
  );
}
