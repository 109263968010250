import React from "react";
import "../scss/pages/bookings.scss";
import BookingsList from "../components/bookings/list";
import Layout from "../components/common/layout";
import { useParams, useNavigate } from "react-router-dom";
function Bookings() {
  const { keyword } = useParams();
  const key = keyword || "";
  const title = keyword?.includes("origin") ? "Origin" : "Your";
  const navigate = useNavigate();
  return (
    <>
      <Layout />
      <div className="mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper md:pt-[26px] md:pr-9 md:pb-[27px] md:pl-[26px]">
        <div className="bookings bg-white">
          <div className="booking-list-header">
            <div className="flex items-center md:justify-start sm:justify-center flex-wrap">
            <h4 className="bookings-heading">{title} Bookings</h4>
            {keyword?.includes("origin")?<>
            <button
              className="redirect-btn  md:inline-block ml-5 mt-5"
              type="button"
              onClick={() =>
                navigate("/eventShipments/at-origin-port-shipments/")
              }
            >
              Go to Shipments at Origin Port
            </button>
            </>:null}
            </div>
           
      
            <p className="bookings-text pr-[68px] sm:pr-0">
              Here’s where you keep up with all your current bookings.
            </p>
          </div>
          <BookingsList keyword={key} />
        </div>
      </div>
    </>
  );
}

export default Bookings;
