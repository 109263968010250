import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { convertDateFormat } from "../../common/utils";

export default function ShipmentDetails({ dataApi, dbData, showDbData }) {
  const [shipmentDetails, setshipmentDetails] = useState(true);
  const [isSubShipmentInfo, setSubShipmentInfo] = useState("");
  const toggleAccordionShipments = () => {
    setshipmentDetails(!shipmentDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  const dates = showDbData ? dbData?.dates : dataApi?.dates;
  const shipmentDetail = dbData?.shipment_details;

  useEffect(() => {
    if (responseApi !== null) {
      if (
        responseApi?.hasOwnProperty("SubShipmentCollection") &&
        responseApi?.SubShipmentCollection.hasOwnProperty("SubShipment")
      ) {
        setSubShipmentInfo("enable");
      } else {
        setSubShipmentInfo("disable");
      }
    }
  }, [responseApi]);
  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-5 md:pb-[30px] mt-[26px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionShipments}
        >
          <h4 className="font-semibold">Shipment Details</h4>
          <img
            className={`transition-transform transform ${
              shipmentDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          shipmentDetails ? "max-h-[800px]" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] md:gap-y-[33px]  3xl:gap-x-[62px]">
              <div className="item">
                <h6 className="item-heading">Mode</h6>
                <div className="item-content">
                  {showDbData
                    ? shipmentDetail?.mode || "NA"
                    : responseApi?.TransportMode?.Code
                    ? `${responseApi?.TransportMode?.Code} (${responseApi?.TransportMode?.Description})`
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Origin</h6>
                <div className="item-content">
                  {showDbData ? (
                    shipmentDetail?.origin || "NA"
                  ) : isSubShipmentInfo === "disable" ? (
                    responseApi?.PortOfOrigin?.Code ? (
                      <>
                        {`${responseApi?.PortOfOrigin?.Code} (${responseApi?.PortOfOrigin?.Name})`}
                      </>
                    ) : (
                      "NA"
                    )
                  ) : responseApi?.SubShipmentCollection?.SubShipment
                      .PortOfOrigin?.Code ? (
                    <>
                      {`${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          .PortOfOrigin?.Code === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              .PortOfOrigin?.Code
                          : "NA"
                      } (${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          .PortOfOrigin?.Name === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              .PortOfOrigin?.Name
                          : "NA"
                      })`}
                    </>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Destination</h6>
                <div className="item-content">
                  {showDbData ? (
                    shipmentDetail?.destination || "NA"
                  ) : isSubShipmentInfo === "disable" ? (
                    responseApi?.PortOfDestination?.Code ? (
                      <>{`${responseApi?.PortOfDestination?.Code} (${responseApi?.PortOfDestination.Name})`}</>
                    ) : (
                      "NA"
                    )
                  ) : responseApi?.SubShipmentCollection?.SubShipment
                      ?.PortOfDestination?.Code ? (
                    <>
                      {`${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          ?.PortOfDestination?.Code === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              ?.PortOfDestination?.Code
                          : "NA"
                      } (${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          ?.PortOfDestination?.Name === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              ?.PortOfDestination?.Name
                          : "NA"
                      })`}
                    </>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Container</h6>
                <div className="item-content">
                  {showDbData
                    ? shipmentDetail?.type || "NA"
                    : isSubShipmentInfo === "disable"
                    ? responseApi?.ShipmentType?.Code
                      ? `${responseApi?.ShipmentType?.Code} (
                      ${responseApi?.ShipmentType?.Description})`
                      : "NA"
                    : responseApi?.SubShipmentCollection?.SubShipment
                        ?.ShipmentType?.Code
                    ? `${responseApi?.SubShipmentCollection?.SubShipment?.ShipmentType?.Code}
                      (${responseApi?.SubShipmentCollection?.SubShipment?.ShipmentType?.Description}
                      )`
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">ETD (est depart)</h6>
                <div className="item-content text-font-bold">
                  {typeof dates?.etd === "string"
                    ? convertDateFormat(dates?.etd)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">ETA (est arrival)</h6>
                <div className="item-content text-font-bold">
                  {typeof dates?.eta === "string"
                    ? convertDateFormat(dates?.eta)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Vessel</h6>
                <div className="item-content">
                  {showDbData
                    ? shipmentDetail?.vessel || "NA"
                    : isSubShipmentInfo === "disable"
                    ? responseApi?.VesselName &&
                      Object.keys(responseApi?.VesselName).length > 0
                      ? responseApi?.VesselName
                      : "NA"
                    : responseApi?.SubShipmentCollection?.SubShipment
                        ?.VesselName &&
                      Object.keys(
                        responseApi?.SubShipmentCollection?.SubShipment
                          ?.VesselName
                      ).length > 0
                    ? responseApi?.SubShipmentCollection?.SubShipment
                        ?.VesselName
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Carrier</h6>
                <div className="item-content">
                  {showDbData
                    ? shipmentDetail?.carrier || "NA"
                    : responseApi?.TransportLegCollection?.TransportLeg?.[0]
                        ?.Carrier?.AddressType === "Carrier"
                    ? responseApi?.TransportLegCollection?.TransportLeg?.[0]
                        ?.Carrier?.CompanyName
                    : typeof responseApi?.TransportLegCollection?.TransportLeg
                        ?.Carrier?.CompanyName === "string"
                    ? `${responseApi?.TransportLegCollection?.TransportLeg?.Carrier?.CompanyName}`
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Incoterm</h6>
                <div className="item-content">
                  {showDbData
                    ? shipmentDetail?.incoterm || "NA"
                    : isSubShipmentInfo === "disable"
                    ? responseApi?.ShipmentIncoTerm?.Code &&
                      Object.keys(responseApi?.ShipmentIncoTerm?.Code).length >
                        0
                      ? responseApi?.ShipmentIncoTerm?.Code
                      : "NA "
                    : responseApi?.SubShipmentCollection?.SubShipment
                        ?.ShipmentIncoTerm?.Code &&
                      Object.keys(
                        responseApi?.SubShipmentCollection?.SubShipment
                          ?.ShipmentIncoTerm?.Code
                      ).length > 0
                    ? responseApi?.SubShipmentCollection?.SubShipment
                        ?.ShipmentIncoTerm?.Code
                    : "NA "}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">BOL</h6>
                <div className="item-content">
                  {showDbData
                    ? shipmentDetail?.bol || "NA"
                    : typeof responseApi?.SubShipmentCollection?.SubShipment
                        ?.WayBillNumber === "string"
                    ? responseApi?.SubShipmentCollection?.SubShipment
                        ?.WayBillNumber
                    : typeof responseApi?.WayBillNumber === "string"
                    ? responseApi?.WayBillNumber
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Container Count</h6>
                <div className="item-content">
                  {showDbData
                    ? shipmentDetail?.container_count || "NA"
                    : responseApi?.ContainerCount || "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Voy./Flight </h6>
                <div className="item-content">
                  {showDbData ? (
                    shipmentDetail?.flight_voyage || "NA"
                  ) : responseApi?.TransportMode?.Code !== "ROA" ? (
                    <>
                      {responseApi?.VoyageFlightNo &&
                      Object.keys(responseApi?.VoyageFlightNo).length > 0
                        ? responseApi?.VoyageFlightNo
                        : "NA"}
                    </>
                  ) : (
                    <>N/A</>
                  )}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">ATD (actual departure)</h6>
                <div className="item-content text-font-bold">
                  {responseApi?.TransportLegCollection?.TransportLeg
                    ?.ActualDeparture &&
                  Object.keys(
                    responseApi?.TransportLegCollection?.TransportLeg
                      ?.ActualDeparture
                  ).length > 0 &&
                  !responseApi?.TransportLegCollection?.TransportLeg?.ActualDeparture.includes(
                    "1970"
                  )
                    ? moment(
                        responseApi?.TransportLegCollection?.TransportLeg
                          ?.ActualDeparture
                      )?.format("DD-MMM-YYYY HH:mm")
                    : typeof responseApi?.TransportLegCollection
                        ?.TransportLeg?.[
                        responseApi?.TransportLegCollection?.TransportLeg
                          ?.length - 1
                      ]?.ActualDeparture === "string"
                    ? moment(
                        responseApi?.TransportLegCollection?.TransportLeg?.[
                          responseApi?.TransportLegCollection?.TransportLeg
                            ?.length - 1
                        ]?.ActualDeparture
                      )?.format("DD-MMM-YYYY HH:mm")
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">ATA (actual arrival)</h6>
                <div className="item-content text-font-bold">
                  {responseApi?.TransportLegCollection?.TransportLeg
                    ?.ActualArrival &&
                  Object.keys(
                    responseApi?.TransportLegCollection?.TransportLeg
                      ?.ActualArrival
                  ).length > 0 &&
                  !responseApi?.TransportLegCollection?.TransportLeg?.ActualArrival?.includes(
                    "1970"
                  )
                    ? moment(
                        responseApi?.TransportLegCollection?.TransportLeg
                          ?.ActualArrival
                      )?.format("DD-MMM-YYYY HH:mm")
                    : dates?.actual_pickup
                    ? moment(dates?.actual_pickup)?.format("DD-MMM-YYYY HH:mm")
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
