import React from "react";
import { useState } from "react";

export default function ShipmentDetails({ dataApi }) {
  const [shipmentDetails, setshipmentDetails] = useState(true);

  const toggleAccordionShipments = () => {
    setshipmentDetails(!shipmentDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  const formattedDateAPI = (DateFormat) => {
    const rawDate = DateFormat;
    const parsedDate = new Date(rawDate);

    const day = parsedDate.getDate();
    const month = parsedDate.toLocaleString("default", { month: "short" });
    const year = parsedDate.getFullYear();

    const hours = parsedDate.getHours().toString().padStart(2, "0"); // Ensure 2-digit representation
    const minutes = parsedDate.getMinutes().toString().padStart(2, "0"); // Ensure 2-digit representation
    const seconds = parsedDate.getSeconds().toString().padStart(2, "0"); // Ensure 2-digit representation

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };
  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-5 md:pb-[30px] mt-7">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionShipments}
        >
          <h4 className="font-semibold">Shipment Details</h4>
          <img
            className={`transition-transform transform ${
              shipmentDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          shipmentDetails ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] md:gap-y-[33px] 3xl:gap-x-[62px]">
              <div className="item">
                <h6 className="item-heading">Container Mode</h6>
                <div className="item-content">
                  {responseApi?.ContainerMode?.Code ?? "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Type</h6>
                <div className="item-content">
                  {" "}
                  {responseApi?.SubShipmentCollection?.SubShipment?.ShipmentType
                    ?.Code &&
                  responseApi?.SubShipmentCollection?.SubShipment?.ShipmentType
                    ?.Description
                    ? `${responseApi?.SubShipmentCollection?.SubShipment?.ShipmentType?.Description} (${responseApi?.SubShipmentCollection?.SubShipment?.ShipmentType?.Code})`
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Incoterm</h6>
                <div className="item-content">
                  {responseApi?.ShipmentIncoTerm?.Code &&
                  Object.keys(responseApi.ShipmentIncoTerm.Code).length > 0
                    ? responseApi.ShipmentIncoTerm.Code
                    : "NA "}
                </div>
              </div>
              {/* <div className='item'>
                <h6 className='item-heading'>Consignee</h6>
                <div className='item-content'>{responseApi?.OrganizationAddressCollection?.OrganizationAddress[2]?.CompanyName}</div>
              </div>
              <div className='item'>
                <h6 className='item-heading'>Consignor</h6>
                <div className='item-content'>{responseApi?.OrganizationAddressCollection?.OrganizationAddress[0]?.CompanyName}</div>
              </div>
              <div className='item'>
                <h6 className='item-heading'>Current Vessel</h6>
                <div className='item-content'>{responseApi?.TransportMode.Code !== "ROA" ? (<>
                  {
                    responseApi?.VesselName &&
                      Object.keys(responseApi.VesselName).length > 0
                      ? responseApi.VesselName
                      : ""
                  }</>) : (<>N/A</>)}</div>
              </div>
              <div className='item'>
                <h6 className='item-heading'>Payment Term</h6>
                <div className='item-content'>{
                  responseApi?.AdditionalTerms &&
                    Object.keys(responseApi?.AdditionalTerms).length > 0
                    ? responseApi?.AdditionalTerms
                    : ""
                }</div>
              </div> */}
              <div className="item">
                <h6 className="item-heading">Booking Date</h6>
                <div className="item-content">
                  {responseApi?.DateCollection?.Date[0]?.IsEstimate === "false"
                    ? formattedDateAPI(
                        responseApi?.DateCollection.Date[0].Value
                      )
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Add. Terms</h6>
                <div className="item-content">
                  {responseApi?.AdditionalTerms &&
                  Object.keys(responseApi?.AdditionalTerms).length > 0
                    ? responseApi?.AdditionalTerms
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Carrier</h6>
                <div className="item-content">
                {typeof responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                  (item) => item.AddressType === "ShippingLineAddress"
                )?.CompanyName === "string"
                  ? responseApi?.OrganizationAddressCollection?.OrganizationAddress?.find(
                      (item) => item.AddressType === "ShippingLineAddress"
                    )?.CompanyName
                  : "NA"}
                </div>
          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
