import React, { useState } from "react";
import "../../scss/header.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation, useParams } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { BiLogOut, BiBuilding } from "react-icons/bi";
import { BsBuildingFillCheck } from "react-icons/bs";
import Cookies from "js-cookie";
import { getLocalValue, logoutReset, removeLocalValue, setLocalValue } from "../../common/utils";
import { LOCAL_STORAGE_KEYS } from "../../common/constants";
import { Axios } from "../../pages/config";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaEdit } from "react-icons/fa";

export default function Header({ toggleSidebar }) {
  const [dropdown, setDropdown] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  let { userId, customId,containerId } = useParams();
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const logout = () => {
    logoutReset();

    Cookies.remove("customColumn");
    Cookies.remove("customColumnCustom");
    Cookies.remove("customColumnBooking");
    window.location = "/login";
  };

  const userName = getLocalValue(LOCAL_STORAGE_KEYS?.userName);
  const companyName = getLocalValue(LOCAL_STORAGE_KEYS?.companyName);
  const profileImage = getLocalValue(LOCAL_STORAGE_KEYS?.profileImage);
  const organisations =
    JSON.parse(getLocalValue(LOCAL_STORAGE_KEYS?.userOrganizations)) || [];
  const updateUserOrganisation = async (id, name, code) => {
    try {
      setProcessing(true);
      await Axios.post("/update-user-organisation", {
        organisation_id: id,
      });
      if (code && code !== null && code !== "null") {
        setLocalValue(LOCAL_STORAGE_KEYS?.clientCode, code);
      } else {
        setLocalValue(LOCAL_STORAGE_KEYS?.clientCode, "");
      }
      if (name && name !== null && name !== "null") {
        setLocalValue(LOCAL_STORAGE_KEYS?.companyName, name);
      } else {
        setLocalValue(LOCAL_STORAGE_KEYS?.companyName, "");
      }
       removeLocalValue(LOCAL_STORAGE_KEYS?.dailyUpdate);
       removeLocalValue(LOCAL_STORAGE_KEYS?.notificationData);
       removeLocalValue( LOCAL_STORAGE_KEYS?.dashboardLastRefreshDate);
       removeLocalValue(LOCAL_STORAGE_KEYS?.calendarEvents);
       
      handleClose();

      window.location = "/";
    } catch (error) {
      console.error("Error updating user organisation:", error);
    } finally {
      setProcessing(false);
    }
  };
  return (
    <div className="header flex items-center justify-start w-full flex-col md:flex-row md:h-[89px]">
      <div className="logo pt-[55px] pb-[26px] px-[36px] md:p-0 w-full flex items-center justify-between md:justify-center max-w-full md:max-w-[307px]">
        <Link to={"/"}>
          <img src="/assets/icons/Logo.svg" alt="logo" />
        </Link>
        <GiHamburgerMenu
          className="navbar-icon text-primary md:hidden"
          onClick={() => toggleSidebar()}
        />
      </div>
      <div className="pt-[19px] pb-[19px] pl-9 flex items-start md:items-center justify-between w-full md:pt-7 md:pb-[26px] md:pl-[26px] pr-9 ">
        <div className="md:pt-[3px] md:pb-2">
          <h2 className="text-primary page-heading">
            {location.pathname === "/"
              ? "Dashboard"
              : location.pathname.startsWith("/eventShipments/")
              ? "Shipments"
              : location.pathname.split("/")[1]}{" "}
            {(userId || customId ||containerId) && (
              <>
                {" "}
                |{" "}
                <span className="text-darkblue inline-block mt-[6px] sm:mt-0">
                  {" "}
                  {userId === "delveryDetails=true"
                    ? "NEW QUOTES"
                    : userId
                    ? userId
                    : customId||containerId}
                </span>
              </>
            )}{" "}
          </h2>
        </div>

        <div className="flex items-center justify-between gap-[17px] md:gap-[22px] detail-wrapper flex-none">
          <div className="relative cursor-pointer">
            <div
              className="profile-avater-wrapper hidden lg:flex items-center justify-between gap-[15px] "
              onClick={handleDropdown}
            >
              <div className="profile-avater w-[29px] h-[29px] rounded-full">
                <div className="hover-effect rounded-full max-w-[147px] w-full h-[147px]">
                  <img
                    className="w-[29px] h-[29px] rounded-full"
                    src={
                      profileImage
                        ? `/assets/image/${profileImage}`
                        : "/assets/image/user-Image.png"
                    }
                    alt="profileImage"
                  />
                </div>
              </div>
              <div className="profile-details">
                <p className="profile-name">{userName}</p>
                <p className="profile-designation">{companyName}</p>
              </div>
            </div>
            <div
              className={`logout-dropdown absolute  right-0 bg-white w-full rounded-lg linear duration-100 ${
                dropdown ? "inline-block top-12" : "invisible top-8 "
              }`}
            >
              <Link to="/settings">
                <div className="header-dropdown-item flex items-center justify-start gap-2 p-2">
                  <span className="icon-wrapper w-[30px] h-[30px] inline-flex items-center justify-center bg-primary text-white rounded-lg">
                    <CgProfile />
                  </span>
                  My Profile
                </div>
              </Link>

              <div
                onClick={logout}
                className="header-dropdown-item flex items-center justify-start gap-2 p-2"
              >
                <span className="icon-wrapper w-[30px] h-[30px] inline-flex items-center justify-center bg-primary text-white rounded-lg">
                  <BiLogOut />
                </span>
                Logout
              </div>
            </div>
          </div>

          <Link to="/settings">
            <img
              className="w-[18px] h-[18px] md:w-[22px] md:h-[22px] cursor-pointer flex-none"
              src="/assets/icons/settings-darkblue.svg"
              alt=""
            />
          </Link>
          <Link to={"/notification"} className="flex-none">
            <img
              className="w-[14px] h-[18px] md:w-[18px] md:h-[22px] cursor-pointer flex-none"
              src="/assets/icons/notifications.svg"
              alt=""
            />
          </Link>
          {organisations?.length>1?
          <>
          <Tooltip title="Change  Organization">
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <FaEdit color="#112560"size={27} />
            </IconButton>
          </Tooltip>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {processing ? (
              <div className="header-dropdown-item flex items-center justify-center gap-2">
                <CircularProgress />
              </div>
            ) : (
              organisations.map((company) => {
                return (
                  <MenuItem
                    onClick={() =>
                      updateUserOrganisation(
                        company?.id,
                        company?.name,
                        company?.local_client_code
                      )
                    }
                  >
                    <div
                      className={`header-dropdown-item flex items-center justify-start gap-2 p-2 ${
                        company?.name === companyName ? "active" : ""
                      }`}
                    >
                      <div className="icon-wrapper w-[30px] h-[30px] inline-flex items-center justify-center bg-primary text-white rounded-lg">
                        {company?.name === companyName ? (
                          <BsBuildingFillCheck />
                        ) : (
                          <BiBuilding />
                        )}
                      </div>
                      <div>{company.name}</div>
                    </div>
                  </MenuItem>
                );
              })
            )}
          </Menu>
          </>
          :null}
        </div>
      </div>
    </div>
  );
}
