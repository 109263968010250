import React from 'react';
import Layout from '../components/common/layout';
import '../scss/pages/settings.scss';
import Settingspage from '../components/settings/Settingspage';
import SidbarInner from '../components/common/sidbarInner';


export default function Settings() {
   
    return (
        <>
            <Layout />
            <div className='page-wrapper settings mt-[167px] md:mt-[89px] md:ml-[307px]  md:pt-[26px] md:pr-9 md:pb-[30px] md:pl-[26px]'>
                <div className='settings-wrapper relative'>
                    <SidbarInner />
                    <Settingspage />
                </div>
            </div>
        </>
    )
}
