import React  from "react";
import { useState } from "react";
import { convertDateFormat ,parseJson} from "../../common/utils";
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from "react-router-dom";
import "../../scss/pages/containerDetail.scss"
import { isArray } from "lodash";
import moment from "moment";
export default function ContainerDetail({ dbData }) {
  const [containerDetails, setContainerDetails] = useState(true);
  
  const toggleAccordionContainer = () => {
    setContainerDetails(!containerDetails);
  }
  const containerData = dbData?.container_details||[];
  const navigate = useNavigate();
  const navigateToDetailPage = (id) => {
    navigate(`/containers/${id}`);
  };
  const getParsedValue=(value)=>{
    let parsedValue = parseJson(value);
    if (parsedValue && parsedValue.length >= 2) {
      let parsedTime=typeof parsedValue[1]==="string"?parsedValue[1].split(" "):"";
       parsedTime=isArray(parsedTime)?`${parsedTime[1]} ${parsedTime[2]}`:"";
       const parsedDate=typeof parsedValue?.[0] ==="string"?convertDateFormat(parsedValue?.[0]):""
    
      parsedValue = `${parsedDate} ${parsedTime}`;
    } else {
      parsedValue = "";
    }
    parsedValue= parsedValue.trim();
    parsedValue=parsedValue?moment(parsedValue, "DD-MMM-YYYY hh:mm A")?.format("DD-MMM-YYYY HH:mm"):"NA"
    return parsedValue;
   
  }
  const formattedData = containerData.map((item) => ({
    ...item,
    eta: item?.eta
    ? convertDateFormat(item?.eta)
    : "NA",
    atd: getParsedValue(item?.atd)||"NA",
   etd:getParsedValue(item?.etd)||"NA",
    empty_return:item?.empty_return
    ? convertDateFormat(item?.empty_return)
    : "NA",
    clickEvent: () => navigateToDetailPage(item.container_number),
  }));


  const tableData = {
    columns: [
          {
            label: "CTR No",
            field: "container_number",
            sort: "asc",
            width: 220,
          },
          {
            label:"Type",
            field:"container_type",
            sort: "asc",
            width: 120,
      
          },
          {
            label: "Mode",
            field: "container_mode",
            sort: "asc",
            width: 220,
          },
          {
            label: "ETA Date/Time",
            field: "eta",
            sort: "asc",
            width: 220,
          },
          {
            label: "EST Del Date/Time ",
            field: "etd",
            sort: "asc",
            width: 220,
          },
          {
            label: "ACT Del Date/Time",
            field: "atd",
            sort: "asc",
            width: 220,
          },
          {
            label: "Empty Return Del Date/Time",
            field: "empty_return",
            sort: "asc",
            width: 220,
          },
    ],
    rows: formattedData,
  };
  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-5 md:pb-[30px] mt-[26px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionContainer}
        >
          <h4 className="font-semibold">Container Details</h4>
          <img
            className={`transition-transform transform ${
              containerDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          containerDetails ? "max-h-[800px]" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl containers-table">
            <MDBDataTable data={tableData} responsive
             entries={100}
             className="table-wrapper"
             noBottomColumns={true}
             scrollX={true}
             searching={false}
             />
          </div>
        </div>
      </div>
    </div>
  );
}
