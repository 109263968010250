import React, { useEffect, useRef } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const App = ({data }) => {
  const chartRef = useRef(null);
  const transformedData = data?.map((item,index) => ({
    type: "column",
    name: item.origin, // Use "origin" as the name
    showInLegend: false,
    yValueFormatString: "#,##0 Shipments",
    // color: index % 2 === 0 ? "#B6E0F5" : "#DEF4FF",
    fontFamily: '"transducer-extended", sans-serif',
    fontweight: "bold",
    lineThickness: 2,
    markerSize: 7,
    dataPoints: item?.data?.map((dataItem,index) => ({
      label: new Date(dataItem?.x).toLocaleString('en-US', { year: 'numeric', month: 'short' }), // Convert "x" to Date
      y: dataItem?.y, // Extract "y" values
      color: ++index % 2 === 0 ? "#B6E0F5" : "#DEF4FF", 
    })),
  }));
  let overallMaxValue = -Infinity;

  transformedData.forEach((dataset) => {
    const maxDatasetValue = Math.max(
      ...dataset.dataPoints.map((point) => point.y)
    );
    overallMaxValue = Math.max(overallMaxValue, maxDatasetValue);
  });

  const options = {
    theme: "light1",
    animationEnabled: true,
    height: 141,
    axisY2: {
      title: "",
      tickLength: 0,
      lineThickness: 0,
      margin: 0,
      fontFamily: '"transducer-extended", sans-serif',
      fontWeight: "bold",
      labelFontWeight: "bold",
      labelFormatter: function (e) {
        return "";
      },
    },
    axisX: {
      gridColor: "transparent",
      lineColor: "transparent",
      tickLength: 6,
      fontWeight: "bold",
      labelFontWeight: "bold",
    },
    axisY: {
      gridColor: "transparent",
      lineColor: "transparent",
      tickLength: 3,
      lineThickness: 0,
      margin: 0,
      fontFamily: '"transducer-extended", sans-serif',
      labelFontWeight: "bold",
      fontWeight: "bold",
      interval: "auto",
      maximum: overallMaxValue + 5,
    },
    toolTip: {
      shared: true,
    },
    legend: {
      cursor: "pointer",
    },
    data: transformedData,
    fontWeight: "bold",
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.render();
    }
  }, []);
  const noRecordFound = transformedData.length === 0;
  return (
    <div className="volumes-chart">
      {noRecordFound ? (
        <div className="flex items-center justify-center origin-no-record">
          No Record Found
        </div>
      ) : (
        <CanvasJSChart options={options} ref={chartRef} />
      )}
    </div>
  );
};

export default App;
