import React from "react";
import { useState } from "react";
import { convertDateFormat } from "../../common/utils";
export default function DeliveryDetails({ dataApi }) {
  const [deliveryDetails, setdeliveryDetails] = useState(true);

  const toggleAccordionDelivery = () => {
    setdeliveryDetails(!deliveryDetails);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  const dates = dataApi?.dates;

  return (
    <div className="details-card  pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[21px] md:pb-[30px] mt-[27px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionDelivery}
        >
          <h4 className="font-semibold">Delivery Details</h4>
          <img
            className={`transition-transform transform ${
              deliveryDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          deliveryDetails ? "max-h-[500px]" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] sm:gap-y-[33px] 3xl:gap-x-[62px]">
              <div className="item">
                <h6 className="item-heading">Origin</h6>
                <div className="item-content">
                  {typeof responseApi?.PortOfOrigin?.Name === "string"
                    ? responseApi?.PortOfOrigin?.Name
                    : "NA"}{" "}
                  (
                  {typeof responseApi?.PortOfOrigin?.Code === "string"
                    ? responseApi?.PortOfOrigin?.Code
                    : ""}
                  )
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Destination</h6>
                <div className="item-content">
                  {typeof responseApi?.PortOfDestination?.Name === "string"
                    ? responseApi?.PortOfDestination?.Name
                    : "NA"}{" "}
                  (
                  {typeof responseApi?.PortOfDestination?.Code === "string"
                    ? responseApi?.PortOfDestination?.Code
                    : "NA"}
                  )
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">ETD</h6>
                <div className="item-content text-font-bold">
                  {typeof dates?.etd == "string"
                    ? convertDateFormat(dates?.etd)
                    : "NA"}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Pickup From</h6>
                <div className="item-content">
                  {`${
                    responseApi?.OrganizationAddressCollection
                      ?.OrganizationAddress[1]?.AddressShortCode ?? "NA"
                  }`}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Delivery Destination</h6>
                <div className="item-content">
                  {`${
                    responseApi?.OrganizationAddressCollection
                      ?.OrganizationAddress[3]?.AddressShortCode ?? "NA"
                  }`}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">{/* ETA */}</h6>
                <div className="item-content text-font-bold">
                  {/* {responseApi?.DateCollection?.Date[3]?.IsEstimate === "true" && (
                  responseApi.DateCollection.Date[3].Value &&
                    Object.keys(
                      responseApi.DateCollection.Date[3].Value).length > 0
                    ?
                    formattedDateAPI(responseApi.DateCollection.Date[3].Value)
                    : " ")} */}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Estimated Pickup</h6>
                <div className="item-content">
                  {typeof responseApi?.LocalProcessing?.EstimatedPickup ==
                  "string"
                    ? convertDateFormat(
                        responseApi?.LocalProcessing?.EstimatedPickup
                      )
                    : "NA"}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Estimated Delivery Date</h6>
                <div className="item-content">
                  {typeof dates?.estimated_delivery_date == "string"
                    ? convertDateFormat(dates?.estimated_delivery_date)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">ETA</h6>
                <div className="item-content text-font-bold">
                  {typeof dates?.eta == "string"
                    ? convertDateFormat(dates?.eta)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Actual Pickup</h6>
                <div className="item-content">
                  {typeof dates?.actual_pickup == "string"
                    ? convertDateFormat(dates?.actual_pickup)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Actual Delivery</h6>
                <div className="item-content">
                  {typeof dates?.actual_delivery_date == "string"
                    ? convertDateFormat(dates?.actual_delivery_date)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">ATA</h6>
                <div className="item-content text-font-bold">NA</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
