import React from 'react';
import '../scss/pages/notificationspreferences.scss';
import Layout from '../components/common/layout';

function notificationPreferences() {
  return (
    <>
    <Layout/>
      <div className='mt-[167px] md:mt-[89px] md:ml-[307px] page-wrapper md:pt-[26px] md:pr-9 md:pb-[27px] md:pl-[26px]' style={{marginTop:"-130px",background:"red"}}>
        
        <div className='bg-white notifications-preferences'>
          <div className=' pt-[25px] pl-[26px] pb-[22px] pr-[26px]'>
            <div className='notifications-heading-wrapper pb-4 flex items-start justify-start flex-col gap-[8px]'>
              <h4 className='notifications-heading'>Notification settings</h4>
              <p className='notifications-text'>we may still send you important notifications about your account outside of your notification settings</p>
            </div>
            <div className="conatiner 2xl">
              <div className="grid grid-cols-2 border-bottom-light">
                <div className='notifications-list col-span-2 xl:col-span-1'>
                  <div className='notifications-item'>
                    <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                      <div className='col-span-3 sm:col-span-2'>
                        <h4>Shipments</h4>
                        <p className="mt-2">These are notifications for shipments when shipments will be created, updated or deleted.</p>
                      </div>
                      <div className='flex items-start sm:items-end  justify-center flex-col gap-4'>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" defaultChecked={true} />
                          <span className="ml-3 ">Push</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">Email</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">SMS</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 border-bottom-light">
                <div className='notifications-list col-span-2 xl:col-span-1'>
                  <div className='notifications-item'>
                    <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                      <div className='col-span-3 sm:col-span-2'>
                        <h4>Bookings</h4>
                        <p className="mt-2">These are notifications for bookings when bookings will be created, updated or deleted. </p>
                      </div>
                      <div className='flex items-start sm:items-end  justify-center flex-col gap-4'>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" defaultChecked={true} />
                          <span className="ml-3 ">Push</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">Email</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">SMS</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 border-bottom-light">
                <div className='notifications-list col-span-2 xl:col-span-1'>
                  <div className='notifications-item'>
                    <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                      <div className='col-span-3 sm:col-span-2'>
                        <h4>Tracking</h4>
                        <p className="mt-2">These are notifications for tracking when tracking will be created, updated or deleted.</p>
                      </div>
                      <div className='flex items-start sm:items-end  justify-center flex-col gap-4'>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" defaultChecked={true} />
                          <span className="ml-3 ">Push</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">Email</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">SMS</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 border-bottom-light">
                <div className='notifications-list col-span-2 xl:col-span-1'>
                  <div className='notifications-item'>
                    <div className="grid grid-cols-3 gap-y-4 md:gap-0">
                      <div className='col-span-3 sm:col-span-2'>
                        <h4>More activity about you</h4>
                        <p className="mt-2">These are notifications for More activity about you when More activity about you will be created, updated or deleted.</p>
                      </div>
                      <div className='flex items-start sm:items-end  justify-center flex-col gap-4'>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" defaultChecked={true} />
                          <span className="ml-3 ">Push</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">Email</span>
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" />
                          <span className="ml-3 ">SMS</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default notificationPreferences
