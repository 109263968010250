import axios from "axios";
import {  token } from "../common/constants";
import { autoLogOut } from "../common/utils";
const baseURL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://phpstack-1038186-3652641.cloudwaysapps.com/api";
const Axios = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  timeout: 80000,
});

Axios.interceptors.request.use(
  async (config) => {
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => {
    if (response?.data?.error?.includes("Unauthenticate")) {
      autoLogOut();
      return null;
    }
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      autoLogOut();
    }
    return Promise.reject(error);
  }
);
export { Axios, baseURL };
