import React from "react";
import { useState, useEffect } from "react";
import { convertDateFormat } from "../../common/utils";
import { isArray } from "lodash";
import { parseJson } from "../../common/utils";
import moment from "moment";
export default function DeliveryDetails({ dataApi }) {
  const [deliveryDetails, setdeliveryDetails] = useState(true);
  const [isSubShipmentInfo, setSubShipmentInfo] = useState("");
  const toggleAccordionDelivery = () => {
    setdeliveryDetails(!deliveryDetails);
  };
  const responseApi = dataApi?.Data?.UniversalShipment?.Shipment;

  useEffect(() => {
    if (responseApi !== null) {
      if (
        responseApi?.hasOwnProperty("SubShipmentCollection") &&
        responseApi?.SubShipmentCollection.hasOwnProperty("SubShipment")
      ) {
        setSubShipmentInfo("enable");
      } else {
        setSubShipmentInfo("disable");
      }
    }
  }, [responseApi]);
  const getParsedValue=(value)=>{
    let parsedValue = parseJson(value);
    if (parsedValue && parsedValue.length >= 2) {
      let parsedTime=typeof parsedValue[1]==="string"?parsedValue[1].split(" "):"";
       parsedTime=isArray(parsedTime)?`${parsedTime[1]} ${parsedTime[2]}`:"";
       const parsedDate=typeof parsedValue?.[0] ==="string"?convertDateFormat(parsedValue?.[0]):""
  
      parsedValue = `${parsedDate} ${parsedTime}`;
    } else {
      parsedValue = "";
    }
    parsedValue= parsedValue.trim();
    parsedValue=parsedValue?moment(parsedValue, "DD-MMM-YYYY hh:mm A")?.format("DD-MMM-YYYY HH:mm"):"NA"
    return parsedValue;
  
  }
  return (
    <div className="details-card  pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[21px] md:pb-[30px] mt-[32px] ">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionDelivery}
        >
          <h4 className="font-semibold">Delivery Details</h4>
          <img
            className={`transition-transform transform ${
              deliveryDetails ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300  ${
          deliveryDetails ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[21px] sm:gap-y-[33px] gap-x-[10px] 3xl:gap-x-[40px]">
            <div className="item">
                <h6 className="item-heading">Port Of Origin</h6>
                <div className="item-content">
                { isSubShipmentInfo === "disable" ? (
                    responseApi?.PortOfOrigin?.Code ? (
                      <>
                        {`${responseApi?.PortOfOrigin?.Code} (${responseApi?.PortOfOrigin?.Name})`}
                      </>
                    ) : (
                      "NA"
                    )
                  ) : responseApi?.SubShipmentCollection?.SubShipment
                      .PortOfOrigin?.Code ? (
                    <>
                      {`${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          .PortOfOrigin?.Code === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              .PortOfOrigin?.Code
                          : "NA"
                      } (${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          .PortOfOrigin?.Name === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              .PortOfOrigin?.Name
                          : "NA"
                      })`}
                    </>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Port of Destination</h6>
                <div className="item-content">
                  {isSubShipmentInfo === "disable" ? (
                    responseApi?.PortOfDestination?.Code ? (
                      <>{`${responseApi?.PortOfDestination?.Code} (${responseApi?.PortOfDestination.Name})`}</>
                    ) : (
                      "NA"
                    )
                  ) : responseApi?.SubShipmentCollection?.SubShipment
                      ?.PortOfDestination?.Code ? (
                    <>
                      {`${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          ?.PortOfDestination?.Code === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              ?.PortOfDestination?.Code
                          : "NA"
                      } (${
                        typeof responseApi?.SubShipmentCollection?.SubShipment
                          ?.PortOfDestination?.Name === "string"
                          ? responseApi?.SubShipmentCollection?.SubShipment
                              ?.PortOfDestination?.Name
                          : "NA"
                      })`}
                    </>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">ETA</h6>
                <div className="item-content">
                  {typeof dataApi?.container?.eta === "string"
                    ? convertDateFormat(dataApi?.container?.eta)
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Peach Status </h6>
                <div className="item-content">
                  {typeof responseApi?.ContainerCollection?.Container
                    ?.ContainerStatus?.Code === "string"
                    ? ` ${responseApi?.ContainerCollection?.Container?.ContainerStatus?.Description} (${responseApi.ContainerCollection?.Container?.ContainerStatus?.Code})`
                    : Array.isArray(responseApi?.ContainerCollection?.Container)
                    ? `${
                        typeof responseApi?.ContainerCollection?.Container.find(
                          (item) =>
                            item.ContainerNumber ===
                            dataApi?.container?.container_number
                        )?.ContainerStatus?.Description ==="string"?
                        responseApi?.ContainerCollection?.Container.find(
                          (item) =>
                            item.ContainerNumber ===
                            dataApi?.container?.container_number
                        )?.ContainerStatus?.Description :""
                      } (${
                        typeof responseApi?.ContainerCollection?.Container.find(
                          (item) =>
                            item.ContainerNumber ===
                            dataApi?.container?.container_number
                        )?.ContainerStatus?.Code ==="string"?responseApi?.ContainerCollection?.Container.find(
                          (item) =>
                            item.ContainerNumber ===
                            dataApi?.container?.container_number
                        )?.ContainerStatus?.Code:"NA"
                      }
                      )`
                    : dataApi?.container?.peach_status || "NA"}
                </div>
              </div>
            
              <div className="item">
                <h6 className="item-heading">Est Del</h6>
                <div className="item-content">

                  {getParsedValue(dataApi?.container?.etd)||"NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Actual Del</h6>
                <div className="item-content">
                {getParsedValue(dataApi?.container?.atd)||"NA"}
                </div>
              </div>

              <div className="item">
                <h6 className="item-heading">Empty Return</h6>
                <div className="item-content">
                {typeof responseApi?.ContainerCollection?.Container
                    ?.ContainerParkEmptyReturnGateIn === "string"
                    ? convertDateFormat(
                        responseApi?.ContainerCollection?.Container
                          ?.ContainerParkEmptyReturnGateIn
                      )
                    : Array.isArray(responseApi?.ContainerCollection?.Container)
                    ? `${
                        typeof responseApi?.ContainerCollection?.Container.find(
                          (item) =>
                            item.ContainerNumber ===
                            dataApi?.container?.container_number
                        )?.ContainerParkEmptyReturnGateIn ==="string"?
                        convertDateFormat(
                          responseApi?.ContainerCollection?.Container
                            ?.ContainerParkEmptyReturnGateIn
                        ):"NA"
                      
                    }`:"NA"

                  }
                </div>
              </div>



              {/* <div className="item">
                <h6 className="item-heading">Delivery Address</h6>
                <div className="item-content">
                  {isSubShipmentInfo === "disable"
                    ? `${
                        responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsigneePickupDeliveryAddress"
                        )[0]?.AddressShortCode
                      } (${
                        responseApi?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsigneePickupDeliveryAddress"
                        )[0]?.Country?.Name
                      })`
                    : `${
                        responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsigneePickupDeliveryAddress"
                        )[0]?.AddressShortCode
                      } (${
                        responseApi?.SubShipmentCollection?.SubShipment?.OrganizationAddressCollection?.OrganizationAddress?.filter(
                          (item) =>
                            item?.AddressType ===
                            "ConsigneePickupDeliveryAddress"
                        )[0]?.Country?.Name
                      })`}
                </div>
              </div>

           

         
              <div className="item">
                <h6 className="item-heading">Consol Carrier Name</h6>
                <div className="item-content">
                  {dataApi?.container?.consol_carrier_name || "NA"}
                </div>
              </div> */}

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
