import React, { useEffect } from "react";
import { useState } from "react";
// import { useParams } from 'react-router-dom';

export default function OrderDetails({ dataApi,dbData,showDbData }) {
  const [orderDetail, setOrderDetail] = useState(true);
  const [isSubShipmentInfo, setSubShipmentInfo] = useState("");

  const toggleAccordionOrder = () => {
    setOrderDetail(!orderDetail);
  };
  const responseApi = dataApi && dataApi?.Data?.UniversalShipment?.Shipment;
  const orderDetails=dbData?.order_details
  useEffect(() => {
    if (responseApi !== null) {
      if (
        responseApi?.hasOwnProperty("SubShipmentCollection") &&
        responseApi?.SubShipmentCollection.hasOwnProperty("SubShipment")
      ) {
        setSubShipmentInfo("enable");
      } else {
        setSubShipmentInfo("disable");
      }
    }
  }, [responseApi]);
  return (
    <div className="details-card pt-[14px] md:pt-[19px] pl-[15px] md:pl-[25px] pr-[15px] pb-[20px] md:pb-[30px]">
      <div className="card-heading">
        <button
          className="flex items-center justify-between w-full text-left"
          onClick={toggleAccordionOrder}
        >
          <h4 className="font-semibold">Order Details</h4>
          <img
            className={`transition-transform transform ${
              orderDetail ? "rotate-[270deg]" : "rotate-90"
            }`}
            src="/assets/icons/right-arrow.svg"
            alt="right-arrow-icon"
          />
        </button>
      </div>
      <div
        className={`overflow-hidden transition-max-height ease-in-out duration-300 ${
          orderDetail ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="details-card-body">
          <div className="container-xl">
            <div className="grid grid-cols-1 gap-y-[21px] sm:gap-y-4 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-4 3xl:gap-x-[10px]">
              <div className="item">
                <h6 className="item-heading">Shipment ID</h6>
                <div className="item-content">
                  {showDbData?
                  orderDetails?.shipment_id||"NA":
                  Array.isArray(
                    responseApi?.DataContext?.DataSourceCollection?.DataSource
                  )
                    ? responseApi?.DataContext?.DataSourceCollection
                        ?.DataSource[1]?.Type === "ForwardingShipment"
                      ? responseApi?.DataContext?.DataSourceCollection
                          ?.DataSource[1]?.Key
                      : responseApi?.DataContext?.DataSourceCollection
                          ?.DataSource[0]?.Key ||
                        responseApi?.DataContext?.DataSourceCollection
                          ?.DataSource[1]?.Key ||
                        "NA"
                    : typeof responseApi?.DataContext?.DataSourceCollection
                        ?.DataSource === "object"
                    ? responseApi?.DataContext?.DataSourceCollection?.DataSource
                        .Key
                    : "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Supplier Name</h6>
                <div className="item-content">
                  {showDbData?
                  orderDetails?.supplier:(Array.isArray(
                    responseApi?.OrganizationAddressCollection
                      ?.OrganizationAddress
                  ) &&
                    responseApi.OrganizationAddressCollection.OrganizationAddress.find(
                      (item) =>
                        item?.AddressType === "ConsignorDocumentaryAddress"
                    )?.CompanyName) ||
                    (typeof responseApi?.CarrierDocumentsOverride?.AWBHeader
                      ?.Shipper?.Name === "string" &&
                      responseApi?.CarrierDocumentsOverride?.AWBHeader?.Shipper
                        ?.Name) ||
                    (Array.isArray(
                      responseApi?.SubShipmentCollection?.SubShipment
                        ?.OrganizationAddressCollection?.OrganizationAddress
                    ) &&
                      responseApi.SubShipmentCollection.SubShipment.OrganizationAddressCollection.OrganizationAddress.find(
                        (item) =>
                          item.AddressType === "ConsignorDocumentaryAddress"
                      )?.CompanyName) ||
                    responseApi?.SubShipmentCollection?.SubShipment
                      ?.CommercialInfo?.CommercialInvoiceCollection
                      ?.CommercialInvoice?.Supplier?.Address1 ||
                    "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Buyer Name</h6>
                <div className="item-content">
                  {showDbData?
                  orderDetails?.buyer:(Array.isArray(
                    responseApi?.OrganizationAddressCollection
                      ?.OrganizationAddress
                  ) &&
                    responseApi.OrganizationAddressCollection.OrganizationAddress.find(
                      (item) =>
                        item.AddressType === "ConsigneeDocumentaryAddress"
                    )?.CompanyName) ||
                    (Array.isArray(
                      responseApi?.SubShipmentCollection?.SubShipment
                        ?.OrganizationAddressCollection?.OrganizationAddress
                    ) &&
                      responseApi.SubShipmentCollection.SubShipment.OrganizationAddressCollection.OrganizationAddress.find(
                        (item) =>
                          item.AddressType === "ConsigneeDocumentaryAddress"
                      )?.CompanyName) ||
                    (isSubShipmentInfo === "disable"
                      ? responseApi?.CarrierDocumentsOverride?.AWBHeader
                          ?.Consignee?.Name
                      : responseApi?.SubShipmentCollection?.SubShipment
                          ?.CarrierDocumentsOverride?.AWBHeader?.Consignee
                          ?.Name) ||
                    "NA"}
                </div>
              </div>
              <div className="item">
                <h6 className="item-heading">Order Ref</h6>
                <div className="item-content">
                  {showDbData?
                  orderDetails?.shippers_ref
                  :isSubShipmentInfo === "disable"
                    ? typeof responseApi?.BookingConfirmationReference ==="string"?
                    responseApi?.BookingConfirmationReference:"NA"
                    : typeof responseApi?.SubShipmentCollection?.SubShipment
                        ?.BookingConfirmationReference === "string"
                    ? responseApi?.SubShipmentCollection?.SubShipment
                        ?.BookingConfirmationReference
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
